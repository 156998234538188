import React, { useEffect, useRef } from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex, isNumber } from "underscore";
import { withRouter } from "react-router-dom";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import FormGroup from "reactstrap/lib/FormGroup";
import _ from "underscore";
import swal from "sweetalert";
import Spinner from "reactstrap/lib/Spinner";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import { TagPicker, Uploader } from "rsuite";
import moment from "moment";
import StringUtils from "lib/StringUtils";
import ArrowText from "components/ArrowText";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Capacitor } from "@capacitor/core";
import LearningCourseDrawer from "../../components/Drawers/LearningCourseDrawer";
import LearningCourseTopicEditor from "../../components/Widgets/LearningCourseTopicEditor";

function Clip({ url, styles }) {
  const videoRef = useRef();

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.load();
    }
  }, [url]);

  return (
    <video ref={videoRef} style={styles} controls>
      <source src={url} />
      Your browser does not support the video tag.
    </video>
  );
}

class LearningManagerCourseView extends React.Component {
  state = {
    store: null,
    loading: true,
    descriptionState: EditorState.createEmpty(),
    loadingCategories: false,
    categories: [],
    categoryTags: [],
    allergens: [],
    tab: { name: "Information", id: "overview" },
    allTabs: [
      {
        name: "Information",
        id: "overview",
      },
      {
        name: "Content",
        id: "topics",
      },
    ],
  };

  constructor(props) {
    super(props);

    this.headerRef = React.createRef();
  }

  _loadCourseState(course) {
    if (!course) {
      return;
    }

    if (course?.cover?.url) {
      APIV2.getPrivateFileURL(course?.cover?.url).then(
        (data) => {
          this.setState({
            coverURL: data.data.url,
          });
        },
        (e) => {}
      );
    }

    if (course?.video?.url) {
      APIV2.getPrivateFileURL(course?.video?.url).then(
        (data) => {
          this.setState({
            videoURL: data.data.url,
          });
        },
        (e) => {}
      );
    }

    this.setState({
      course: course,
    });
  }

  async loadCourse() {
    const {
      match: { params },
    } = this.props;

    APIV2.getLearningCourseByID(params.courseID)
      .then(
        (data) => {
          if (data?.data?.course) {
            console.log(data?.data?.course);

            this._loadCourseState(data.data.course);
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  delete() {
    swal({
      title: "Delete Course?",
      text: "Are you sure you want to delete this course? This cannot be undone.",
      icon: "warning",
      buttons: ["Nevermind", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (!willDelete) {
        return;
      }

      this.setState({ deleting: true });

      APIV2.deleteLearningCourse(this.state.course._id)
        .then(
          (data) => {
            PubSub.publish(Event.AFFILIATE.DELETED, {
              id: this.state.course._id,
            });

            swal("Course successfully deleted", {
              icon: "success",
            });

            this.props.history.push("/university/lmsmanager");
          },
          (e) => {
            console.error(e);
            window.alert(
              "We're having trouble removing this course. Please try again."
            );
          }
        )
        .finally(() => {
          this.setState({ deleting: false });
        });
    });
  }

  checkStickyHeader(e) {
    if (this.headerRef.current) {
      const stickyElm = this.headerRef.current;

      var rect = stickyElm.getBoundingClientRect();

      if (rect.top <= (Capacitor.getPlatform() == "ios" ? 110 : 80)) {
        this.setState({ sticky: true });
      } else {
        this.setState({ sticky: false });
      }

      if (rect.top == 0) {
        this.setState({ fixed: true });
      } else {
        this.setState({ fixed: false });
      }
    }
  }

  componentDidMount() {
    this.loadCourse();

    PubSub.subscribe(Event.COURSE.MODIFIED, (c) => {
      this._loadCourseState(c);
    });

    if (this.props.match?.params?.tabID) {
      this.setTab(this.props.match?.params?.tabID);
    } else {
      this.setTab("overview");
    }
  }

  setTab(tab) {
    if (!tab) {
      return;
    }

    let t = _.findWhere(this.state.allTabs, { id: tab });

    if (t) {
      this.setState({ tab: t });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.courseID != prevProps.match?.params?.courseID
    ) {
      this.loadCourse();
    }

    if (this.props.match?.params?.tabID !== prevProps.match?.params?.tabID) {
      if (this.props.match?.params?.tabID) {
        this.setTab(this.props.match?.params?.tabID);
      } else {
        this.setTab("overview");
      }
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            backOverride={`/university/lmsmanager`}
            title={
              this.state.course?.name ? this.state.course.name : "Loading..."
            }
            showBackButton={true}
            tabs={
              <CardSelectorHeader
                id="stickyHeader"
                compact={true}
                value={this.state.tab}
                options={this.state.allTabs}
                onChange={(v) => {
                  if (v.id != this.state.tab.id) {
                    console.log("CHANGE TAB", v);

                    let url =
                      "/university/lmsmanager/courses/" +
                      this.props.match?.params?.courseID +
                      "/" +
                      v.id;

                    this.props.history.push(url, { noScroll: true });
                  }
                }}
              ></CardSelectorHeader>
            }
            sticky={true}
          />
          {/* Table */}
          <Row>
            <Col xs="12" md="12">
              {this.state.tab.id == "overview" ? (
                <>
                  <Row className="align-items-center mb-3 pb-3 border-bottom">
                    <Col xs="">
                      <h2 className="m-0">Information</h2>
                    </Col>
                    <Col xs="auto">
                      <Button
                        color="dark"
                        outline
                        onClick={() => {
                          this.setState({
                            openModify: true,
                            modifyCourse: this.state.course,
                          });
                        }}
                      >
                        Edit
                      </Button>
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-4">
                    <Col xs="12" sm="6">
                      <h3>Cover Image</h3>
                      {this.state.course?.cover ? (
                        <div
                          style={{
                            width: "100%",
                            height: 300,
                          }}
                          className="rounded border d-flex items-center justify-content-center"
                        >
                          <img
                            src={this.state.coverURL}
                            className="rounded"
                            style={{ width: "100%", objectFit: "contain" }}
                          ></img>
                        </div>
                      ) : (
                        <div
                          style={{ width: "100%", height: 300 }}
                          className="rounded border text-center d-flex justify-content-center align-items-center"
                        >
                          <div className="text-dark">No cover image.</div>
                        </div>
                      )}
                    </Col>
                    <Col xs="12" sm="6" className="mt-3 mt-sm-0">
                      <h3>Video Introdution</h3>
                      {this.state.course?.video ? (
                        <div
                          style={{
                            width: "100%",
                            height: 300,
                          }}
                          className="rounded border bg-dark d-flex items-center justify-content-center"
                        >
                          <Clip
                            url={this.state.videoURL}
                            styles={{
                              width: "100%",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{ width: "100%", height: 300 }}
                          className="rounded border text-center d-flex justify-content-center align-items-center"
                        >
                          <div className="text-dark">
                            No video introduction.
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <div className="rounded border mb-3">
                    <div className="p-3">
                      <Row className="align-items-center mb-2">
                        <Col xs="">
                          <h3 className="text-dark m-0">
                            Minimum Passing Score
                          </h3>
                        </Col>
                      </Row>
                      <p className="text-dark m-0">Minimum Score: 80/100</p>
                    </div>
                  </div>
                  <div className="rounded border mb-3">
                    <div className="p-3">
                      <Row className="align-items-center">
                        <Col xs="">
                          {this.state.course?.name ? (
                            <h1 className="text-dark m-0">
                              {this.state.course?.name}
                            </h1>
                          ) : null}
                        </Col>
                      </Row>
                      {this.state.course?.description ? (
                        <p className="text-dark m-0">
                          {this.state.course?.description}
                        </p>
                      ) : null}
                    </div>
                    {this.state.course?.overview?.html ? (
                      <div className="p-3 border-top">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.course?.overview?.html,
                          }}
                          className="plnu-topic"
                        ></div>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : null}
              {/**Primary  Widgets */}

              {this.state.tab.id == "topics" ? (
                <>
                  <LearningCourseTopicEditor
                    course={this.state.course}
                    onSave={() => {
                      this.loadCourse();
                    }}
                  ></LearningCourseTopicEditor>
                </>
              ) : null}
            </Col>
          </Row>
          <hr className="mt-2 mb-4" />
          <Row>
            <Col xs="auto">
              <Button
                color="danger"
                onClick={() => this.delete()}
                outline
                disabled={this.state.deleting}
              >
                {this.state.deleting ? (
                  <>
                    <Spinner size="sm" color="danger" />
                  </>
                ) : (
                  <>Delete Course</>
                )}
              </Button>
            </Col>
            <Col xs=""></Col>
          </Row>
        </Container>
        <LearningCourseDrawer
          open={this.state.openModify}
          course={this.state.modifyCourse}
          onClose={() => {
            this.setState({
              openModify: false,
              modifyCourse: null,
            });
          }}
        ></LearningCourseDrawer>
      </>
    );
  }
}

export default withRouter(LearningManagerCourseView);
