import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import swal from "sweetalert";
import LearningTrackDrawer from "../../components/Drawers/LearningTrackDrawer";

class LearningManagerTrackListView extends React.Component {
  state = {
    active: {
      id: "All Tracks",
      name: "All Tracks",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
  };

  componentDidUpdate() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    PubSub.subscribe(Event.STAFF.CREATED, () => {
      this.loadTracks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.STAFF.MODIFIED, () => {
      this.loadTracks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  loadTracks(page = 1, count = 25, filters = null, orderBy = { name: 1 }) {
    this.setState({
      loadingTracks: true,
    });

    APIV2.getLearningTracks(page, count, filters, orderBy)
      .then(
        (data) => {
          if (data?.data?.tracks) {
            let tracks = data.data.tracks;

            this.setState({
              tracks,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the tracks. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTracks: false,
        });
      });
  }

  delete(track, replacementID) {
    swal({
      title: "Remove Track?",
      text: `Are you sure you want remove ${track?.name} as a track?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deleteSubmitting: track?._id,
      });

      APIV2.deleteLearningTrack(track?._id, this.state.replacementID)
        .then(
          (data) => {
            this.loadTracks(
              this.state.page,
              this.state.count,
              this.state.filters
            );
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            deleteSubmitting: false,
          });
        });
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadTracks(page, this.state.count, this.state.filters);
  }

  handleTabChange(option, force = false) {
    let filters = null;
    let orderBy = { name: 1 };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All Tracks") {
      filters = {};
    } else if (option.id == "Core Tracks") {
      filters = {
        type: "Core Track",
      };
    } else if (option.id == "Ad-Hoc Tracks") {
      filters = {
        type: "Ad-Hoc Track",
      };
    }

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadTracks(1, this.state.count, filters, orderBy);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Tracks"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  openModify: true,
                  modifyTrack: null,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All Tracks",
                      id: "All Tracks",
                    },
                    {
                      name: "Core Tracks",
                      id: "Core Tracks",
                    },
                    {
                      name: "Ad-Hoc Tracks",
                      id: "Ad-Hoc Tracks",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.result?.start && this.state.result?.end ? (
                        <>
                          Showing{" "}
                          {StringUtils.numberFormat(this.state.result?.start)}-
                          {StringUtils.numberFormat(this.state.result?.end)}
                          {this.state.result?.total ? (
                            <>
                              &nbsp;of{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.total
                              )}{" "}
                              tracks
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>---</>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h4 className="mb-0">Track</h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingTracks ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    <div>
                      {!this.state.tracks?.length &&
                      !this.state.loadingTracks ? (
                        <div className="px-3 py-2">
                          <Row>
                            <Col>Get started by creating your first track.</Col>
                          </Row>
                        </div>
                      ) : null}

                      {this.state.tracks?.map((c, i) => (
                        <div
                          className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                            i != this.state.tracks.length - 1
                              ? "border-bottom"
                              : ""
                          }`}
                          key={i}
                        >
                          <Row className="align-items-center">
                            <Col xs="">
                              <p className="text-dark text-capitalize small mb-0">
                                {c?.draft ? (
                                  <Badge
                                    color="light"
                                    className="mr-1 border py-1 border-midlighter"
                                  >
                                    Draft
                                  </Badge>
                                ) : (
                                  <Badge
                                    style={{
                                      position: "relative",
                                      top: "-1px",
                                    }}
                                    color="success"
                                    className="mr-1 py-1"
                                  >
                                    Published
                                  </Badge>
                                )}
                                {c?.type}:&nbsp;
                                {c.required ? "Required" : "Optional"}
                              </p>
                              <h4 className={`mb-0`}>{c.name}</h4>
                              <p className="text-dark small mb-0">
                                {c?.required ? "Assigned" : "Available"} To:{" "}
                                {c?.staffRoles?.length} role
                                {c?.staffRoles?.length > 1 ? "s" : ""}
                                {c?.type == "Ad-Hoc Track" && !c?.allStores
                                  ? ` at ${c?.storeIDs?.length} store${
                                      c?.storeIDs?.length > 1 ? "s" : ""
                                    }`
                                  : " at all stores"}
                              </p>
                            </Col>

                            <Col xs="auto" className="text-right">
                              <Button
                                color="danger"
                                outline
                                className="btn-icon-only"
                                disabled={this.state.deleteSubmitting == c._id}
                                onClick={() => {
                                  this.delete(c);
                                }}
                                size="sm"
                              >
                                {this.state.deleteSubmitting == c._id ? (
                                  <Spinner color="danger" size="sm"></Spinner>
                                ) : (
                                  <i className="mdi mdi-close"></i>
                                )}
                              </Button>
                              <Button
                                color="secondary"
                                outline
                                className="btn-icon-only"
                                onClick={() => {
                                  this.setState({
                                    openModify: true,
                                    modifyTrack: c,
                                  });
                                }}
                                size="sm"
                              >
                                <i className="mdi mdi-pencil"></i>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <LearningTrackDrawer
          open={this.state.openModify}
          track={this.state.modifyTrack}
          onClose={() => {
            this.setState({
              openModify: false,
              modifyTrack: null,
            });
          }}
          tracks={this.state.tracks}
        ></LearningTrackDrawer>
      </>
    );
  }
}

export default LearningManagerTrackListView;
