import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Collapse,
  Badge,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import ModifyStoreBillingAccountModal from "./ModifyStoreBillingAccountModal";

import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import FranchisePartnerDrawer from "./Drawers/FranchisePartnerDrawer";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import NumberFormat from "react-number-format";
import Spinner from "reactstrap/lib/Spinner";
import { withRouter } from "react-router";
import StoreNotesWidget from "./Widgets/StoreNotesWidget";
import moment from "moment";
import { ProgressBar } from "@tremor/react";
import _ from "underscore";
import StoreNSOWidget from "./Widgets/StoreNSOWidget";
import { DatePicker, Dropdown, Popover, Whisper } from "rsuite";
import LinkUtils from "lib/LinkUtils";
import FileUploadDrawer from "./FileUploadDrawer";
import swal from "sweetalert";
import StoreComplianceNotesWidget from "./Widgets/StoreComplianceNotesWidget";

class StoreViewComplianceTab extends React.Component {
  state = {
    store: null,
    expanded: [],
    documents: [
      {
        value: "fddReceiptPageDocument",
        label: "FDD Receipt Page",
      },
      {
        value: "fddDocument",
        label: "Executed FDD / Agreement",
      },
      {
        value: "fddAmendmentDocument",
        label: "Executed FDD Amendments",
      },
      {
        value: "leaseDocument",
        label: "Executed Lease",
      },
      {
        value: "ucc1Filing",
        label: "UCC-1 Filing",
      },
      {
        value: "buildoutPlans",
        label: "Buildout Plans",
      },
      {
        value: "insuranceDocument",
        label: "Insurance Policy",
      },
      {
        value: "applicationDocument",
        label: "Application",
      },
      {
        value: "backgroundCheckDocument",
        label: "Background Check",
      },
      {
        value: "dealClosingChecklistDocument",
        label: "Deal Closing Checklist",
      },
      {
        value: "terminationAgreement",
        label: "Executed Termination Agreement",
      },
    ],
    dates: [
      {
        value: "discoveryDayDate",
        label: "Confirmation Day",
      },
      {
        value: "fddSignedDate",
        label: "FDD / Agreement Execution Date",
      },
      {
        value: "developmentStartDate",
        label: "Development Start Date",
      },
      {
        value: "hqTrainingDate",
        label: "HQ Partner Training",
      },
      {
        value: "operations.storeOpening.websiteLaunchDate",
        label: "Website Launch",
      },
      {
        value: "leaseSignedDate",
        label: "Lease Execution Date",
      },

      {
        value: "communityActivationDate",
        label: "Community Activation Start",
      },
      {
        value: "buildoutStartDate",
        label: "Buildout Start",
      },

      {
        value: "hqManagementTrainingDate",
        label: "HQ Management Training",
      },
      {
        value: "operations.storeOpening.prelaunchStartDate",
        label: "Prelaunch Start",
      },
      {
        value: "buildoutEndDate",
        label: "Buildout End",
      },
      {
        value: "operations.storeOpening.openingDate",
        label: "Soft Opening",
      },
      {
        value: "onSiteTrainingDate",
        label: "On-Site Opening Training",
      },
      {
        value: "operations.storeOpening.firstBillDate",
        label: "First Fulfillment",
      },
      {
        value: "supportTransitionDate",
        label: "Support Transition",
      },
      {
        value: "grandOpeningDate",
        label: "Grand Opening Date",
      },

      {
        value: "leaseRenewalDate",
        label: "Lease Expiration",
        showTimeUntil: true,
      },
      {
        value: "franchiseRenewalDate",
        label: "Franchise Expiration",
        showTimeUntil: true,
      },
    ],
  };

  constructor(props) {
    super(props);

    this.menuRef = React.createRef();
  }

  async getPartnerImages(partners) {
    let partnerImages = {};

    for (let i = 0; i < partners?.length; i++) {
      try {
        if (!partners[i]?.imageURL) {
          continue;
        }

        let result = await APIV2.getPrivateFileURL(partners[i]?.imageURL);

        if (result?.data?.url) {
          partnerImages[partners[i].id] = result.data.url;
        }
      } catch (e) {}
    }

    this.setState({
      partnerImages,
    });
  }

  removePartner(partner) {
    swal({
      title: "Remove Partner",
      text: `Are you sure you want to remove ${partner?.name} from being a partner?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      APIV2.deleteFranchisePartnerForStore(this.state.store?._id, partner.id)
        .then(
          (data) => {
            PubSub.publish(Event.STORE.MODIFIED, data.data.store);

            this.setState({
              store: data.data.store,
            });
          },
          (error) => {
            window.alert("Unable to remove partner, please try again.");
          }
        )
        .finally(() => {});
    });
  }

  setValue(key, value, modifyKey = "") {
    this.setState({
      valSubmitting: true,
    });

    console.log(key, value);

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(this.state?.store?._id, key, value)
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          if (modifyKey) {
            this.setState({
              [modifyKey]: false,
            });
          }
        },
        (e) => {
          window.alert("Unable to modify: " + e?.message ?? "error occurred.");
        }
      )
      .finally(() => {
        this.setState({
          valSubmitting: false,
        });
      });
  }

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });

            if (data.data.store?.franchisePartners?.length) {
              this.getPartnerImages(data.data.store?.franchisePartners);
            }
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.loadStore();

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });
    });
  }

  getMilestoneDateValue(store, key) {
    let value = store;

    key.split(".").forEach((k) => {
      if (value) {
        value = value[k];
      }
    });

    if (value) {
      return moment(value).toDate();
    }

    return null;
  }

  render() {
    return (
      <>
        <Card className="mt-3 border">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Franchise Partners</h3>
                <p className="small mb-0">
                  Contact information for all partners associated with this
                  store.
                </p>
              </Col>
              <Col xs="auto">
                <Button
                  onClick={() => {
                    this.setState({
                      addPartnerOpen: true,
                    });
                  }}
                  className="btn-sm btn"
                  color="primary"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            {this.state.store?.franchisePartners?.length ? (
              <>
                {this.state.store?.franchisePartners?.map((partner, i) => (
                  <>
                    <div
                      className={`p-3 ${
                        i != this.state.store?.franchisePartners?.length - 1 &&
                        "border-bottom"
                      }`}
                    >
                      <Row className={"align-items-top"}>
                        <Col xs="">
                          <Row
                            className={
                              partner?.phone ||
                              partner?.occupation ||
                              partner?.location ||
                              partner?.notes
                                ? "align-items-top"
                                : "align-items-center"
                            }
                          >
                            <Col
                              xs="12"
                              sm="auto"
                              className="pr-2 mb-3 mb-sm-0"
                            >
                              <div
                                style={{
                                  height: 50,
                                  width: 50,
                                  overflow: "hidden",
                                  borderRadius: 4,
                                }}
                                className="border border-lighter bg-superlight"
                              >
                                {this.state.partnerImages?.hasOwnProperty(
                                  partner.id
                                ) ? (
                                  <div>
                                    <Whisper
                                      trigger={["hover", "click"]}
                                      placement="auto"
                                      preventOverflow={true}
                                      delayOpen={200}
                                      speaker={
                                        <Popover full>
                                          <img
                                            src={
                                              this.state.partnerImages[
                                                partner.id
                                              ]
                                            }
                                            style={{ width: 270 }}
                                          ></img>
                                        </Popover>
                                      }
                                    >
                                      <img
                                        src={
                                          this.state.partnerImages[partner.id]
                                        }
                                        className="cursor-pointer"
                                        style={{ width: "100%" }}
                                      ></img>
                                    </Whisper>
                                  </div>
                                ) : (
                                  <div className="p-2">
                                    <i
                                      className="mdi mdi-account text-medium"
                                      style={{ fontSize: 33, lineHeight: 1 }}
                                    ></i>
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col xs="" sm="">
                              <Row className="align-items-center">
                                <Col xs="">
                                  <h3 className="m-0 text-dark">
                                    {partner?.name}{" "}
                                    {partner.decisionMaker && (
                                      <>
                                        <Badge
                                          size="sm"
                                          color="primary"
                                          className="ml-1"
                                          style={{
                                            position: "relative",
                                            top: -2,
                                          }}
                                        >
                                          Decision Maker
                                        </Badge>
                                      </>
                                    )}
                                  </h3>
                                  {partner.occupation || partner.location ? (
                                    <>
                                      <p className="m-0 small text-dark">
                                        {partner?.occupation && partner.location
                                          ? `${partner.occupation} from ${partner.location}`
                                          : null}
                                        {partner.occupation && !partner.location
                                          ? partner.occupation
                                          : null}
                                        {partner.location && !partner.occupation
                                          ? partner.location
                                          : null}
                                      </p>
                                    </>
                                  ) : null}
                                </Col>

                                {partner.email ? (
                                  <>
                                    <Col xs="12" className="mt-1">
                                      <p
                                        className="m-0 text-dark"
                                        style={{ fontSize: 14 }}
                                      >
                                        <span className="font-weight-bold text-dark">
                                          Email:
                                        </span>
                                        &nbsp;{partner.email}
                                      </p>
                                    </Col>
                                  </>
                                ) : null}
                                {partner.phone ? (
                                  <>
                                    <Col xs="12" className="mt-1">
                                      <p
                                        className="m-0 text-dark"
                                        style={{ fontSize: 14 }}
                                      >
                                        <span className="font-weight-bold text-dark">
                                          Phone:
                                        </span>
                                        &nbsp;
                                        {StringUtils.formatPhoneNumber(
                                          partner.phone.replace("+1", "")
                                        )}
                                      </p>
                                    </Col>
                                  </>
                                ) : null}
                                {partner.mailingAddress ? (
                                  <>
                                    <Col xs="12" className="mt-1">
                                      <p
                                        className="m-0 text-dark"
                                        style={{ fontSize: 14 }}
                                      >
                                        <span className="font-weight-bold text-dark">
                                          Mailing Address:
                                        </span>
                                        &nbsp;{partner.mailingAddress}
                                      </p>
                                    </Col>
                                  </>
                                ) : null}

                                {partner.notes ? (
                                  <>
                                    <Col xs="12" className="mt-1">
                                      <p
                                        style={{ fontSize: 14 }}
                                        className="mb-0 font-weight-bold text-dark"
                                      >
                                        Notes:
                                      </p>
                                      <p
                                        style={{ fontSize: 14 }}
                                        className="m-0 text-dark"
                                      >
                                        {partner.notes}
                                      </p>
                                    </Col>
                                  </>
                                ) : null}
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="auto">
                          <Whisper
                            trigger="click"
                            placement="autoVerticalEnd"
                            ref={this.menuRef}
                            speaker={
                              <Popover full>
                                <Dropdown.Menu
                                  style={{
                                    minWidth: 150,
                                    paddingBottom: 0,
                                  }}
                                >
                                  <Dropdown.Item
                                    panel
                                    className="py-2 font-weight-800 text-uppercase"
                                    style={{
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                    }}
                                  >
                                    <h5 className="m-0">Actions</h5>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="my-0"
                                    divider
                                  ></Dropdown.Item>
                                  <Dropdown.Item
                                    disabled={!partner?.email}
                                    onClick={() => {
                                      this.menuRef.current.close();

                                      LinkUtils.openInNewTab(
                                        "mailto:" + partner.email
                                      );
                                    }}
                                  >
                                    Email
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    disabled={!partner?.phone}
                                    onClick={() => {
                                      this.menuRef.current.close();

                                      LinkUtils.openInNewTab(
                                        "tel:" + partner.phone
                                      );
                                    }}
                                  >
                                    Call
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    disabled={
                                      !this.state.partnerImages?.hasOwnProperty(
                                        partner.id
                                      )
                                    }
                                    onClick={() => {
                                      this.menuRef.current.close();

                                      LinkUtils.openInNewTab(
                                        this.state.partnerImages[partner.id]
                                      );
                                    }}
                                  >
                                    Download Image
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      this.menuRef.current.close();

                                      this.setState({
                                        modifyPartner: partner,
                                        addPartnerOpen: true,
                                      });
                                    }}
                                  >
                                    Modify Partner
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="my-0"
                                    divider
                                  ></Dropdown.Item>
                                  <Dropdown.Item
                                    className="text-danger"
                                    onClick={() => {
                                      this.removePartner(partner);
                                    }}
                                  >
                                    Remove Partner
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Popover>
                            }
                          >
                            <Button
                              size="sm"
                              outline
                              color="dark"
                              className="btn-icon-only"
                            >
                              <i className="mdi mdi-dots-vertical"></i>
                            </Button>
                          </Whisper>
                        </Col>
                      </Row>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <>
                <div className="p-4">
                  <p className="m-0">
                    Get started by adding the first franchise partner to this
                    store.
                  </p>
                </div>
              </>
            )}
          </CardBody>
        </Card>

        <Card className="mt-3 border">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Royalties & Fees</h3>
                <p className="small mb-0">
                  Franchise royalty & fees for this location.
                </p>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark m-0">Initial Franchise Fee:</h4>
            <p className="small text-dark mb-2" style={{ lineHeight: 1.2 }}>
              Initial fee collected from franchise award.
            </p>
            <Row className="align-items-center">
              <Col xs="">
                {this.state?.modifyInitialFee ? (
                  <>
                    <NumberFormat
                      className="form-control form-control-sm"
                      fixedDecimalScale={true}
                      decimalScale={2}
                      required={true}
                      placeholder="$350.00"
                      value={this.state.initialFranchiseFee.value}
                      onValueChange={(v) => {
                        this.setState({
                          initialFranchiseFee: v,
                        });
                      }}
                      prefix="$"
                    />
                  </>
                ) : (
                  <>
                    <p className="m-0 text-dark">
                      {this.state.store?.initialFranchiseFee
                        ? `${StringUtils.centsToCurrency(
                            this.state.store?.initialFranchiseFee
                          )}`
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state?.modifyInitialFee ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyInitialFee: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        let value =
                          parseFloat(this.state.initialFranchiseFee.value) *
                          100;

                        value = Math.round(value);

                        this.setValue(
                          "initialFranchiseFee",
                          value ? value : null,
                          `modifyInitialFee`
                        );
                      }}
                      disabled={this.state.valSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.valSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyInitialFee: true,
                        initialFranchiseFee: this.state.store
                          ?.initialFranchiseFee
                          ? {
                              value:
                                this.state.store?.initialFranchiseFee / 100,
                            }
                          : {
                              value: null,
                            },
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark m-0">Royalty Fees:</h4>
            <p className="small text-dark mb-2" style={{ lineHeight: 1.2 }}>
              Percentage of gross revenue paid as royalty.
            </p>
            <Row className="align-items-center">
              <Col xs="">
                {this.state?.modifyFranchiseFee ? (
                  <>
                    <NumberFormat
                      className="form-control form-control-sm"
                      fixedDecimalScale={true}
                      decimalScale={2}
                      required={true}
                      placeholder="7%"
                      value={this.state.franchiseFee.value}
                      onValueChange={(v) => {
                        this.setState({
                          franchiseFee: v,
                        });
                      }}
                      suffix="%"
                    />
                  </>
                ) : (
                  <>
                    <p className="m-0 text-dark">
                      {this.state.store?.franchiseFee
                        ? (this.state.store?.franchiseFee * 100).toFixed(2) +
                          "%"
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state?.modifyFranchiseFee ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyFranchiseFee: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        // Perform rounding to three decimal places
                        let rounded = parseFloat(
                          parseFloat(this.state.franchiseFee.value).toFixed(3)
                        );

                        // Check for repeating nines in the thousandths place
                        if ((rounded * 1000) % 10 === 9) {
                          // Adjust to handle repeating nines properly
                          rounded = parseFloat(
                            (
                              parseFloat(this.state.franchiseFee.value) +
                              0.00001
                            ).toFixed(3)
                          );
                        }

                        rounded = rounded / 100;

                        this.setValue(
                          "franchiseFee",
                          rounded ? rounded : null,
                          `modifyFranchiseFee`
                        );
                      }}
                      disabled={this.state.valSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.valSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyFranchiseFee: true,
                        franchiseFee: this.state.store?.franchiseFee
                          ? { value: this.state.store?.franchiseFee * 100 }
                          : {
                              value: null,
                            },
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark m-0">Marketing Fund:</h4>
            <p className="small text-dark mb-2" style={{ lineHeight: 1.2 }}>
              Percentage of gross revenue contributed to the national marketing
              fund.
            </p>
            <Row className="align-items-center">
              <Col xs="">
                {this.state?.modifyMarketingFund ? (
                  <>
                    <NumberFormat
                      className="form-control form-control-sm"
                      fixedDecimalScale={true}
                      decimalScale={2}
                      required={true}
                      placeholder="7%"
                      value={this.state.marketingFund.value}
                      onValueChange={(v) => {
                        this.setState({
                          marketingFund: v,
                        });
                      }}
                      suffix="%"
                    />
                  </>
                ) : (
                  <>
                    <p className="m-0 text-dark">
                      {this.state.store?.marketingFund
                        ? (this.state.store?.marketingFund * 100).toFixed(2) +
                          "%"
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state?.modifyMarketingFund ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyMarketingFund: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        // Perform rounding to three decimal places
                        let rounded = parseFloat(
                          parseFloat(this.state.marketingFund.value).toFixed(3)
                        );

                        // Check for repeating nines in the thousandths place
                        if ((rounded * 1000) % 10 === 9) {
                          // Adjust to handle repeating nines properly
                          rounded = parseFloat(
                            (
                              parseFloat(this.state.marketingFund.value) +
                              0.00001
                            ).toFixed(3)
                          );
                        }

                        rounded = rounded / 100;

                        this.setValue(
                          "marketingFund",
                          rounded ? rounded : null,
                          `modifyMarketingFund`
                        );
                      }}
                      disabled={this.state.valSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.valSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyMarketingFund: true,
                        marketingFund: this.state.store?.marketingFund
                          ? { value: this.state.store?.marketingFund * 100 }
                          : {
                              value: null,
                            },
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark m-0">Technology Fee:</h4>
            <p className="small text-dark mb-2" style={{ lineHeight: 1.2 }}>
              Monthly fee charged for technology services.
            </p>
            <Row className="align-items-center">
              <Col xs="">
                {this.state?.modifyTechnologyFee ? (
                  <>
                    <NumberFormat
                      className="form-control form-control-sm"
                      fixedDecimalScale={true}
                      decimalScale={2}
                      required={true}
                      placeholder="$350.00"
                      value={this.state.technologyFee.value}
                      onValueChange={(v) => {
                        this.setState({
                          technologyFee: v,
                        });
                      }}
                      prefix="$"
                    />
                  </>
                ) : (
                  <>
                    <p className="m-0 text-dark">
                      {this.state.store?.technologyFee
                        ? `${StringUtils.centsToCurrency(
                            this.state.store?.technologyFee
                          )}`
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state?.modifyTechnologyFee ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyTechnologyFee: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        let value =
                          parseFloat(this.state.technologyFee.value) * 100;

                        value = Math.round(value);

                        this.setValue(
                          "technologyFee",
                          value ? value : null,
                          `modifyTechnologyFee`
                        );
                      }}
                      disabled={this.state.valSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.valSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyTechnologyFee: true,
                        technologyFee: this.state.store?.technologyFee
                          ? { value: this.state.store?.technologyFee / 100 }
                          : {
                              value: null,
                            },
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark m-0">Financial Health Support Fee:</h4>
            <p className="small text-dark mb-2" style={{ lineHeight: 1.2 }}>
              Monthly fee charged for Financial Health Support Program.
            </p>
            <Row className="align-items-center">
              <Col xs="">
                {this.state?.modifyHealthFee ? (
                  <>
                    <NumberFormat
                      className="form-control form-control-sm"
                      fixedDecimalScale={true}
                      decimalScale={2}
                      required={true}
                      placeholder="$350.00"
                      value={this.state.financialHealthFee.value}
                      onValueChange={(v) => {
                        this.setState({
                          financialHealthFee: v,
                        });
                      }}
                      prefix="$"
                    />
                  </>
                ) : (
                  <>
                    <p className="m-0 text-dark">
                      {this.state.store?.financialHealthFee
                        ? `${StringUtils.centsToCurrency(
                            this.state.store?.financialHealthFee
                          )}`
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state?.modifyHealthFee ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyHealthFee: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        let value =
                          parseFloat(this.state.financialHealthFee.value) * 100;

                        value = Math.round(value);

                        this.setValue(
                          "financialHealthFee",
                          value ? value : null,
                          `modifyHealthFee`
                        );
                      }}
                      disabled={this.state.valSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.valSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyHealthFee: true,
                        financialHealthFee: this.state.store?.financialHealthFee
                          ? {
                              value: this.state.store?.financialHealthFee / 100,
                            }
                          : {
                              value: null,
                            },
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark m-0">Self-Funded Local Advertising:</h4>
            <p className="small text-dark mb-2" style={{ lineHeight: 1.2 }}>
              Minimum amount of gross revenue the store must spend on local
              advertising.
            </p>
            <Row className="align-items-center">
              <Col xs="">
                {this.state?.modifyLocalAdvertising ? (
                  <>
                    <NumberFormat
                      className="form-control form-control-sm"
                      fixedDecimalScale={true}
                      decimalScale={2}
                      required={true}
                      placeholder="7%"
                      value={this.state.localAdvertising.value}
                      onValueChange={(v) => {
                        this.setState({
                          localAdvertising: v,
                        });
                      }}
                      suffix="%"
                    />
                  </>
                ) : (
                  <>
                    <p className="m-0 text-dark">
                      {this.state.store?.localAdvertising
                        ? (this.state.store?.localAdvertising * 100).toFixed(
                            2
                          ) + "%"
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state?.modifyLocalAdvertising ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyLocalAdvertising: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        // Perform rounding to three decimal places
                        let rounded = parseFloat(
                          parseFloat(this.state.localAdvertising.value).toFixed(
                            3
                          )
                        );

                        // Check for repeating nines in the thousandths place
                        if ((rounded * 1000) % 10 === 9) {
                          // Adjust to handle repeating nines properly
                          rounded = parseFloat(
                            (
                              parseFloat(this.state.localAdvertising.value) +
                              0.00001
                            ).toFixed(3)
                          );
                        }

                        rounded = rounded / 100;

                        this.setValue(
                          "localAdvertising",
                          rounded ? rounded : null,
                          `modifyLocalAdvertising`
                        );
                      }}
                      disabled={this.state.valSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.valSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyLocalAdvertising: true,
                        localAdvertising: this.state.store?.localAdvertising
                          ? { value: this.state.store?.localAdvertising * 100 }
                          : {
                              value: null,
                            },
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mt-3 border">
          <CardHeader
            className="cursor-pointer"
            onClick={() => {
              this.setState({
                showDates: !this.state.showDates,
              });
            }}
          >
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Milestone Dates</h3>
                <p className="small mb-0">
                  Key dates during the franchise development & agreement term.
                </p>
              </Col>
              <Col xs="auto">
                <h1 className="m-0">
                  <i
                    className={`mdi mdi-chevron-${
                      this.state.showDates ? "down" : "right"
                    }`}
                  ></i>
                </h1>
              </Col>
            </Row>
          </CardHeader>

          <Collapse isOpen={this.state.showDates}>
            {this.state.dates?.map((date, i) => (
              <CardBody className="py-3 border-bottom">
                <h4 className="text-dark">{date?.label}:</h4>
                <Row className="align-items-center">
                  <Col xs="">
                    {this.state[`modify${date?.value}`] ? (
                      <>
                        <DatePicker
                          value={this.state[date?.value]}
                          onChange={(v) => {
                            this.setState({ [date?.value]: v ? v : null });
                          }}
                          placement="auto"
                          oneTap={true}
                          cleanable={true}
                          block
                          format="MM/dd/yyyy"
                        ></DatePicker>
                      </>
                    ) : (
                      <>
                        <p className="m-0 text-dark">
                          {this.getMilestoneDateValue(
                            this.state.store,
                            date?.value
                          )
                            ? moment(
                                this.getMilestoneDateValue(
                                  this.state.store,
                                  date?.value
                                )
                              ).format("MM/DD/YYYY")
                            : "--"}

                          {date?.showTimeUntil &&
                          this.getMilestoneDateValue(
                            this.state.store,
                            date?.value
                          ) ? (
                            <>
                              <span className="pl-2">
                                (
                                {moment(
                                  this.getMilestoneDateValue(
                                    this.state.store,
                                    date?.value
                                  )
                                ).fromNow()}
                                )
                              </span>
                            </>
                          ) : null}
                        </p>
                      </>
                    )}
                  </Col>
                  <Col xs="auto">
                    {this.state[`modify${date?.value}`] ? (
                      <>
                        <Button
                          onClick={() => {
                            this.setState({
                              [`modify${date?.value}`]: false,
                            });
                          }}
                          className="btn-sm btn"
                          color="secondary"
                          outline
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            this.setValue(
                              date?.value,
                              this.state[date?.value]
                                ? moment(this.state[date?.value]).format(
                                    "YYYY-MM-DD"
                                  )
                                : null,
                              `modify${date?.value}`
                            );
                          }}
                          disabled={this.state.valSubmitting}
                          className="btn-sm btn"
                          color="primary"
                        >
                          {this.state.valSubmitting ? (
                            <>
                              <Spinner size="sm"></Spinner>
                            </>
                          ) : (
                            <>Save</>
                          )}
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={() => {
                          this.setState({
                            [`modify${date?.value}`]: true,
                            [date?.value]: this.getMilestoneDateValue(
                              this.state.store,
                              date?.value
                            ),
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Modify
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            ))}
          </Collapse>
        </Card>

        <Card className="mt-3 border">
          <CardHeader
            className="cursor-pointer"
            onClick={() => {
              this.setState({
                showDocuments: !this.state.showDocuments,
              });
            }}
          >
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Documents</h3>
                <p className="small mb-0">
                  Key documents associated with this location
                </p>
              </Col>
              <Col xs="auto">
                <h1 className="m-0">
                  <i
                    className={`mdi mdi-chevron-${
                      this.state.showDocuments ? "down" : "right"
                    }`}
                  ></i>
                </h1>
              </Col>
            </Row>
          </CardHeader>

          <Collapse isOpen={this.state.showDocuments}>
            {this.state.documents?.map((date, i) => (
              <CardBody className="py-3 border-bottom">
                <h4 className="text-dark">{date?.label}:</h4>
                <Row className="align-items-center">
                  <Col
                    xs=""
                    style={{
                      maxWidth: this.state[`modify${date?.value}`]
                        ? "100%"
                        : "calc(100% - 100px)",
                    }}
                  >
                    {this.state[`modify${date?.value}`] ? (
                      <>
                        {this.state[date?.value] ? (
                          <>
                            <Row className="align-items-center mb-3 mb-sm-0">
                              <Col xs="12" sm="auto">
                                <p className="m-0 text-dark text-truncate">
                                  {this.state[date?.value]?.fileName}
                                </p>
                              </Col>
                              <Col xs="12" sm="" className="mt-3 mt-sm-0">
                                <Button
                                  className="btn btn-sm"
                                  color="secondary"
                                  outline
                                  onClick={() => {
                                    this.setState({
                                      fileDrawer: date?.value,
                                    });
                                  }}
                                >
                                  Replace
                                </Button>
                                <Button
                                  className="btn btn-sm"
                                  color="danger"
                                  outline
                                  onClick={() => {
                                    this.setState({
                                      [date?.value]: null,
                                    });
                                  }}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <>
                            <Row className="align-items-center">
                              <Col>
                                <Button
                                  onClick={() => {
                                    this.setState({
                                      fileDrawer: date?.value,
                                    });
                                  }}
                                  className="btn btn-sm"
                                  color="primary"
                                  outline
                                >
                                  Upload A Document
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p className="m-0 text-dark text-truncate">
                          {this.state.store?.[date?.value] ? (
                            <>
                              <a
                                href={`${this.state.store?.[date?.value]?.url}`}
                                onClick={(e) => {
                                  e.preventDefault();

                                  LinkUtils.openPrivateURL(
                                    this.state.store?.[date?.value]?.url
                                  );
                                }}
                                target="_blank"
                              >
                                {this.state.store?.[date?.value]?.fileName}
                              </a>
                            </>
                          ) : (
                            "--"
                          )}
                        </p>
                      </>
                    )}
                  </Col>
                  <Col xs="auto">
                    {this.state[`modify${date?.value}`] ? (
                      <>
                        <Button
                          onClick={() => {
                            this.setState({
                              [`modify${date?.value}`]: false,
                            });
                          }}
                          className="btn-sm btn"
                          color="secondary"
                          outline
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            this.setValue(
                              date?.value,
                              this.state[date?.value]
                                ? this.state[date?.value]
                                : null,
                              `modify${date?.value}`
                            );
                          }}
                          disabled={this.state.valSubmitting}
                          className="btn-sm btn"
                          color="primary"
                        >
                          {this.state.valSubmitting ? (
                            <>
                              <Spinner size="sm"></Spinner>
                            </>
                          ) : (
                            <>Save</>
                          )}
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={() => {
                          this.setState({
                            [`modify${date?.value}`]: true,
                            [date?.value]: this.state.store[date?.value]
                              ? this.state.store[date?.value]
                              : null,
                          });
                        }}
                        className="btn-sm btn"
                        color="secondary"
                        outline
                      >
                        Modify
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            ))}
          </Collapse>
        </Card>
        <Card className="mt-4 border">
          <CardBody>
            <StoreComplianceNotesWidget
              store={this.state.store}
            ></StoreComplianceNotesWidget>
          </CardBody>
        </Card>
        <FileUploadDrawer
          cdn="private"
          compressUploads={false}
          size="sm"
          onComplete={(files) => {
            this.setState({
              fileDrawer: false,
              [this.state.fileDrawer]: files[0],
            });
          }}
          open={this.state.fileDrawer}
          onClose={() => {
            this.setState({
              fileDrawer: false,
            });
          }}
        ></FileUploadDrawer>
        <FranchisePartnerDrawer
          open={this.state.addPartnerOpen}
          store={this.state.store}
          partner={this.state.modifyPartner}
          onClose={() => {
            this.setState({
              addPartnerOpen: false,
              modifyPartner: null,
            });
          }}
        ></FranchisePartnerDrawer>
      </>
    );
  }
}

export default withRouter(StoreViewComplianceTab);
