import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import { Badge, Button, Col, Input, Row, Table } from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import swal from "sweetalert";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import NewOutreachDrawer from "./NewOutreachDrawer";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import NoteBody from "components/NoteBody";
import Spinner from "reactstrap/lib/Spinner";
import AffiliateContactInformationWidget from "components/Widgets/AffiliateContactInformationWidget";
import LinkUtils from "lib/LinkUtils";
import Linkify from "components/Linkify";
import AffiliateAboutWidget from "components/Widgets/AffiliateAboutWidget";

class OutreachDetailDrawer extends React.Component {
  state = {
    dueDate: moment().day(6).toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    type: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    recentFirst: "recentFirst",
    coachIDs: [],
    leads: [],
  };

  dropdownRef = React.createRef();

  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      plan: null,
      leads: [
        {
          _id: StringUtils.uuid(),
          name: "",
          email: "",
          phone: {
            value: null,
          },
        },
      ],
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  async bulkAddLeads() {
    return new Promise((resolve, reject) => {
      let leads = _.filter(this.state.leads, (l) => {
        if (!l.email || !StringUtils.isValidEmail(l.email)) {
          return false;
        }

        return true;
      });

      leads = leads?.map((lead) => {
        return {
          uuid: lead?._id,
          name: lead?.name ? lead.name?.trim() : null,
          email: lead.email?.trim(),
          phone:
            lead?.phone?.value && lead.phone?.value?.toString()?.length == 10
              ? "+1" + lead?.phone?.value
              : null,
          initialSourceType: "Community Event",
          initialSource: `${this.props.event?.name}`,
          communityEventID: this.props.event?._id,
          affiliateID: this.props.event?.affiliateID,
          status: "Not Contacted",
        };
      });

      this.setState({ bulkSubmitting: true });

      console.log(leads);

      APIV2.bulkCreateLeads(this.props?.event?.storeID, leads, true)
        .then(
          (data) => {
            let createdLeads = data?.data?.leads;
            let errorLeads = data?.data?.errors;

            let allLeads = this.state.leads;

            for (let i = 0; i < createdLeads?.length; i++) {
              let idx = _.findIndex(allLeads, { _id: createdLeads[i].uuid });

              if (idx != -1) {
                allLeads[idx].success = true;
                allLeads[idx].error = "";
              }
            }

            for (let i = 0; i < errorLeads?.length; i++) {
              let idx = _.findIndex(allLeads, { _id: errorLeads[i].uuid });

              if (idx != -1) {
                allLeads[idx].success = false;
                allLeads[idx].error = errorLeads[i].error;
              }
            }

            this.setState({ leads: allLeads }, () => {
              return resolve(allLeads);
            });
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                "Unable to add leads - unknown error occurred. Try again."
            );

            return reject(e);
          }
        )
        .finally(() => {
          this.setState({ bulkSubmitting: false });
        });
    });
  }

  async complete() {
    this.setState({ submitting: true });

    if (this.getLeadCount(this.state.leads)) {
      if (this.invalidLeads()) {
        this.setError(
          "leadError",
          "One or more of the leads entered below contains an error. Please correct the errors in the form below or remove the lead from the list before continuing."
        );

        this.setState({ submitting: false });

        return;
      }

      let allLeads = await this.bulkAddLeads();

      if (_.findWhere(allLeads, { success: false })) {
        this.setError(
          "error",
          "Some leads could not be added. Please correct the errors and try again."
        );

        this.setState({ submitting: false });

        return;
      }
    } else if (
      this.props.event?.appointmentType?.includes("or-") &&
      !this.props.event?.appointmentType != "or-dropin"
    ) {
      let conf = await swal({
        title: "No Leads From This Event?",
        text: `Are you sure you want to continue without adding leads from this event?`,
        icon: "warning",
        buttons: ["Go Back", "Continue"],
        dangerMode: true,
      });

      if (!conf) {
        return;
      }
    }

    let payload = {
      staffIDs: this.state.coachIDs,
      completionNote: this.state.consultNotes,
      status: "COMPLETED",
    };

    APIV2.modifyAppointmentInformation(
      this.props?.event?.storeID,
      this?.props?.event?._id,
      payload
    )
      .then(
        (data) => {
          const appointment = data.data.appointment;

          //this.toggleModal();

          this.setState({
            completionOpen: false,
            coachIDs: [],
            consultNotes: "",
            leads: [],
          });

          this.fetchNotes(true);

          PubSub.publish(Event.APPOINTMENT.MODIFIED, appointment);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to update event status - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modifyStatus(status) {
    this.setState({ submitting: true });

    APIV2.modifyAppointmentStatus(
      this.props?.event?.storeID,
      this?.props?.event?._id,
      status
    )
      .then(
        (data) => {
          const appointment = data.data.appointment;

          //this.toggleModal();

          PubSub.publish(Event.APPOINTMENT.MODIFIED, appointment);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to update event status - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  getStoreStaff() {
    this.setState({
      loadingCoaches: true,
    });

    APIV2.getStaffForStore(this.props.event?.storeID, 1, 10000, {
      position: { $nin: ["Terminated", "Applicant"] },
      terminated: { $ne: true },
    })
      .then(
        (data) => {
          let coachOptions = data.data.staff?.map((s) => {
            return {
              value: s._id,
              label: s.name,
            };
          });

          this.setState({
            coaches: data.data.staff,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch coaches.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  fetchNotes(forceLoad = false) {
    if (!this.props.event) {
      return;
    }

    if (forceLoad) {
      this.setState({
        loading: true,
      });
    }

    let query = {};

    if (this.props.event?.customerID) {
      query = {
        customerID: this.props.event?.customerID,
      };
    } else if (this.props.event?.leadID) {
      query = {
        leadID: this.props.event?.leadID,
      };
    } else if (this.props.event?.affiliateID) {
      query = {
        affiliateID: this.props.event?.affiliateID,
      };
    } else {
      query = {
        appointmentID: this?.props?.event?._id,
      };
    }

    let sortBy = {
      createdAt: this.state.recentFirst == "recentFirst" ? -1 : 1,
    };

    APIV2.getNotesForStore(
      this.props?.event?.storeID,
      this.state.page,
      20,
      query,
      sortBy
    )
      .then(
        (data) => {
          let notes = forceLoad ? [] : this.state.notes;

          notes = data?.data?.notes?.length
            ? notes.concat(data?.data?.notes)
            : notes;

          let notesOut = [];

          for (let i = 0; i < notes?.length; i++) {
            if (!_.findWhere(notesOut, { _id: notes[i]?._id })) {
              notesOut.push(notes[i]);
            }
          }

          this.setState({
            notes: notesOut,
            pagination: data?.data?.pagination,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleRecentFirstChange(v) {
    this.setState(
      {
        recentFirst: v,
        page: 1,
      },
      () => {
        this.fetchNotes(true);
      }
    );
  }

  getLeadCount(leads) {
    if (!leads?.length) {
      return 0;
    }

    let count = _.filter(leads, (l) => {
      return l.email || l.phone?.value || l.name;
    }).length;

    return count;
  }

  invalidLeads() {
    if (!this.getLeadCount(this.state.leads)) {
      return false;
    }

    let invalid = _.filter(this.state.leads, (l) => {
      return (
        (!l?.email && (l?.phone?.value || l?.name)) ||
        (l.email && !StringUtils.isValidEmail(l.email)) ||
        (l.phone?.value && l.phone?.value?.toString()?.length != 10)
      );
    });

    //console.log(invalid);

    return invalid.length ? true : false;
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Event Details
            </h3>
            <Drawer.Actions>
              <Whisper
                ref={this.dropdownRef}
                trigger="click"
                placement={"autoVerticalEnd"}
                speaker={
                  <Popover full>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Status</h5>
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.event?.status == "CONFIRMED" ||
                          this.state.submitting
                        }
                        onClick={() => {
                          this.modifyStatus("CONFIRMED");

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as Confirmed
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={
                          this.props.event?.status == "COMPLETED" ||
                          this.state.submitting
                        }
                        onClick={() => {
                          this.setState({
                            completionOpen: true,
                            leads: [
                              {
                                _id: StringUtils.uuid(),
                                name: "",
                                email: "",
                                phone: {
                                  value: null,
                                },
                              },
                            ],
                          });

                          this.getStoreStaff();

                          this.dropdownRef.current.close();
                        }}
                      >
                        Mark as Completed
                      </Dropdown.Item>

                      <Dropdown.Item divider></Dropdown.Item>
                      <Dropdown.Item
                        panel
                        className="py-2 font-weight-800 text-uppercase"
                        style={{ paddingLeft: 12, paddingRight: 12 }}
                      >
                        <h5 className="m-0">Actions</h5>
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          this.setState({
                            modifyOpen: true,
                            modifyEvent: this.props.event,
                          });

                          this.dropdownRef.current.close();
                        }}
                      >
                        Modify Event
                      </Dropdown.Item>
                      <Dropdown.Item divider></Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => {
                          swal({
                            title: "Cancel Event",
                            text: `Are you sure you want to cancel this event?`,
                            icon: "warning",
                            buttons: ["Nevermind", "Cancel"],
                            dangerMode: true,
                          }).then((conf) => {
                            if (!conf) {
                              return;
                            }

                            this.modifyStatus("CANCELLED");

                            this.dropdownRef.current.close();
                          });
                        }}
                      >
                        Cancel Event
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Popover>
                }
              >
                <Button size="sm" outline color="secondary">
                  Actions <i className="mdi mdi-chevron-down pl-2"></i>
                </Button>
              </Whisper>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <Row className="align-items-center mb-2">
                <Col xs="">
                  <h4 className="m-0">
                    {moment(this.props.event?.startsAt).format(
                      "MM/DD/YY hh:mm A"
                    )}
                    {this.props.event?.endsAt ? (
                      <>
                        {" "}
                        - {moment(this.props.event?.endsAt).format("hh:mm A")}
                      </>
                    ) : null}
                  </h4>
                </Col>
                <Col xs="auto">
                  <Badge
                    color="medium"
                    style={{ position: "relative", top: -2 }}
                  >
                    {this.props.event?.status}
                  </Badge>
                </Col>
              </Row>
              <h2 className="m-0">{this.props.event?.name}</h2>
            </FormGroup>

            <Row className="align-items-top">
              <Col xs="12" md="12">
                <FormGroup>
                  <h5>Event Notes</h5>
                  <p className="m-0">
                    {this.props.event?.notes ? this.props.event?.notes : "--"}
                  </p>
                </FormGroup>
              </Col>
              {this.props.event?.status == "CONFIRMED" ? (
                <>
                  <Col xs="12" className="mb-3">
                    <Button
                      block
                      color="primary"
                      onClick={() => {
                        this.setState({
                          completionOpen: true,
                          leads: [
                            {
                              _id: StringUtils.uuid(),
                              name: "",
                              email: "",
                              phone: {
                                value: null,
                              },
                            },
                          ],
                        });

                        this.getStoreStaff();

                        this.dropdownRef.current.close();
                      }}
                    >
                      Mark Event As Completed
                    </Button>
                  </Col>
                </>
              ) : null}
            </Row>
            {this.props.event?.status == "COMPLETED" &&
            this.props.event?.staffID &&
            !this.props.event?.staffList?.length ? (
              <div>
                <hr className="my-3"></hr>

                <Row className="align-items-top">
                  <Col xs="12" md="12">
                    <FormGroup>
                      <h5>Completed By</h5>
                      <p className="m-0">
                        {this.props.event?.staff?.name ?? "Store Employee"}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="12">
                    <FormGroup>
                      <h5>Additional Notes</h5>
                      <p className="m-0">
                        {this.props.event?.completionNote ?? "--"}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            ) : null}
            {this.props.event?.status == "COMPLETED" &&
            this.props.event?.staffList?.length ? (
              <div>
                <hr className="my-3"></hr>

                <Row className="align-items-top">
                  <Col xs="12" md="12">
                    <FormGroup>
                      <h5>Completed By</h5>
                      <p className="m-0">
                        {this.props.event?.staffList
                          ?.map((st) => {
                            return st.name ? st.name : "Store Employee";
                          })
                          ?.join(", ")}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="12">
                    <FormGroup>
                      <h5>Additional Notes</h5>
                      <p className="m-0">
                        {this.props.event?.completionNote ?? "--"}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            ) : null}
            <div>
              {this.props.event?.affiliate ? (
                <>
                  <hr className="my-3"></hr>
                  <Row className="mb-3 align-items-center">
                    <Col xs="">
                      <h4 className="mb-0">Community Partner</h4>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        color="secondary"
                        outline
                        onClick={() => {
                          let url = `/storefront/${this.props?.event?.storeID}/affiliates/details/${this.props.event?.affiliate?._id}/summary`;

                          LinkUtils.openInNewTab(url, true);
                        }}
                      >
                        View
                      </Button>
                    </Col>
                  </Row>

                  {this.props.event?.affiliate ? (
                    <>
                      <AffiliateAboutWidget
                        affiliate={this.props.event?.affiliate}
                        flat={true}
                      ></AffiliateAboutWidget>
                      <AffiliateContactInformationWidget
                        affiliate={this.props.event?.affiliate}
                        flat={true}
                      ></AffiliateContactInformationWidget>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </Drawer.Body>
        </Drawer>
        <NewOutreachDrawer
          store={
            this.state.modifyEvent?.storeID
              ? { _id: this.state.modifyEvent.storeID }
              : null
          }
          open={this.state.modifyOpen}
          event={this.state.modifyEvent}
          affiliate={this.state.modifyEvent?.affiliate}
          onClose={() => {
            this.setState({
              modifyOpen: false,
              modifyEvent: null,
            });
          }}
        ></NewOutreachDrawer>
        <Drawer
          size="lg"
          style={{ maxWidth: "100%" }}
          open={this.state.completionOpen}
          onClose={() =>
            this.setState({
              completionOpen: false,
              coachIDs: [],
              consultNotes: "",
              leads: [],
            })
          }
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Complete Event
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  this.complete();
                }}
                disabled={this.state.submitting || !this.state.coachIDs?.length}
              >
                Complete
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <FormGroup>
              <h5>
                Staff Members&nbsp;<span className="text-danger">*</span>
              </h5>
              <CheckPicker
                loading={this.state.loadingCoaches}
                cleanable
                size="md"
                placeholder="Select Staff Members"
                data={this.state.coachOptions}
                style={{}}
                value={this.state.coachIDs}
                block
                onChange={(val) => {
                  this.setState({ coachIDs: val });
                }}
                placement="auto"
              />
            </FormGroup>
            <FormGroup>
              <h5>Additional Notes</h5>
              <Input
                bsSize="sm"
                type="textarea"
                name="consultNotes"
                placeholder="Notes"
                value={this.state.consultNotes}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <hr></hr>
            <FormGroup>
              <h3 className="text-dark">
                <Badge color="success" className="mr-2">
                  NEW
                </Badge>
                Add Leads From Event (Optional)
              </h3>
              <p className="text-sm text-dark mb-0">
                Bulk add leads collected at this event by Name, Email, and/or
                Phone to Storefront using the form below.
              </p>
              <p className="text-sm text-danger font-weight-bold mt-0">
                Email is required for each lead, Name and Phone are optional.
              </p>
              {this.state.leadError ? (
                <Alert color="danger">
                  One or more of the leads entered below contains errors. Please
                  correct the errors in the form below before continuing.
                </Alert>
              ) : null}
            </FormGroup>
            <div className="table-responsive">
              <Table bordered>
                <thead>
                  <th className="text-dark" colSpan={4}>
                    Leads Collected: {this.getLeadCount(this.state.leads)}
                  </th>
                </thead>
                <thead>
                  <th
                    style={{ minWidth: 200, fontSize: 14 }}
                    className="text-dark px-1 py-2 bg-superlight font-weight-bold"
                  >
                    Name
                  </th>
                  <th
                    style={{ minWidth: 200, fontSize: 14 }}
                    className="text-dark px-1 py-2 bg-superlight font-weight-bold"
                  >
                    Email&nbsp;
                    <span className="text-danger" style={{ fontSize: 12 }}>
                      (Required)
                    </span>
                  </th>
                  <th
                    style={{ minWidth: 200, fontSize: 14 }}
                    className="text-dark px-1 py-2 bg-superlight font-weight-bold"
                  >
                    Phone
                  </th>
                  <th
                    style={{ width: 41 }}
                    className="text-dark px-1 py-2 bg-superlight font-weight-bold"
                  ></th>
                </thead>
                <tbody>
                  {this.state.leads?.map((lead, i) => (
                    <tr key={i}>
                      <td className="p-1">
                        <Input
                          bsSize="sm"
                          type="text"
                          name="leads"
                          placeholder="Name"
                          value={lead.name}
                          onChange={(e) => {
                            let leads = this.state.leads;

                            leads[i].name = e.target.value;

                            this.setState({ leads: leads });
                          }}
                        ></Input>
                      </td>
                      <td className="p-1">
                        <Input
                          bsSize="sm"
                          type="text"
                          name="leads"
                          placeholder="Email"
                          value={lead.email}
                          onChange={(e) => {
                            let leads = this.state.leads;

                            leads[i].email = e.target.value;

                            if (e?.target?.value && i == leads.length - 1) {
                              leads.push({
                                _id: StringUtils.uuid(),
                                name: "",
                                email: "",
                                phone: {
                                  value: null,
                                },
                              });
                            } else if (
                              !e?.target?.value &&
                              !lead?.name &&
                              !lead?.phone?.value &&
                              i < leads.length - 1
                            ) {
                              leads.splice(i, 1);
                            }

                            this.setState({ leads: leads });
                          }}
                        ></Input>
                        {lead?.email &&
                        !StringUtils.isValidEmail(lead?.email) ? (
                          <p
                            className="text-danger text-sm m-0"
                            style={{ lineHeight: 1.1 }}
                          >
                            Please enter a valid email to continue
                          </p>
                        ) : null}
                        {!lead?.email && (lead?.phone?.value || lead?.name) ? (
                          <p
                            className="text-danger text-sm m-0"
                            style={{ lineHeight: 1.1 }}
                          >
                            A valid email is required to continue
                          </p>
                        ) : null}
                        {lead?.error ? (
                          <p
                            className="text-danger text-sm m-0"
                            style={{ lineHeight: 1.1 }}
                          >
                            {lead.error}
                          </p>
                        ) : null}
                      </td>
                      <td className="p-1">
                        <NumberFormat
                          className="form-control form-control-sm"
                          format="(###) ###-####"
                          name="phoneNumberInput"
                          placeholder="Phone Number"
                          onValueChange={(v) => {
                            let leads = this.state.leads;

                            leads[i].phone = v;

                            this.setState({ leads: leads });
                          }}
                          value={lead.phone?.value}
                          type="tel"
                          mask="_"
                        />
                        {lead?.phone?.value &&
                        lead?.phone?.value?.toString()?.length != 10 ? (
                          <p
                            className="text-danger text-sm m-0"
                            style={{ lineHeight: 1.1 }}
                          >
                            Please enter a valid phone
                          </p>
                        ) : null}
                      </td>
                      <td className="p-1">
                        <Button
                          onClick={() => {
                            let leads = this.state.leads;

                            leads.splice(i, 1);

                            this.setState({ leads: leads });
                          }}
                          size="sm"
                          outline
                          color={
                            this.state.bulkSubmitting ||
                            lead?.success ||
                            i == this.state.leads.length - 1
                              ? "light"
                              : "danger"
                          }
                          disabled={
                            this.state.bulkSubmitting ||
                            lead?.success ||
                            i == this.state.leads.length - 1
                          }
                          className="btn-icon-only"
                        >
                          {this.state.bulkSubmitting ? (
                            <Spinner size="sm" color="secondary"></Spinner>
                          ) : null}
                          {!this.state.bulkSubmitting && !lead?.success ? (
                            <i className="mdi mdi-close"></i>
                          ) : null}
                          {!this.state.bulkSubmitting && lead?.success ? (
                            <i className="mdi mdi-check"></i>
                          ) : null}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(OutreachDetailDrawer);
