import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Collapse,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import ProductGroupRow from "components/ProductGroupRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import CreateProductGroupModal from "components/CreateProductGroupModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import _ from "underscore";
import NSOTemplateDrawer from "components/Drawers/NSOTemplateDrawer";
import swal from "sweetalert";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LinkUtils from "lib/LinkUtils";
import ReactPlayer from "react-player";

class TransferClosureTimelineView extends React.Component {
  state = {
    groups: [],
    loading: true,
    expanded: [],
  };

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.dragSection?.actions,
      result.source.index,
      result.destination.index
    );

    let dragSection = this.state.dragSection;

    dragSection.actions = items;

    this.setState({
      dragSection,
    });
  }

  loadNSOTemplate() {
    APIV2.getNSOTemplate()
      .then(
        (data) => {
          let template = data?.data?.template;

          let sections = _.filter(_.keys(template), (key) => {
            return key?.includes("closing_");
          });

          sections = _.map(sections, (section) => {
            let s = template[section];

            s.key = section;

            return s;
          });

          sections = _.sortBy(sections, "order");

          this.setState({
            template,
            sections,
          });
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  saveSectionOrder(section) {
    let actions = section?.actions ? section?.actions : [];

    this.setState({
      dragSubmitting: true,
    });

    APIV2.modifyNSOSection(section?.key, { actions })
      .then(
        (data) => {
          PubSub.publish(Event.NSO_TEMPLATE.MODIFIED, data?.data?.template);

          this.setState({
            dragMode: null,
            dragSection: {},
          });
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          dragSubmitting: false,
        });
      });
  }

  componentDidMount() {
    this.loadNSOTemplate();

    PubSub.subscribe(Event.NSO_TEMPLATE.MODIFIED, (data) => {
      this.loadNSOTemplate();
    });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            title="Transfer & Closure Timelines"
            showBackButton={false}
          />
          {/* Table */}
          {this.state.sections?.map((section, index) => (
            <div key={index}>
              <Card
                className={`shadow border ${
                  index != this.state.sections?.length - 1 ? "mb-4" : ""
                }`}
              >
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="m-0">{section.name}</h3>
                      {section?.description ? (
                        <p
                          className="text-muted mb-0 mt-1 small"
                          style={{ lineHeight: 1.2 }}
                        >
                          {section?.description}
                        </p>
                      ) : null}
                    </Col>
                    <Col xs="auto">
                      {this.state.dragMode == section?.key ? (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            onClick={() => {
                              this.setState({
                                dragMode: null,
                                dragSection: {},
                              });
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() => {
                              // TODO: save order

                              this.saveSectionOrder(this.state.dragSection);
                            }}
                          >
                            {this.state?.dragSubmitting ? (
                              <Spinner size="sm" />
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            outline
                            color="dark"
                            className="btn-icon-only"
                            disabled={!section?.actions?.length}
                            onClick={() => {
                              this.setState({
                                dragMode: section?.key,
                                dragSection: JSON.parse(
                                  JSON.stringify(section)
                                ),
                              });
                            }}
                          >
                            <i className="mdi mdi-swap-vertical"></i>
                          </Button>
                          <Button
                            size="sm"
                            outline
                            color="primary"
                            className="btn-icon-only"
                            onClick={() => {
                              this.setState({
                                open: true,
                                selectedSection: section,
                              });
                            }}
                          >
                            <i className="mdi mdi-plus"></i>
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                {this.state.dragMode == section?.key ? (
                  <>
                    <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: snapshot.isDraggingOver
                                ? "var(--primary-ultralight)"
                                : "",
                              borderRadius: 6,
                            }}
                          >
                            {this.state.dragSection?.actions.map(
                              (item, index) => (
                                <Draggable
                                  key={item._id}
                                  draggableId={item._id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="m-2"
                                    >
                                      <Card className="border rounded">
                                        <CardBody className={`py-2`}>
                                          <Row className="align-items-center">
                                            <Col xs="">
                                              <Row className="align-items-center">
                                                <Col
                                                  xs="auto"
                                                  className="pr-0 pl-1"
                                                >
                                                  <h1 className="m-0">
                                                    <i className="mdi mdi-drag"></i>
                                                  </h1>
                                                </Col>
                                                <Col xs="auto" className="pr-0">
                                                  <div style={{ width: 85 }}>
                                                    <h2 className="m-0">
                                                      <Badge
                                                        color="light"
                                                        className="border"
                                                      >
                                                        {item.timeToFFD > 0
                                                          ? "+"
                                                          : ""}
                                                        {item.timeToFFD} Days
                                                      </Badge>
                                                    </h2>
                                                  </div>
                                                </Col>
                                                <Col xs="">
                                                  <p
                                                    className="mb-1"
                                                    style={{
                                                      lineHeight: 1.2,
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    {item?.assignedTo} (
                                                    <i
                                                      className="mdi mdi-eye"
                                                      style={{
                                                        position: "relative",
                                                        top: 1,
                                                      }}
                                                    ></i>{" "}
                                                    {item?.visibleTo})
                                                  </p>
                                                  <h3
                                                    className="m-0"
                                                    style={{ fontSize: 20 }}
                                                  >
                                                    {item?.name}
                                                  </h3>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </div>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </>
                ) : (
                  <>
                    {section?.actions?.length ? (
                      <>
                        {section?.actions?.map((item, i2) => (
                          <CardBody
                            className={`py-2 ${
                              i2 != section?.actions?.length - 1
                                ? "border-bottom"
                                : ""
                            }`}
                            key={i2}
                          >
                            <Row className="align-items-center">
                              <Col xs="">
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <div style={{ width: 85 }}>
                                      <h2 className="m-0">
                                        <Badge color="light" className="border">
                                          {item.timeToFFD > 0 ? "+" : ""}
                                          {item.timeToFFD} Days
                                        </Badge>
                                      </h2>
                                    </div>
                                  </Col>
                                  <Col xs="">
                                    <p
                                      className="mb-1"
                                      style={{ lineHeight: 1.2, fontSize: 15 }}
                                    >
                                      {item?.assignedTo} (
                                      <i
                                        className="mdi mdi-eye"
                                        style={{ position: "relative", top: 1 }}
                                      ></i>{" "}
                                      {item?.visibleTo})
                                    </p>
                                    <h3
                                      className="m-0"
                                      style={{ fontSize: 20 }}
                                    >
                                      {item?.name}
                                    </h3>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="auto">
                                <Button
                                  size="sm"
                                  outline
                                  color="danger"
                                  className="btn-icon-only"
                                  disabled={this.state.deleting}
                                  onClick={() => {
                                    swal({
                                      title: "Delete Action",
                                      text: `Are you sure you want to remove the "${item?.name}" action?`,
                                      icon: "warning",
                                      buttons: ["Nevermind", "Delete"],
                                      dangerMode: true,
                                    }).then((conf) => {
                                      if (!conf) {
                                        return;
                                      }

                                      this.setState({
                                        deleting: true,
                                      });

                                      APIV2.deleteNSOAction(
                                        section?.key,
                                        item?._id
                                      )
                                        .then((data) => {
                                          PubSub.publish(
                                            Event.NSO_TEMPLATE.MODIFIED,
                                            data?.data?.template
                                          );
                                        })
                                        .finally(() => {
                                          this.setState({
                                            deleting: false,
                                          });
                                        });
                                    });
                                  }}
                                >
                                  <i className="mdi mdi-delete"></i>
                                </Button>
                                <Button
                                  size="sm"
                                  outline
                                  color="dark"
                                  className="btn-icon-only"
                                  onClick={() => {
                                    this.setState({
                                      open: true,
                                      selectedSection: section,
                                      modifyAction: item,
                                    });
                                  }}
                                >
                                  <i className="mdi mdi-pencil"></i>
                                </Button>
                                {item?.topic?._id ||
                                item?.description?.text ||
                                item?.files?.length ? (
                                  <Button
                                    size="sm"
                                    outline
                                    color="dark"
                                    className="btn-icon-only"
                                    onClick={() => {
                                      if (
                                        this.state.expanded?.includes(item?._id)
                                      ) {
                                        this.setState({
                                          expanded: filter(
                                            this.state?.expanded,
                                            (e) => {
                                              return e != item?._id;
                                            }
                                          ),
                                        });

                                        return;
                                      }

                                      this.setState({
                                        expanded: [
                                          ...this.state?.expanded,
                                          item?._id,
                                        ],
                                      });
                                    }}
                                  >
                                    <i
                                      className={`${
                                        this.state?.expanded?.includes(
                                          item?._id
                                        )
                                          ? "mdi mdi-chevron-down"
                                          : "mdi mdi-chevron-right"
                                      }`}
                                    ></i>
                                  </Button>
                                ) : null}
                              </Col>
                            </Row>
                            <Collapse
                              isOpen={this.state?.expanded?.includes(item?._id)}
                            >
                              <Row className="align-items-center">
                                <Col xs="auto" className="pr-0">
                                  <div style={{ width: 85 }}></div>
                                </Col>
                                <Col xs="">
                                  {item?.topic?._id ? (
                                    <>
                                      <div className="mt-2 p-3 border rounded">
                                        <div className="border-bottom mb-3 pb-3">
                                          <Row className="align-items-center">
                                            <Col xs="">
                                              <h3 className="text-dark m-0">
                                                {item.topic?.name}
                                              </h3>
                                              {item.topic?.description ? (
                                                <p
                                                  className="text-dark small m-0"
                                                  style={{ lineHeight: 1.2 }}
                                                >
                                                  {item.topic?.description}
                                                </p>
                                              ) : null}
                                            </Col>
                                            <Col xs="auto">
                                              <Button
                                                size="sm"
                                                color="secondary"
                                                outline
                                                onClick={() => {
                                                  LinkUtils.openInNewTab(
                                                    `/university/library/topics/${item.topic._id}`,
                                                    true
                                                  );
                                                }}
                                              >
                                                View Topic
                                              </Button>
                                            </Col>
                                          </Row>
                                        </div>
                                        {item.topic?.videoURL ? (
                                          <>
                                            {item.topic?.videoURL?.includes(
                                              "<iframe"
                                            ) ? (
                                              <>
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      item.topic?.videoURL,
                                                  }}
                                                  className="mb-4 border border-midlighter"
                                                  style={{ width: "100%" }}
                                                ></div>
                                              </>
                                            ) : (
                                              <>
                                                <ReactPlayer
                                                  className="mb-4 border border-midlighter"
                                                  width={"100%"}
                                                  url={item.topic?.videoURL}
                                                />
                                              </>
                                            )}
                                          </>
                                        ) : null}
                                        <div
                                          className="plnu-topic"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              typeof item?.topic?.content ==
                                              "string"
                                                ? item?.topic?.content
                                                : item?.topic?.content?.html,
                                          }}
                                        ></div>
                                        {item.topic?.attachments?.length ? (
                                          <Card className="border">
                                            <CardHeader className="p-3">
                                              <h3
                                                className="m-0 text-dark"
                                                style={{ lineHeight: 1 }}
                                              >
                                                Attachments
                                              </h3>
                                            </CardHeader>
                                            <CardBody className="p-3">
                                              {item.topic?.attachments?.map(
                                                (at, i) => (
                                                  <div
                                                    className={`p-2 border rounded ${
                                                      i !=
                                                        item.topic?.attachments
                                                          ?.length -
                                                          1 && "mb-2"
                                                    }`}
                                                    key={i}
                                                  >
                                                    <Row className="align-items-center">
                                                      <Col xs="12" sm="">
                                                        <p className="small m-0 text-dark text-truncate">
                                                          {at?.fileName}
                                                        </p>
                                                      </Col>
                                                      <Col
                                                        xs="12"
                                                        sm="auto"
                                                        className="mt-3 mt-sm-0 text-right"
                                                      >
                                                        <Button
                                                          className="btn-icon-only"
                                                          size="sm"
                                                          outline
                                                          color="secondary"
                                                          onClick={() => {
                                                            LinkUtils.openPrivateURL(
                                                              at?.url
                                                            );
                                                          }}
                                                        >
                                                          <i className="mdi mdi-eye"></i>
                                                        </Button>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                )
                                              )}
                                            </CardBody>
                                          </Card>
                                        ) : null}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="mt-2 p-3 border rounded"
                                        dangerouslySetInnerHTML={{
                                          __html: item?.description?.html,
                                        }}
                                      ></div>
                                      {item?.description?.text &&
                                      item?.files &&
                                      item?.files?.length ? (
                                        <hr className="my-3"></hr>
                                      ) : null}
                                    </>
                                  )}
                                  {item?.files && item?.files?.length ? (
                                    <>
                                      <h4 className="my-3">Files:</h4>
                                      {item?.files?.map((at, i) => (
                                        <div
                                          className={`border p-2 rounded mb-2`}
                                          key={i}
                                        >
                                          <Row className="align-items-center">
                                            <Col xs="12" sm="">
                                              <p className="small m-0 text-dark text-truncate">
                                                {at?.fileName}
                                              </p>
                                            </Col>
                                            <Col
                                              xs="12"
                                              sm="auto"
                                              className="mt-3 mt-sm-0 text-right"
                                            >
                                              <Button
                                                className="btn-icon-only"
                                                size="sm"
                                                outline
                                                color="secondary"
                                                disabled={!at?.url}
                                                onClick={() => {
                                                  if (!at?.url) {
                                                    return;
                                                  }

                                                  LinkUtils.openPrivateURL(
                                                    at?.url
                                                  );
                                                }}
                                              >
                                                <i className="mdi mdi-eye"></i>
                                              </Button>
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}
                                    </>
                                  ) : null}
                                </Col>
                              </Row>
                            </Collapse>
                          </CardBody>
                        ))}
                      </>
                    ) : (
                      <CardBody>
                        <p className="m-0">
                          Get started by adding your first action to the{" "}
                          {section.name} stage.
                        </p>
                      </CardBody>
                    )}
                  </>
                )}
              </Card>
            </div>
          ))}
        </Container>
        <NSOTemplateDrawer
          targetDateString={"Transfer/Closure Date"}
          open={this.state.open}
          section={this.state.selectedSection}
          onClose={() => {
            this.setState({
              open: false,
              selectedSection: null,
              modifyAction: null,
            });
          }}
          action={this.state.modifyAction}
        ></NSOTemplateDrawer>
      </>
    );
  }
}

export default TransferClosureTimelineView;
