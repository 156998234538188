import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminFooter from "components/Footers/AdminFooter.js";

import routes from "routes.js";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import User from "lib/Model/User";
import PubSub from "lib/PubSub";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import AdminNavbarV2 from "components/Navbars/AdminNavbarV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import MealList from "views/examples/MealList";
import routesv2 from "routesv2";
import { Capacitor } from "@capacitor/core";
import LibraryHome from "views/university/LibraryHome";
import LibraryModule from "views/university/LibraryModule";
import LibraryTopic from "views/university/LibraryTopic";
import BrandCentralView from "views/university/BrandCentralView";
import ContentCalendarView from "views/marketing/ContentCalendarView";
import SocialContentListView from "views/marketing/SocialContentListView";
import LearningCenterHome from "views/university/LearningCenterHome";
import LearningManagerCertificationsListView from "views/university/LearningManagerCertificationsListView";
import LearningManagerTrackListView from "views/university/LearningManagerTrackListView";
import LearningManagerCoursesListView from "views/university/LearningManagerCoursesListView";
import LearningCenterCourseLibrary from "views/university/LearningCenterCourseLibrary";
import LearningCenterCourseSummary from "views/university/LearningCenterCourseSummary";
import StaffRolesListView from "views/university/StaffRolesListView";
import LearningManagerCourseView from "views/university/LearningManagerCourseView";

class University extends React.Component {
  componentDidUpdate(e) {
    if (e.history?.location?.state?.noScroll) {
      return;
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  componentWillMount() {
    if (Capacitor.getPlatform() == "ios") {
      document.body.className += " ios-padding";
    }

    if (LocalStorage.get(Constant.CACHE.TOKEN)) {
      API.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2._wsConnect();
    }

    if (LocalStorage.get(Constant.CACHE.USER)) {
      window[Constant.CACHE.USER] = User._fromData(
        LocalStorage.get(Constant.CACHE.USER)
      );
      PubSub.publish(Event.USER_LOADED, window[Constant.CACHE.USER]);
    }

    APIV2.getAccount().then(
      (data) => {
        if (data && data.data && data.data.user) {
          data.data.user.id = data.data.user._id;

          let user = data.data.user;

          window[Constant.CACHE.USER] = user;
          LocalStorage.set(Constant.CACHE.USER, user);

          console.log("fetched user");

          PubSub.publish(Event.USER_LOADED, user);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  render() {
    if (!LocalStorage.get(Constant.CACHE.TOKEN)) {
      this.props.history.push("/auth");
    }

    return (
      <>
        <SidebarV2
          {...this.props}
          routes={routesv2.universityRoutes}
          basePath={"/university"}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbarV2
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            searchMode="university"
            searchPlaceholder="Search Modules & Topics..."
          />
          <Switch>
            <Route path="/university/library" component={LibraryHome} exact />

            <Route
              path="/university/library/modules/:moduleID"
              component={LibraryModule}
              exact
            />
            <Route
              path="/university/library/topics/:topicID"
              component={LibraryTopic}
              exact
            />
            <Route
              path="/university/assets"
              component={BrandCentralView}
              exact
            />

            <Route
              path="/university/assets/social"
              component={SocialContentListView}
              exact
            />

            <Route
              path="/university/learning"
              component={LearningCenterHome}
              exact
            />
            <Route
              path="/university/learning/courses"
              component={LearningCenterCourseLibrary}
              exact
            />
            <Route
              path="/university/learning/courses/:courseID"
              component={LearningCenterCourseSummary}
              exact
            />

            <Route
              path="/university/lmsmanager"
              component={LearningManagerCoursesListView}
              exact
            />
            <Route
              path="/university/lmsmanager/courses/:courseID/:tabID"
              component={LearningManagerCourseView}
              exact
            />

            <Route
              path="/university/lmsmanager/roles"
              component={StaffRolesListView}
              exact
            />
            <Route
              path="/university/lmsmanager/tracks"
              component={LearningManagerTrackListView}
              exact
            />
            <Route
              path="/university/lmsmanager/certifications"
              component={LearningManagerCertificationsListView}
              exact
            />

            <Redirect from="*" to="/university/library" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default University;
