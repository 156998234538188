import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Collapse,
  Badge,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import ModifyStoreBillingAccountModal from "./ModifyStoreBillingAccountModal";

import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import NumberFormat from "react-number-format";
import Spinner from "reactstrap/lib/Spinner";
import { withRouter } from "react-router";
import StoreNotesWidget from "./Widgets/StoreNotesWidget";
import moment from "moment";
import { ProgressBar } from "@tremor/react";
import _ from "underscore";
import StoreNSOWidget from "./Widgets/StoreNSOWidget";
import { DatePicker } from "rsuite";

class StoreViewSupportTab extends React.Component {
  state = {
    store: null,
    expanded: [],
    supportRegions: [
      {
        name: "Carolina Core",
      },
      {
        name: "Central Champions",
      },
      {
        name: "Midwest Movers",
      },
      {
        name: "Northeast Navigators",
      },
      {
        name: "SouthEast Stronghold",
      },
    ],
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  setSuccessScoreVisit() {
    this.setState({
      successVisitScoreSubmitting: true,
    });

    let score = this.state.successVisitScore;

    if (score) {
      score = parseInt(score);
    }

    if (!isNaN(score)) {
      if (score > 100) {
        score = 100;
      }

      if (score < 0) {
        score = 0;
      }
    }

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "successVisitScore",
      score
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifySuccessVisitScore: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Success Visit Score: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          successVisitScoreSubmitting: false,
        });
      });
  }

  setLastVisitDate() {
    this.setState({
      lastVisitDateSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "lastVisitDate",
      this.state.lastVisitDate
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyLastVisitDate: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Last Visit Date: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          lastVisitDateSubmitting: false,
        });
      });
  }

  setOrderGoal() {
    this.setState({
      orderGoalSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "weeklyOrderGoal",
      this.state.orderGoal ? parseInt(this.state?.orderGoal) : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyOrderGoal: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Weekly Order Goal: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          orderGoalSubmitting: false,
        });
      });
  }

  setQuarterlyOrderGoal() {
    this.setState({
      quarterlyOrderGoalSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "quarterlyOrderGoal",
      this.state.quarterlyOrderGoal
        ? parseInt(this.state?.quarterlyOrderGoal)
        : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyQuarterlyOrderGoal: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Quarterly Order Goal: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          quarterlyOrderGoalSubmitting: false,
        });
      });
  }

  setQuarterlyMESGoal() {
    this.setState({
      mesGoalSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "quarterlyMESGoal",
      this.state.quarterlyMESGoal
        ? parseInt(this.state?.quarterlyMESGoal)
        : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyMESGoal: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Quarterly MES Goal: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          mesGoalSubmitting: false,
        });
      });
  }

  setQuarterlyCESGoal() {
    this.setState({
      cesGoalSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "quarterlyCESGoal",
      this.state.quarterlyCESGoal
        ? parseInt(this.state?.quarterlyCESGoal)
        : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyCESGoal: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Quarterly CES Goal: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          cesGoalSubmitting: false,
        });
      });
  }

  setLevel() {
    this.setState({
      levelSubmitting: true,
    });

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "level",
      this.state.level ? this.state?.level : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyLevel: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Store Level: " + e?.message ?? "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          levelSubmitting: false,
        });
      });
  }

  setValue(key, value, modifyKey = "") {
    this.setState({
      valSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(this.state?.store?._id, key, value)
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          if (modifyKey) {
            this.setState({
              [modifyKey]: false,
            });
          }
        },
        (e) => {
          window.alert("Unable to modify: " + e?.message ?? "error occurred.");
        }
      )
      .finally(() => {
        this.setState({
          valSubmitting: false,
        });
      });
  }

  setRetailGoal() {
    this.setState({
      retailGoalSubmitting: true,
    });

    let value = this.state.retailGoal?.value
      ? parseFloat(this.state.retailGoal?.value) * 100
      : null;

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "weeklyRetailGoal",
      value
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifyRetailGoal: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Weekly Retail Goal: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          retailGoalSubmitting: false,
        });
      });
  }

  setSupportLevel() {
    this.setState({
      SupportLevelSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "supportLevel",
      this.state.supportLevel ? this.state?.supportLevel : ""
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifySupportLevel: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Support Level: " + e?.message ?? "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          SupportLevelSubmitting: false,
        });
      });
  }

  setSupportRegion() {
    this.setState({
      SupportRegionSubmitting: true,
    });

    // TODO: implement NSO update here

    APIV2.modifyStoreParameter(
      this.state?.store?._id,
      "supportRegion",
      this.state.supportRegion ? this.state?.supportRegion : ""
    )
      .then(
        (data) => {
          PubSub.publish(Event.STORE.MODIFIED, data.data.store);

          this.setState({
            modifySupportRegion: false,
          });
        },
        (e) => {
          window.alert(
            "Unable to modify Support Region: " + e?.message ??
              "error occurred."
          );
        }
      )
      .finally(() => {
        this.setState({
          SupportRegionSubmitting: false,
        });
      });
  }

  componentDidMount() {
    this.loadStore();

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });
    });
  }

  render() {
    return (
      <>
        <Card className="mt-3">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Support Region</h3>
                <p className="small mb-0">
                  The regional support group this store is assigned to.
                </p>
              </Col>
              <Col xs="auto">
                {this.state.modifySupportRegion ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifySupportRegion: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setSupportRegion();
                      }}
                      disabled={this.state.SupportRegionSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.SupportRegionSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifySupportRegion: true,
                        supportRegion: this.state.store?.supportRegion ?? "",
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {this.state.modifySupportRegion ? (
              <>
                <Input
                  type="select"
                  name="supportRegion"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.supportRegion}
                  placeholder="Support Region"
                >
                  <option value="">(none set)</option>
                  {this.state.supportRegions.map((region) => {
                    return <option value={region.name}>{region.name}</option>;
                  })}
                </Input>
              </>
            ) : (
              <>
                <p className="m-0">
                  {this.state.store?.supportRegion
                    ? this.state.store?.supportRegion
                    : "(none set)"}
                </p>
              </>
            )}
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Performance Tier</h3>
                <p className="small mb-0">
                  The performance tier this store is executing at.
                </p>
              </Col>
              <Col xs="auto">
                {this.state.modifySupportLevel ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifySupportLevel: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setSupportLevel();
                      }}
                      disabled={this.state.SupportLevelSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.SupportLevelSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifySupportLevel: true,
                        supportLevel: this.state.store?.supportLevel ?? "",
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {this.state.modifySupportLevel ? (
              <>
                <Input
                  type="select"
                  name="supportLevel"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.supportLevel}
                  placeholder="Support Level"
                >
                  <option value="">(none set)</option>
                  <option value="Impact Level">Impact Level</option>
                  <option value="Growth">Growth Level</option>
                  <option value="Critical Level">Critical Level</option>
                  <option value="Pre-Open Level">Pre-Open Level</option>
                  <option value="Post-Open Level">Post-Open Level</option>
                </Input>
              </>
            ) : (
              <>
                <p className="m-0">
                  {this.state.store?.supportLevel
                    ? this.state.store?.supportLevel
                    : "(none set)"}
                </p>
              </>
            )}
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Financial Health Program</h3>
                <p className="small mb-0">
                  Does this store participate in the Financial Health Program?
                </p>
              </Col>
              <Col xs="auto">
                {this.state.modifyFinancialHealth ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyFinancialHealth: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setValue(
                          "financialHealth",
                          this.state.financialHealth == "true",
                          "modifyFinancialHealth"
                        );
                      }}
                      disabled={this.state.FinancialHealthSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.FinancialHealthSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyFinancialHealth: true,
                        financialHealth: this.state.store?.financialHealth
                          ? "true"
                          : "false",
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {this.state.modifyFinancialHealth ? (
              <>
                <Input
                  type="select"
                  name="financialHealth"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.financialHealth}
                  placeholder="Support Level"
                >
                  <option value="" disabled>
                    Select A Value
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Input>
              </>
            ) : (
              <>
                <p className="m-0">
                  {this.state.store?.financialHealth ? "Yes" : "No"}
                </p>
              </>
            )}
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Scorecard Goals</h3>
                <p className="small mb-0">Set the stores's scorecard goals</p>
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark">Weekly Member Order Goal:</h4>
            <Row className="align-items-center">
              <Col xs="">
                {this.state.modifyOrderGoal ? (
                  <>
                    <Input
                      type="number"
                      value={this.state.orderGoal}
                      onChange={this.handleInputChange.bind(this)}
                      name="orderGoal"
                      placeholder="Order Quantity"
                      bsSize="sm"
                    ></Input>
                  </>
                ) : (
                  <>
                    <p className="m-0">
                      {this.state.store?.weeklyOrderGoal
                        ? StringUtils.numberFormat(
                            this.state.store?.weeklyOrderGoal
                          )
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state.modifyOrderGoal ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyOrderGoal: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setOrderGoal();
                      }}
                      disabled={this.state.orderGoalSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.orderGoalSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyOrderGoal: true,
                        orderGoal: this.state.store?.weeklyOrderGoal
                          ? this.state.store?.weeklyOrderGoal
                          : null,
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark">Weekly Retail Sales Goal:</h4>
            <Row className="align-items-center">
              <Col xs="">
                {this.state.modifyRetailGoal ? (
                  <>
                    <NumberFormat
                      className="form-control form-control-sm"
                      fixedDecimalScale={true}
                      decimalScale={2}
                      required={true}
                      placeholder="$0.00"
                      value={this.state.retailGoal.value}
                      onValueChange={(v) => {
                        this.setState({
                          retailGoal: v,
                        });
                      }}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </>
                ) : (
                  <>
                    <p className="m-0">
                      {this.state.store?.weeklyRetailGoal
                        ? StringUtils.centsToCurrency(
                            this.state.store?.weeklyRetailGoal
                          )
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state.modifyRetailGoal ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyRetailGoal: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setRetailGoal();
                      }}
                      disabled={this.state.retailGoalSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.retailGoalSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyRetailGoal: true,
                        retailGoal: {
                          value: this.state.store?.weeklyRetailGoal
                            ? (
                                this.state.store?.weeklyRetailGoal / 100
                              )?.toString()
                            : "",
                        },
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Quarterly Growth Goals</h3>
                <p className="small mb-0">
                  Set the stores's quarterly growth goals for completion by{" "}
                  {moment()
                    .endOf("quarter")
                    .startOf("day")
                    .format("MM/DD/YYYY")}
                  .
                </p>
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark">Weekly Member Order Goal:</h4>
            <Row className="align-items-center">
              <Col xs="">
                {this.state.modifyQuarterlyOrderGoal ? (
                  <>
                    <Input
                      type="number"
                      value={this.state.quarterlyOrderGoal}
                      onChange={this.handleInputChange.bind(this)}
                      name="quarterlyOrderGoal"
                      placeholder="Weekly Member Order Quantity"
                      bsSize="sm"
                    ></Input>
                  </>
                ) : (
                  <>
                    <p className="m-0">
                      {this.state.store?.quarterlyOrderGoal
                        ? StringUtils.numberFormat(
                            this.state.store?.quarterlyOrderGoal
                          )
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state.modifyQuarterlyOrderGoal ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyQuarterlyOrderGoal: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setQuarterlyOrderGoal();
                      }}
                      disabled={this.state.quarterlyOrderGoalSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.quarterlyOrderGoalSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyQuarterlyOrderGoal: true,
                        quarterlyOrderGoal: this.state.store?.quarterlyOrderGoal
                          ? this.state.store?.quarterlyOrderGoal
                          : null,
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark">Member Experience Score:</h4>
            <Row className="align-items-center">
              <Col xs="">
                {this.state.modifyMESGoal ? (
                  <>
                    <Input
                      type="number"
                      value={this.state.quarterlyMESGoal}
                      onChange={this.handleInputChange.bind(this)}
                      name="quarterlyMESGoal"
                      placeholder="Member Experience Score (0-100)"
                      bsSize="sm"
                    ></Input>
                  </>
                ) : (
                  <>
                    <p className="m-0">
                      {this.state.store?.quarterlyMESGoal
                        ? `${StringUtils.numberFormat(
                            this.state.store?.quarterlyMESGoal
                          )}%`
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state.modifyMESGoal ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyMESGoal: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setQuarterlyMESGoal();
                      }}
                      disabled={this.state.mesGoalSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.mesGoalSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyMESGoal: true,
                        quarterlyMESGoal: this.state.store?.quarterlyMESGoal
                          ? this.state.store?.quarterlyMESGoal
                          : null,
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardBody className="py-3 border-bottom">
            <h4 className="text-dark">Community Engagement Score:</h4>
            <Row className="align-items-center">
              <Col xs="">
                {this.state.modifyCESGoal ? (
                  <>
                    <Input
                      type="number"
                      value={this.state.quarterlyCESGoal}
                      onChange={this.handleInputChange.bind(this)}
                      name="quarterlyCESGoal"
                      placeholder="Community Engagement Score (0-100)"
                      bsSize="sm"
                    ></Input>
                  </>
                ) : (
                  <>
                    <p className="m-0">
                      {this.state.store?.quarterlyCESGoal
                        ? `${StringUtils.numberFormat(
                            this.state.store?.quarterlyCESGoal
                          )}%`
                        : "--"}
                    </p>
                  </>
                )}
              </Col>
              <Col xs="auto">
                {this.state.modifyCESGoal ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyCESGoal: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setQuarterlyCESGoal();
                      }}
                      disabled={this.state.cesGoalSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.cesGoalSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyCESGoal: true,
                        quarterlyCESGoal: this.state.store?.quarterlyCESGoal
                          ? this.state.store?.quarterlyCESGoal
                          : null,
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Success Visit Score</h3>
                <p className="small mb-0">
                  Score of the last success visit out of 100.
                </p>
              </Col>
              <Col xs="auto">
                {this.state.modifySuccessVisitScore ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifySuccessVisitScore: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setSuccessScoreVisit();
                      }}
                      disabled={this.state.successVisitScoreSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.successVisitScoreSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifySuccessVisitScore: true,
                        successVisitScore:
                          this.state.store?.successVisitScore ?? "",
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {this.state.modifySuccessVisitScore ? (
              <>
                <Input
                  type="number"
                  name="successVisitScore"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.successVisitScore}
                ></Input>
              </>
            ) : (
              <>
                <p className="m-0">
                  {this.state.store?.successVisitScore
                    ? `${this.state.store?.successVisitScore}%`
                    : "(none set)"}
                </p>
              </>
            )}
          </CardBody>
        </Card>

        <Card className="mt-3">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="mb-0">Last Visit Date</h3>
                <p className="small mb-0">
                  The most recent date a success visit was conducted.
                </p>
              </Col>
              <Col xs="auto">
                {this.state.modifyLastVisitDate ? (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          modifyLastVisitDate: false,
                        });
                      }}
                      className="btn-sm btn"
                      color="secondary"
                      outline
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setLastVisitDate();
                      }}
                      disabled={this.state.lastVisitDateSubmitting}
                      className="btn-sm btn"
                      color="primary"
                    >
                      {this.state.lastVisitDateSubmitting ? (
                        <>
                          <Spinner size="sm"></Spinner>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      this.setState({
                        modifyLastVisitDate: true,
                        lastVisitDate: this.state.store?.lastVisitDate
                          ? moment(this.state.store?.lastVisitDate).toDate()
                          : null,
                      });
                    }}
                    className="btn-sm btn"
                    color="secondary"
                    outline
                  >
                    Modify
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {this.state.modifyLastVisitDate ? (
              <>
                <DatePicker
                  value={this.state.lastVisitDate}
                  onChange={(v) => {
                    let m = v;

                    if (v) {
                      m = moment(v);

                      m.startOf("day");
                    }

                    this.setState({ lastVisitDate: v ? m.toDate() : null });
                  }}
                  placement="auto"
                  oneTap={true}
                  cleanable={true}
                  block
                  format="MM/dd/yyyy"
                ></DatePicker>
              </>
            ) : (
              <>
                <p className="m-0">
                  {this.state.store?.lastVisitDate
                    ? moment(this.state.store?.lastVisitDate).format(
                        "MM/DD/YYYY"
                      )
                    : "(none set)"}
                </p>
              </>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(StoreViewSupportTab);
