import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";

class StorefrontCustomerListView extends React.Component {
  state = {
    active: {
      id: "Active",
      name: "All Active",
    },
    tabs: [
      {
        name: "All Active",
        id: "Active",
      },

      {
        name: "Active This Week",
        id: "Active This Week",
      },
      {
        name: "Active Next Week",
        id: "Active Next Week",
      },
      {
        name: "Members Week 1-4",
        id: "Members Week 1-4",
      },
      {
        name: "HFN Risk (4+ Orders Skipped)",
        id: "HFN Risk",
      },

      {
        name: "New This Week",
        id: "New This Week",
      },
      {
        name: "New Last Week",
        id: "New Last Week",
      },
      {
        name: "Reactivated This Week",
        id: "Reactivated This Week",
      },
      {
        name: "Reactivated Last Week",
        id: "Reactivated Last Week",
      },
      /*{
        name: "HFN Recommended (16+ Orders Skipped)",
        id: "HFN Recommended",
      },*/
      {
        name: "HFN This Week",
        id: "HFN This Week",
      },
      {
        name: "HFN Last Week",
        id: "HFN Last Week",
      },

      {
        name: "All HFN",
        id: "HFN",
      },
      {
        name: "All",
        id: "All",
      },
    ],
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 15,
    filters: null,
  };

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      console.log("UPDATE");

      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }

    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID &&
      this.props.match?.params?.storeID &&
      this.props.match?.params?.storeID != "select"
    ) {
      this.loadTabCounts(true);
    }
  }

  componentDidMount() {
    this.loadTabCounts(true);

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    this.loadStores();

    PubSub.subscribe(Event.MEAL.DELETED, (data) => {
      let g = filter(this.state.meals, (a) => {
        return a.id != data._id;
      });

      this.setState({
        meals: g,
      });
    });

    PubSub.subscribe(Event.MEAL.CREATED, (m) => {
      let g = this.state.meals;

      g.push(m);

      this.setState({
        meals: g,
      });
    });

    PubSub.subscribe(Event.MEAL.MODIFIED, (a) => {
      let g = this.state.meals;
      let idx = findIndex(g, { _id: a._id });

      g[idx] = a;

      this.setState({
        meals: g,
      });
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadCustomers(page = 1, count = 15, filters = null, orderBy = null) {
    this.setState({
      loadingCustomers: true,
    });

    if (!orderBy) {
      orderBy = {
        name: 1,
      };
    } else if (orderBy.hasOwnProperty("subscriptionObject.lastBillDate")) {
      if (!filters) {
        filters = {
          "subscriptionObject.lastBillDate": { $nin: [null, ""] },
        };
      } else {
        filters["subscriptionObject.lastBillDate"] = { $nin: [null, ""] };
      }
    }

    APIV2.getCustomersForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let customers = [];

            if (data.data.customers.length) {
              customers = data.data.customers;
            }

            this.setState({
              customers,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the members. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingCustomers: false,
        });

        this.loadTabCounts();
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchCustomersForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultCustomers: data.data.customers,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultCustomers: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoreByID(this.props.match.params.storeID)
      .then(
        (data) => {
          if (data && data.data) {
            let store = data?.data?.store;

            this.setState({ store });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the store. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadCustomers(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  getFilters(option) {
    let filters = null;

    if (option.id == "All") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
      };
    } else if (option.id == "Active") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        storeID: this.props?.match?.params?.storeID,
      };
    } else if (option.id == "Active This Week") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        "subscriptionObject.nextBillDate": moment().day(6).format("YYYY-MM-DD"),
        storeID: this.props?.match?.params?.storeID,
      };
    } else if (option.id == "New This Week") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        storeID: this.props?.match?.params?.storeID,
        $or: [
          {
            signupDate: {
              $gte: {
                $date: moment()
                  .day(0)
                  .hour(0)
                  .minute(0)
                  .seconds(0)
                  .millisecond(0)
                  .toDate()
                  .toISOString(),
              },
              $lt: {
                $date: moment()
                  .day(6)
                  .hour(23)
                  .minute(59)
                  .seconds(59)
                  .millisecond(999)
                  .toDate()
                  .toISOString(),
              },
            },
          },
        ],
      };
    } else if (option.id == "Members Week 1-4") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        storeID: this.props?.match?.params?.storeID,
        $or: [
          {
            subscriptionWeeks: { $lt: 4 },
          },
          { subscriptionWeeks: null },
        ],
      };
    } else if (option.id == "HFN Risk") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        "subscriptionObject.pauseCounter": { $gte: 4 },
        storeID: this.props?.match?.params?.storeID,
      };
    } else if (option.id == "HFN Recommended") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        "subscriptionObject.pauseCounter": { $gte: 16 },
        storeID: this.props?.match?.params?.storeID,
      };
    } else if (option.id == "Reactivated This Week") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        storeID: this.props?.match?.params?.storeID,
        "subscriptionObject.reactivateDate": {
          $gte: {
            $date: moment()
              .day(0)
              .hours(0)
              .minutes(0)
              .seconds(0)
              .milliseconds(0)
              .toDate()
              .toISOString(),
          },
          $lte: {
            $date: moment()
              .day(6)
              .hours(23)
              .minutes(59)
              .seconds(59)
              .milliseconds(999)
              .toDate()
              .toISOString(),
          },
        },
      };
    } else if (option.id == "New Last Week") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        storeID: this.props?.match?.params?.storeID,
        $or: [
          {
            signupDate: {
              $gte: {
                $date: moment()
                  .day(0)
                  .hour(0)
                  .minute(0)
                  .seconds(0)
                  .millisecond(0)
                  .subtract(1, "week")
                  .toDate()
                  .toISOString(),
              },
              $lt: {
                $date: moment()
                  .day(6)
                  .hour(23)
                  .minute(59)
                  .seconds(59)
                  .millisecond(999)
                  .subtract(1, "week")
                  .toDate()
                  .toISOString(),
              },
            },
          },
        ],
      };
    } else if (option.id == "Reactivated Last Week") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        storeID: this.props?.match?.params?.storeID,
        "subscriptionObject.reactivateDate": {
          $gte: {
            $date: moment()
              .day(0)
              .hour(0)
              .minute(0)
              .seconds(0)
              .millisecond(0)
              .subtract(1, "week")
              .toDate()
              .toISOString(),
          },
          $lt: {
            $date: moment()
              .day(6)
              .hour(23)
              .minute(59)
              .seconds(59)
              .millisecond(999)
              .subtract(1, "week")
              .toDate()
              .toISOString(),
          },
        },
      };
    } else if (option.id == "Active Next Week") {
      filters = {
        "subscriptionObject.status": "ACTIVE",
        storeID: this.props?.match?.params?.storeID,
        "subscriptionObject.nextBillDate": moment()
          .day(6)
          .add(1, "week")
          .format("YYYY-MM-DD"),
      };
    } else if (option.id == "HFN This Week") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        "subscriptionObject.holdDate": {
          $gte: {
            $date: moment()
              .day(0)
              .hour(0)
              .minute(0)
              .seconds(0)
              .millisecond(0)
              .toDate()
              .toISOString(),
          },
          $lte: {
            $date: moment()
              .day(6)
              .hour(23)
              .minute(59)
              .seconds(59)
              .millisecond(9999)
              .toDate()
              .toISOString(),
          },
        },
        "subscriptionObject.status": "HOLD",
      };
    } else if (option.id == "HFN Last Week") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        "subscriptionObject.status": "HOLD",
        "subscriptionObject.holdDate": {
          $gte: {
            $date: moment()
              .day(0)
              .hour(0)
              .minute(0)
              .seconds(0)
              .millisecond(0)
              .subtract(1, "week")
              .toDate()
              .toISOString(),
          },
          $lte: {
            $date: moment()
              .day(6)
              .hour(23)
              .minute(59)
              .seconds(59)
              .millisecond(999)
              .subtract(1, "week")
              .toDate()
              .toISOString(),
          },
        },
      };
    } else if (option.id == "HFN") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        "subscriptionObject.status": "HOLD",
      };
    }

    return filters;
  }

  async loadTabCounts(force = false) {
    if (this.state.tabsLoading) {
      return;
    }

    this.setState(
      {
        tabsLoading: true,
      },
      () => {
        let out = this.state.tabs?.map((tab) => {
          if (force || tab?.countLoading === undefined) {
            tab.count = 0;
            tab.countLoading = true;
          }

          return _.clone(tab);
        });

        this.setState({ tabs: out }, async () => {
          let tabs = this.state.tabs?.map((t) => {
            const filters = this.getFilters(t);

            return {
              id: t.id,
              collection: "Customers",
              query: filters,
            };
          });

          try {
            let data = await APIV2.getTabCounts(tabs);

            let out = this.state.tabs?.map((tab) => {
              const res = _.findWhere(data.data?.tabs, { id: tab.id });

              tab.count = res?.count ? res.count : 0;
              tab.countLoading = false;

              return tab;
            });

            this.setState({
              tabs: out,
            });
          } catch (e) {
            console.error(e);
          }

          this.setState({
            tabsLoading: false,
          });
        });
      }
    );
  }

  row(c, i) {
    return (
      <tr
        className={`px-3 py-2 bg-superlight--hover cursor-pointer`}
        key={i}
        onClick={() => {
          this.props.history.push(
            "/storefront/" +
              this.props?.match?.params?.storeID +
              "/members/details/" +
              c._id +
              "/summary"
          );
        }}
      >
        <td>
          <div
            className={`${
              c?.memberHealthScore <= 50 ? "bg-danger text-white" : ""
            } ${
              c?.memberHealthScore > 50 && c?.memberHealthScore <= 70
                ? "bg-yellow text-dark"
                : ""
            } ${
              c?.memberHealthScore > 70 ? "bg-success text-white" : ""
            } rounded d-flex align-items-center  justify-content-center`}
            style={{ height: 40, width: 40 }}
          >
            <div className="text-center">
              <div
                className="font-weight-bold"
                style={{ fontSize: 16, lineHeight: 1.1 }}
              >
                {c?.memberHealthScore}
              </div>
              <div
                className="font-weight-bold"
                style={{ fontSize: 9, paddingTop: 2, lineHeight: 1.1 }}
              >
                SCORE
              </div>
            </div>
          </div>
        </td>
        <td>
          <Row className="align-items-center">
            <Col xs="" className="pl-3">
              <h4 className="mb-0">{c.name}</h4>
              <p className="mb-0" style={{ fontSize: "13px" }}>
                {c.phone
                  ? StringUtils.formatPhoneNumber(c.phone.replace("+1", ""))
                  : "--"}
              </p>
              {c?.subscriptionObject?.pauseCounter &&
              c?.subscriptionObject?.pauseCounter >= 3 ? (
                <>
                  <div
                    style={{ fontSize: 12 }}
                    className="badge badge-warning p-1 border border-warning text-capitalize mt-1"
                  >
                    <i className="mdi mdi-alert-box mr-1"></i>HFN Risk :{" "}
                    {c?.subscriptionObject?.pauseCounter} orders skipped
                  </div>
                </>
              ) : null}
              {c?.subscriptionObject?.status == "ACTIVE" &&
              !c?.paymentMethod?.paymentProfileID &&
              !c?.paymentMethod?.stripePaymentMethod ? (
                <>
                  <div
                    style={{ fontSize: 12 }}
                    className="badge badge-warning p-1 border border-warning text-capitalize mt-1"
                  >
                    <i className="mdi mdi-alert-box mr-1"></i>No Payment Method
                  </div>
                </>
              ) : null}
              {c?.subscriptionObject?.status == "ACTIVE" &&
              c?.subscriptionObject?.billingError &&
              (c?.paymentMethod?.paymentProfileID ||
                c?.paymentMethod?.stripePaymentMethod) ? (
                <>
                  <div
                    style={{ fontSize: 12 }}
                    className="badge badge-warning p-1 border border-warning text-capitalize mt-1"
                  >
                    <i className="mdi mdi-alert-box mr-1"></i>Update Payment
                    Method
                  </div>
                </>
              ) : null}
            </Col>
          </Row>
        </td>
        <td>
          <p style={{ fontSize: 15 }} className="text-dark m-0">
            {c?.subscription?.plan?.name}
          </p>
          <p className="m-0" style={{ lineHeight: 1.2, fontSize: 11 }}>
            {c?.subscription?.plan?.code}
          </p>
        </td>
        <td style={{ fontSize: 18 }}>
          {c.accountStatus == "ACTIVE" ? (
            <>
              <Badge color="success">Active</Badge>
            </>
          ) : null}
          {c.accountStatus == "HOLD" ? (
            <>
              <Badge color="medium">Hold</Badge>
            </>
          ) : null}
        </td>
        <td>
          <div style={{ fontSize: "15px" }} className="text-dark">
            {c.accountStatus == "ACTIVE" && c.subscription ? (
              <>{moment(c.subscription.nextBillDate).format("MM/DD/YYYY")}</>
            ) : (
              <>--</>
            )}
          </div>
        </td>
        <td>
          <div
            style={{ fontSize: "15px" }}
            className={`${
              c?.subscription?.lastBillDate &&
              c?.subscription?.status == "ACTIVE" &&
              moment(c.subscription.lastBillDate).toDate() <
                moment().startOf("day").subtract(30, "days").toDate() &&
              (!c?.subscription?.reactivateDate ||
                moment(c.subscription.reactivateDate).toDate() <
                  moment().startOf("day").subtract(30, "days").toDate())
                ? "text-danger"
                : "text-dark"
            }`}
          >
            {c.subscription?.lastBillDate ? (
              <>{moment(c.subscription.lastBillDate).format("MM/DD/YYYY")}</>
            ) : (
              <>--</>
            )}
          </div>
        </td>
        <td>
          <div style={{ fontSize: "15px" }} className={`text-dark`}>
            {c?.subscription?.status != "ACTIVE" ? (
              "--"
            ) : (
              <>
                {c.subscriptionWeeks ? (
                  <>Week&nbsp;{c.subscriptionWeeks}</>
                ) : (
                  <>Pre-First Fulfillment</>
                )}
              </>
            )}
          </div>
        </td>
      </tr>
    );
  }

  handleTabChange(option, force = false) {
    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    let filters = this.getFilters(option);

    this.setState({
      filters,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadCustomers(1, this.state.count, filters, this.state.orderBy);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Members"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
            tabs={
              <CardSelectorHeader
                compact={true}
                showCount={true}
                value={this.state.active}
                options={this.state.tabs}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
            }
          />
          <Card
            className="border-0"
            style={{ minHeight: "500px", marginLeft: -15, marginRight: -15 }}
          >
            <CardHeader className="px-3 py-0">
              <Row className="align-items-center">
                <Col xs="12" sm="">
                  <Input
                    type="text"
                    placeholder="Search members..."
                    size="sm"
                    name="search"
                    value={this.state.search}
                    onChange={this.handleSearchChange.bind(this)}
                  ></Input>
                </Col>
              </Row>
              <Row className="align-items-center mt-3 mb-3">
                {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                <Col xs="" sm="" className="">
                  {this.state.searchResults ? (
                    <>
                      Found{" "}
                      {this.state.searchResultCustomers?.length
                        ? StringUtils.numberFormat(
                            this.state.searchResultCustomers?.length
                          )
                        : "0"}{" "}
                      member
                      {this.state.searchResultCustomers?.length == 1
                        ? ""
                        : "s"}{" "}
                      matching your search.
                    </>
                  ) : (
                    <div className="text-dark">
                      {this.state.result?.start && this.state.result?.end ? (
                        <>
                          Showing{" "}
                          {StringUtils.numberFormat(this.state.result?.start)}-
                          {StringUtils.numberFormat(this.state.result?.end)}
                          {this.state.result?.total ? (
                            <>
                              &nbsp;of{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.total
                              )}{" "}
                              members
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>---</>
                      )}
                    </div>
                  )}
                </Col>
                <Col xs="auto" sm="auto" className="pl-2 pl-sm-3 text-right">
                  <Button
                    style={{ height: "28px" }}
                    size="sm"
                    color={
                      this.state.page == 1 ||
                      !this.state.pagination?.hasPrevious ||
                      this.state.searchResults ||
                      this.state.loadingCustomers
                        ? "light"
                        : "dark"
                    }
                    className="btn-icon-only"
                    outline
                    disabled={
                      this.state.page == 1 ||
                      !this.state.pagination?.hasPrevious ||
                      this.state.searchResults ||
                      this.state.loadingCustomers
                    }
                    onClick={() => {
                      this.incrementPage(-1);
                    }}
                  >
                    <i
                      className="mdi mdi-chevron-left"
                      style={{ fontSize: "18px" }}
                    ></i>
                  </Button>
                  <Button
                    style={{ height: "28px" }}
                    size="sm"
                    color={
                      !this.state.pagination?.hasNext ||
                      this.state.searchResults ||
                      this.state.loadingCustomers
                        ? "light"
                        : "dark"
                    }
                    disabled={
                      !this.state.pagination?.hasNext ||
                      this.state.searchResults ||
                      this.state.loadingCustomers
                    }
                    className="btn-icon-only"
                    outline
                    onClick={() => {
                      this.incrementPage(1);
                    }}
                  >
                    <i
                      className="mdi mdi-chevron-right"
                      style={{ fontSize: "18px" }}
                    ></i>
                  </Button>
                  <Button
                    style={{ height: "28px" }}
                    size="sm"
                    color={
                      this.state.loadingCustomers || this.state.searchLoading
                        ? "light"
                        : "dark"
                    }
                    disabled={
                      this.state.loadingCustomers || this.state.searchLoading
                    }
                    className="btn-icon-only"
                    outline
                    onClick={() => {
                      this.loadCustomers(
                        this.state.page,
                        this.state.count,
                        this.state.filters,
                        this.state.orderBy
                      );
                    }}
                  >
                    {this.state.loadingCustomers || this.state.searchLoading ? (
                      <Spinner size="sm"></Spinner>
                    ) : (
                      <i className="mdi mdi-refresh"></i>
                    )}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Table
              className="align-items-center table-flush table-hover"
              responsive
            >
              <thead className="thead-light">
                <tr>
                  <th
                    className="text-dark py-2 cursor-pointer"
                    scope="col"
                    onClick={() => {
                      let dir = "ascending";

                      if (this.state.sortByOption == "memberHealthScore") {
                        if (this.state.sortByDirection == "descending") {
                          return this.setState(
                            {
                              sortByOption: "",
                              sortByDirection: "",
                              orderBy: null,
                            },
                            () => {
                              this.loadCustomers(
                                1,
                                this.state.count,
                                this.state.filters,
                                this.state.orderBy
                              );
                            }
                          );
                        } else if (this.state.sortByDirection == "ascending") {
                          dir = "descending";
                        }
                      }

                      this.setState(
                        {
                          sortByOption: "memberHealthScore",
                          sortByDirection: dir,
                          orderBy: {
                            memberHealthScore: dir == "descending" ? -1 : 1,
                          },
                        },
                        () => {
                          this.loadCustomers(
                            1,
                            this.state.count,
                            this.state.filters,
                            this.state.orderBy
                          );
                        }
                      );
                    }}
                  >
                    <h4 className="m-0 text-capitalize">
                      Score
                      {this.state.sortByOption == "memberHealthScore" ? (
                        <>
                          <i
                            className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                          ></i>
                        </>
                      ) : (
                        <>
                          <i className="mdi mdi-sort pl-1"></i>
                        </>
                      )}
                    </h4>
                  </th>
                  <th
                    className="text-dark py-2 cursor-pointer"
                    scope="col"
                    style={{ minWidth: "220px" }}
                    onClick={() => {
                      let dir = "ascending";

                      if (this.state.sortByOption == "name") {
                        if (this.state.sortByDirection == "descending") {
                          return this.setState(
                            {
                              sortByOption: "",
                              sortByDirection: "",
                              orderBy: null,
                            },
                            () => {
                              this.loadCustomers(
                                1,
                                this.state.count,
                                this.state.filters,
                                this.state.orderBy
                              );
                            }
                          );
                        } else if (this.state.sortByDirection == "ascending") {
                          dir = "descending";
                        }
                      }

                      this.setState(
                        {
                          sortByOption: "name",
                          sortByDirection: dir,
                          orderBy: {
                            name: dir == "descending" ? -1 : 1,
                          },
                        },
                        () => {
                          this.loadCustomers(
                            1,
                            this.state.count,
                            this.state.filters,
                            this.state.orderBy
                          );
                        }
                      );
                    }}
                  >
                    <h4 className="m-0 text-capitalize">
                      Name
                      {this.state.sortByOption == "name" ? (
                        <>
                          <i
                            className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                          ></i>
                        </>
                      ) : (
                        <>
                          <i className="mdi mdi-sort pl-1"></i>
                        </>
                      )}
                    </h4>
                  </th>
                  <th className="text-dark py-2" scope="col">
                    <h4 className="m-0 text-capitalize">Plan</h4>
                  </th>
                  <th
                    className="text-dark py-2 cursor-pointer"
                    scope="col"
                    onClick={() => {
                      let dir = "ascending";

                      if (this.state.sortByOption == "status") {
                        if (this.state.sortByDirection == "descending") {
                          return this.setState(
                            {
                              sortByOption: "",
                              sortByDirection: "",
                              orderBy: null,
                            },
                            () => {
                              this.loadCustomers(
                                1,
                                this.state.count,
                                this.state.filters,
                                this.state.orderBy
                              );
                            }
                          );
                        } else if (this.state.sortByDirection == "ascending") {
                          dir = "descending";
                        }
                      }

                      this.setState(
                        {
                          sortByOption: "status",
                          sortByDirection: dir,
                          orderBy: {
                            "subscriptionObject.status":
                              dir == "descending" ? -1 : 1,
                          },
                        },
                        () => {
                          this.loadCustomers(
                            1,
                            this.state.count,
                            this.state.filters,
                            this.state.orderBy
                          );
                        }
                      );
                    }}
                  >
                    <h4 className="m-0 text-capitalize">
                      Status
                      {this.state.sortByOption == "status" ? (
                        <>
                          <i
                            className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                          ></i>
                        </>
                      ) : (
                        <>
                          <i className="mdi mdi-sort pl-1"></i>
                        </>
                      )}
                    </h4>
                  </th>

                  <th
                    className="text-dark py-2 cursor-pointer"
                    scope="col"
                    onClick={() => {
                      let dir = "ascending";

                      if (this.state.sortByOption == "nextBillDate") {
                        if (this.state.sortByDirection == "descending") {
                          return this.setState(
                            {
                              sortByOption: "",
                              sortByDirection: "",
                              orderBy: null,
                            },
                            () => {
                              this.loadCustomers(
                                1,
                                this.state.count,
                                this.state.filters,
                                this.state.orderBy
                              );
                            }
                          );
                        } else if (this.state.sortByDirection == "ascending") {
                          dir = "descending";
                        }
                      }

                      this.setState(
                        {
                          sortByOption: "nextBillDate",
                          sortByDirection: dir,
                          orderBy: {
                            "subscriptionObject.nextBillDate":
                              dir == "descending" ? -1 : 1,
                          },
                        },
                        () => {
                          this.loadCustomers(
                            1,
                            this.state.count,
                            this.state.filters,
                            this.state.orderBy
                          );
                        }
                      );
                    }}
                  >
                    <h4 className="m-0 text-capitalize">
                      Next Bill Date
                      {this.state.sortByOption == "nextBillDate" ? (
                        <>
                          <i
                            className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                          ></i>
                        </>
                      ) : (
                        <>
                          <i className="mdi mdi-sort pl-1"></i>
                        </>
                      )}
                    </h4>
                  </th>
                  <th
                    className="text-dark py-2 cursor-pointer"
                    scope="col"
                    onClick={() => {
                      let dir = "ascending";

                      if (this.state.sortByOption == "lastBillDate") {
                        if (this.state.sortByDirection == "descending") {
                          return this.setState(
                            {
                              sortByOption: "",
                              sortByDirection: "",
                              orderBy: null,
                            },
                            () => {
                              this.loadCustomers(
                                1,
                                this.state.count,
                                this.state.filters,
                                this.state.orderBy
                              );
                            }
                          );
                        } else if (this.state.sortByDirection == "ascending") {
                          dir = "descending";
                        }
                      }

                      this.setState(
                        {
                          sortByOption: "lastBillDate",
                          sortByDirection: dir,
                          orderBy: {
                            "subscriptionObject.lastBillDate":
                              dir == "descending" ? -1 : 1,
                          },
                        },
                        () => {
                          this.loadCustomers(
                            1,
                            this.state.count,
                            this.state.filters,
                            this.state.orderBy
                          );
                        }
                      );
                    }}
                  >
                    <h4 className="m-0 text-capitalize">
                      Last Bill Date{" "}
                      {this.state.sortByOption == "lastBillDate" ? (
                        <>
                          <i
                            className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                          ></i>
                        </>
                      ) : (
                        <>
                          <i className="mdi mdi-sort pl-1"></i>
                        </>
                      )}
                    </h4>
                  </th>
                  <th
                    className="text-dark py-2 cursor-pointer"
                    scope="col"
                    onClick={() => {
                      let dir = "ascending";

                      if (this.state.sortByOption == "week") {
                        if (this.state.sortByDirection == "descending") {
                          return this.setState(
                            {
                              sortByOption: "",
                              sortByDirection: "",
                              orderBy: null,
                            },
                            () => {
                              this.loadCustomers(
                                1,
                                this.state.count,
                                this.state.filters,
                                this.state.orderBy
                              );
                            }
                          );
                        } else if (this.state.sortByDirection == "ascending") {
                          dir = "descending";
                        }
                      }

                      this.setState(
                        {
                          sortByOption: "week",
                          sortByDirection: dir,
                          orderBy: {
                            subscriptionWeeks: dir == "descending" ? -1 : 1,
                          },
                        },
                        () => {
                          this.loadCustomers(
                            1,
                            this.state.count,
                            this.state.filters,
                            this.state.orderBy
                          );
                        }
                      );
                    }}
                  >
                    <h4 className="m-0 text-capitalize">
                      Subscription Week{" "}
                      {this.state.sortByOption == "week" ? (
                        <>
                          <i
                            className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                          ></i>
                        </>
                      ) : (
                        <>
                          <i className="mdi mdi-sort pl-1"></i>
                        </>
                      )}
                    </h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.searchLoading || this.state.loadingCustomers ? (
                  <>
                    <tr>
                      <td colspan={7}>
                        <div
                          className="skeleton rounded"
                          style={{ width: "100%", height: `calc(25px + 13px)` }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan={7}>
                        <div
                          className="skeleton rounded"
                          style={{ width: "100%", height: `calc(25px + 13px)` }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan={7}>
                        <div
                          className="skeleton rounded"
                          style={{ width: "100%", height: `calc(25px + 13px)` }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan={7}>
                        <div
                          className="skeleton rounded"
                          style={{ width: "100%", height: `calc(25px + 13px)` }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan={7}>
                        <div
                          className="skeleton rounded"
                          style={{ width: "100%", height: `calc(25px + 13px)` }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan={7}>
                        <div
                          className="skeleton rounded"
                          style={{ width: "100%", height: `calc(25px + 13px)` }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan={7}>
                        <div
                          className="skeleton rounded"
                          style={{ width: "100%", height: `calc(25px + 13px)` }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan={7}>
                        <div
                          className="skeleton rounded"
                          style={{ width: "100%", height: `calc(25px + 13px)` }}
                        ></div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    {this.state.searchResults ? (
                      <>
                        {!this.state.searchResultCustomers?.length &&
                        !this.state.searchLoading ? (
                          <tr>
                            <td colSpan={5}>
                              No members found matching your search "
                              {this.state.search}"
                            </td>
                          </tr>
                        ) : null}

                        {this.state.searchResultCustomers?.map((c, i) =>
                          this.row(c, i)
                        )}
                      </>
                    ) : (
                      <>
                        {!this.state.customers?.length &&
                        !this.state.loadingCustomers ? (
                          <tr colSpan={5} className="px-3 py-2">
                            <td>Get started by adding your first member.</td>
                          </tr>
                        ) : null}

                        {this.state.customers?.map((c, i) => this.row(c, i))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </Card>
        </Container>
        <CustomerGlobalFilterDrawer
          openEvent={Event.FILTERS.CUSTOMERS_OPEN}
        ></CustomerGlobalFilterDrawer>
        <CustomerCreateDrawer
          store={this.state.store}
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          onCreate={(c) => {
            PubSub.publish(Event.CUSTOMER.CREATED, c);
          }}
        ></CustomerCreateDrawer>
      </>
    );
  }
}

export default StorefrontCustomerListView;
