import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Collapse,
  Badge,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";
import ModifyStoreBillingAccountModal from "./ModifyStoreBillingAccountModal";

import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import APIV2 from "lib/APIV2";
import StringUtils from "lib/StringUtils";
import NumberFormat from "react-number-format";
import Spinner from "reactstrap/lib/Spinner";
import { withRouter } from "react-router";
import StoreNotesWidget from "./Widgets/StoreNotesWidget";
import moment from "moment";
import { ProgressBar } from "@tremor/react";
import _ from "underscore";
import StoreNSOWidget from "./Widgets/StoreNSOWidget";

class StoreViewReConTab extends React.Component {
  state = {
    store: null,
    expanded: [],
  };

  loadStore() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStoreByID(params.storeID)
      .then(
        (data) => {
          if (data && data.data && data.data.store) {
            this.setState({
              store: data.data.store,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.loadStore();

    PubSub.subscribe(Event.STORE.MODIFIED, (s) => {
      this.setState({
        store: s,
      });
    });
  }

  render() {
    return (
      <>
        <StoreNSOWidget
          filter={(key) => {
            return key?.includes("recon");
          }}
          title="Real Estate & Construction Progress"
          globalMode={true}
          store={this.state.store}
          loading={this.state.loading}
          onUpdate={(nso) => {
            let store = this.state.store;

            if (store) {
              store.nso = nso;
            }

            this.setState({
              store: store,
            });
          }}
        ></StoreNSOWidget>
      </>
    );
  }
}

export default withRouter(StoreViewReConTab);
