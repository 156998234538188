import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
  InputNumber,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import LearningCourseTopicSelectorDrawer from "./LearningCourseTopicSelectorDrawer";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";
import Editor from "components/NovelEditor/ui/editor";
import LinkUtils from "lib/LinkUtils";
import FileUploadDrawer from "components/FileUploadDrawer";

class NSOTemplateDrawer extends React.Component {
  state = {
    message: "",
    name: "",
    description: {},
    assignedTo: "",
    timeToFFD: 0,
    visibleTo: "All Parties",
    files: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.action != prevProps?.action && this.props.action) {
      this.setState({
        name: this.props.action?.name,
        description: this.props.action?.description,
        assignedTo: this.props.action?.assignedTo,
        timeToFFD: this.props.action?.timeToFFD,
        visibleTo: this.props.action?.visibleTo,
        actionLoad: true,
        files: this.props?.action?.files?.length
          ? this.props?.action?.files
          : [],
        topic: this.props.action?.topic ? this.props.action?.topic : null,
      });

      if (this.props.action?.description && this.props.open) {
        setTimeout(() => {
          PubSub.publish("editor:contentUpdated", {
            event: "contentUpdated",
            data: this.props.action?.description,
            id: "nsoActionTemplateEditor",
            forceHydrate: true,
          });
        }, 0);
      }
    }
  }

  componentDidMount() {
    if (this.props.action) {
      this.setState({
        name: this.props.action?.name,
        description: this.props.action?.description,
        assignedTo: this.props.action?.assignedTo,
        timeToFFD: this.props.action?.timeToFFD,
        visibleTo: this.props.action?.visibleTo,
        actionLoad: true,
        files: this.props?.action?.files?.length
          ? this.props?.action?.files
          : [],
      });
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      description: {},
      assignedTo: "",
      timeToFFD: 0,
      visibleTo: "All Parties",
      files: [],
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  submit() {
    if (this.props.action && !this.props.duplicate) {
      this.modify();
    } else {
      this.create();
    }
  }

  create() {
    this.setState({ submitting: true });

    let timeToFFD = this.state.timeToFFD ? parseInt(this.state.timeToFFD) : 0;

    APIV2.createNSOAction(
      this.props.section?.key,
      this.state.name,
      this.state.assignedTo,
      timeToFFD,
      this.state.visibleTo,
      this.state.topic ? this.state.topic?._id : null,
      this.state.description,
      this.state.files
    )
      .then(
        (data) => {
          const template = data.data.template;

          this.toggleModal();

          PubSub.publish(Event.NSO_TEMPLATE.MODIFIED, template);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create action - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  removeFile(attachment) {
    swal({
      title: "Remove File",
      text: `Are you sure you want to remove the "${attachment?.fileName}" file?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      let files = this.state?.files?.length ? this.state?.files : [];

      files = _.filter(files, (at) => {
        return (
          at?.fileName != attachment?.fileName && at?.url != attachment?.url
        );
      });

      this.setState({
        files,
      });
    });
  }

  async openFile(attachment) {
    LinkUtils.openPrivateURL(attachment?.url);

    return;
  }

  setFiles(files) {
    let f = this.state?.files?.length ? this.state.files : [];

    f = f.concat(files);

    this.setState({
      files: f,
    });

    return f;
  }

  modify() {
    this.setState({ submitting: true });

    let timeToFFD = this.state.timeToFFD ? parseInt(this.state.timeToFFD) : 0;

    APIV2.modifyNSOAction(
      this.props.section?.key,
      this.props.action?._id,
      this.state.name,
      this.state.assignedTo,
      timeToFFD,
      this.state.visibleTo,
      this.state.topic ? this.state.topic?._id : null,
      this.state.description,
      this.state.files
    )
      .then(
        (data) => {
          const template = data.data.template;

          this.toggleModal();

          PubSub.publish(Event.NSO_TEMPLATE.MODIFIED, template);
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify action - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="md"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.action && !this.props.duplicate
                ? `Modify ${this.props.section?.name} Action`
                : null}

              {!this.props.action
                ? `New ${this.props.section?.name} Action`
                : null}
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  !this.state.name ||
                  !this.state.assignedTo ||
                  isNaN(parseInt(this.state.timeToFFD)) ||
                  this.state.timeToFFD === "" ||
                  this.state.timeToFFD === null ||
                  !this.state.visibleTo
                }
                color="primary"
                onClick={this.submit.bind(this)}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>{this.props.action ? "Save" : "Add"}</>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}

            <FormGroup>
              <h5>
                Action{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <Input
                bsSize="sm"
                type="text"
                name="name"
                placeholder="Action"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Assigned To{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                block
                placement="auto"
                preventOverflow={true}
                cleanable
                searchable={false}
                data={[
                  {
                    label: "Fran-Dev",
                    value: "Fran-Dev",
                  },
                  {
                    label: "Franchise Partner",
                    value: "Franchise Partner",
                  },
                  {
                    label: "Unit Operations",
                    value: "Unit Operations",
                  },
                  {
                    label: "Fran-Finance",
                    value: "Fran-Finance",
                  },
                  {
                    label: "Fran-Support",
                    value: "Fran-Support",
                  },
                  {
                    label: "Production",
                    value: "Production",
                  },
                  {
                    label: "Growth Technology",
                    value: "Growth Technology",
                  },
                  {
                    label: "Marketing",
                    value: "Marketing",
                  },
                  {
                    label: "Real Estate & Construction",
                    value: "Real Estate & Construction",
                  },
                ]}
                value={this.state.assignedTo}
                onChange={(v) => {
                  this.setState({
                    assignedTo: v,
                  });
                }}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>
                Due Days From{" "}
                {this.props.targetDateString
                  ? this.props.targetDateString
                  : "First Fulfillment"}{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <p className="small text-muted" style={{ lineHeight: 1.2 }}>
                The number of days from the{" "}
                {this.props.targetDateString
                  ? this.props.targetDateString
                  : "First Fulfillment"}{" "}
                date that this action should be completed by. Negative is days
                before first fulfillment date, positive is days after. Zero is
                on the first fulfillment date.
              </p>
              <InputNumber
                postfix={`${this.state.timeToFFD == 0 ? "on date" : ""}${
                  this.state.timeToFFD > 0 ? "days after" : ""
                }${this.state.timeToFFD < 0 ? "days before" : ""}`}
                value={this.state.timeToFFD}
                onChange={(v) => {
                  console.log(v);

                  this.setState({
                    timeToFFD: v,
                  });
                }}
              ></InputNumber>
            </FormGroup>
            <FormGroup>
              <h5>
                Visible To{" "}
                <i
                  className="mdi mdi-octagram text-danger pl-1"
                  style={{ fontSize: "80%", position: "relative", top: -1 }}
                ></i>
              </h5>
              <SelectPicker
                block
                placement="auto"
                preventOverflow={true}
                searchable={false}
                data={[
                  {
                    label: "Fran-Dev Team Only",
                    value: "Fran-Dev Team Only",
                  },
                  {
                    label: "All Parties",
                    value: "All Parties",
                  },
                ]}
                value={this.state.visibleTo}
                onChange={(v) => {
                  this.setState({
                    visibleTo: v,
                  });
                }}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <Row className="align-items-center mb-2">
                <Col xs="">
                  <h5 className="m-0">Files:</h5>
                </Col>
                <Col xs="auto">
                  <Button
                    onClick={() => {
                      this.setState({
                        showFile: true,
                      });
                    }}
                    size="sm"
                    outline
                    color="primary"
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
              <div className="p-2 border rounded">
                {this.state?.files?.length > 0 ? (
                  <>
                    {this.state.files?.map((at, i) => (
                      <div className={`mx-2 mb-2`} key={i}>
                        <Row className="align-items-center">
                          <Col xs="12" sm="">
                            <p className="small m-0 text-dark text-truncate">
                              {at?.fileName}
                            </p>
                          </Col>
                          <Col
                            xs="12"
                            sm="auto"
                            className="mt-3 mt-sm-0 text-right"
                          >
                            <Button
                              className="btn-icon-only"
                              size="sm"
                              outline
                              color="secondary"
                              onClick={() => {
                                this.openFile(at);
                              }}
                            >
                              <i className="mdi mdi-eye"></i>
                            </Button>
                            <Button
                              className="btn-icon-only"
                              size="sm"
                              outline
                              color="danger"
                              onClick={() => {
                                this.removeFile(at);
                              }}
                            >
                              <i className="mdi mdi-close"></i>
                            </Button>
                          </Col>
                        </Row>
                        {i != this.state.files.length - 1 && (
                          <hr className="my-2" />
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <p
                    className="small m-0"
                    style={{ lineHeight: 1.2, fontSize: 15 }}
                  >
                    No files added
                  </p>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Row className="align-items-center mb-2">
                <Col xs="">
                  <h5 className="m-0">Resource Center Topic</h5>
                </Col>
                <Col xs="auto">
                  {this.state.topic ? (
                    <Button
                      size="sm"
                      color="danger"
                      outline
                      onClick={() => {
                        this.setState({
                          topic: null,
                        });
                      }}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      color="primary"
                      outline
                      onClick={() => {
                        this.setState({
                          openTopic: true,
                        });
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Col>
              </Row>
              <div className="rounded p-2 border border-midlighter">
                {this.state.topic ? (
                  <>
                    <p className="m-0 text-dark">{this.state.topic?.name}</p>
                    <p className="m-0 text-dark small">
                      {this.state.topic?.description}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="m-0 text-dark">No topic selected</p>
                  </>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <h5>Description</h5>
              <div className="rounded border p-3">
                <Editor
                  editable={true}
                  allowForceHydration={
                    this.props.action && this.state.actionLoad
                  }
                  id={"nsoActionTemplateEditor"}
                  padding="p-0"
                  autoSave={false}
                  debounceRate={750}
                  value={this.state?.description}
                  onChange={(value) => {
                    if (value?.clear) {
                      this.setState({
                        actionLoad: false,
                      });

                      return;
                    }

                    this.setState({
                      description: value,
                    });
                  }}
                  allowTaskLists={false}
                ></Editor>
              </div>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
        <FileUploadDrawer
          compressUploads={true}
          size="sm"
          onComplete={(files) => {
            console.log(files);

            this.setState({
              showFile: false,
            });

            this.setFiles(files);
          }}
          open={this.state.showFile}
          onClose={() => {
            this.setState({
              showFile: false,
            });
          }}
        ></FileUploadDrawer>
        <LearningCourseTopicSelectorDrawer
          open={this.state.openTopic}
          maxSelected={1}
          selectedTopics={this.state.topic ? [this.state.topic] : []}
          onSelect={(topic) => {
            this.setState({
              topic: topic,
            });

            this.setState({
              openTopic: false,
            });
          }}
          onClose={() => {
            this.setState({ openTopic: false });
          }}
        ></LearningCourseTopicSelectorDrawer>
      </>
    );
  }
}

export default withRouter(NSOTemplateDrawer);
