import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import _, { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { Dropdown, Popover, SelectPicker, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import LibraryCategoryEditDrawer from "components/Drawers/LibraryCategoryEditDrawer";
import LibraryModuleEditDrawer from "components/Drawers/LibraryModuleEditDrawer";
import LibraryAllModulesDrawer from "components/Drawers/LibraryAllModulesDrawer";
import { convertTypeAcquisitionFromJson } from "typescript";
import LibraryAllTopicsDrawer from "components/Drawers/LibraryAllTopicsDrawer";
import { Capacitor } from "@capacitor/core";

class LearningCenterCourseLibrary extends React.Component {
  state = {
    active: 0,
    loadingCategories: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    openCategory: false,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  /**
   * Returns whether a track's prereqs have been met
   *
   * @param {*} track
   * @returns
   */
  prerequsitesMet(track) {
    if (!track?.preRequisiteTrackIDs?.length) {
      return true;
    }

    if (!this.state.assignments) {
      return false;
    }

    for (let preReqID of track?.preRequisiteTrackIDs) {
      let item = _.findWhere(this.state.assignments, { trackID: preReqID });

      if (!item) {
        return false;
      }

      if (item?.status != "Certified") {
        return false;
      }
    }

    return true;
  }

  getPrerequisiteTracks(track) {
    if (!track?.preRequisiteTrackIDs?.length) {
      return [];
    }

    let out = _.filter(this.state.tracks, (t) => {
      return track?.preRequisiteTrackIDs?.includes(t._id);
    });

    return out;
  }

  loadStaffRoles() {
    return new Promise((resolve) => {
      APIV2.getStaffRoles(1, 100, {}, {}).then(
        (staffData) => {
          this.setState({
            staffRoles: staffData.data?.roles,
          });

          resolve();
        },
        (e) => {
          console.error(e);
          resolve();
        }
      );
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    let filters = {};

    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!this.isGlobal()) {
      let storeIDs = user?.storePermissions?.map((store) => store.storeID);

      filters = {
        _idString: { $in: storeIDs },
      };
    }

    APIV2.getStores(filters, { _id: 1, name: 1 })
      .then(
        (storeData) => {
          this.setState({
            stores: storeData.data?.stores,
            storeOptions: storeData.data?.stores?.map((store) => {
              return {
                label: store.name?.replace("Project LeanNation", "PLN")?.trim(),
                value: store._id,
              };
            }),
            store: storeData.data?.stores?.length
              ? storeData.data?.stores[0]?._id
              : null,
            loadingStores: false,
          });

          if (storeData.data?.stores?.length) {
            this.loadCourses(storeData.data?.stores[0]?._id);
          }
        },
        (e) => {
          console.error(e);

          this.setState({
            loadingStores: false,
          });
        }
      )
      .finally(() => {});
  }

  loadAssignments() {
    APIV2.getLearningTrackAssignments(1, 500, {
      userID: LocalStorage.get(Constant.CACHE.USER)._id,
    }).then(
      (data) => {
        console.log(data?.data?.assignments);

        this.setState({
          assignments: data?.data?.assignments,
          loadingAssignments: false,
        });
      },
      (e) => {
        console.error(e);

        this.setState({
          loadingAssignments: false,
        });
      }
    );
  }

  loadCourses(storeID) {
    this.setState({
      loading: true,
    });

    let user = LocalStorage.get(Constant.CACHE.USER);

    let tracksFilter = {};

    if (!this.isGlobal()) {
      let storePermissions = _.findWhere(user.storePermissions, { storeID });

      if (storePermissions?.staffProfile) {
        let roleOptions = [storePermissions.staffProfile.roleID];

        let role = _.findWhere(this.state.staffRoles, {
          _id: storePermissions.staffProfile.roleID,
        });

        if (role?.nextRoleID) {
          roleOptions.push(role.nextRoleID);
        }

        for (let role of this.state.staffRoles) {
          if (
            roleOptions?.includes(role?.nextRoleID) &&
            !roleOptions?.includes(role?._id)
          ) {
            roleOptions.push(role._id);
          }
        }

        tracksFilter = {
          staffRoleIDs: {
            $in: roleOptions,
          },
          draft: { $ne: true },
          $or: [
            {
              storeIDs: storeID,
            },
            {
              allStores: true,
            },
          ],
        };
      }
    }

    //console.log(tracksFilter);

    APIV2.getLearningTracks(1, 100, tracksFilter, { name: 1 }).then(
      async (data) => {
        console.log(data?.data?.tracks);

        let tracks = data?.data?.tracks;

        for (let track of data?.data?.tracks) {
          for (let course of track?.courses) {
            if (course?.cover?.url) {
              try {
                let res = await APIV2.getPrivateFileURL(course.cover.url);

                course.cover.url = res.data.url;
              } catch (e) {
                course.cover.url = "";
              }
            }
          }
        }

        tracks = _.sortBy(tracks, (track) => track.name);

        this.setState({
          tracks,
          loading: false,
        });
      },
      (e) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  componentDidMount() {
    this.loadStaffRoles().then(() => {
      this.loadStores();
    });
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isProduction() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.productionPermissions?.length ? true : false;
  }

  filterTracks(search, tracks) {
    if (!search || search?.length <= 2) {
      return tracks;
    }

    return tracks.filter((track) => {
      return _.filter(track?.courses, (course) => {
        return (
          course?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
          course?.description?.toLowerCase()?.includes(search.toLowerCase())
        );
      }).length;
    });
  }

  filterCourses(search, courses) {
    if (!search || search?.length <= 2) {
      return courses;
    }

    return courses.filter((course) => {
      return (
        course?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        course?.description?.toLowerCase()?.includes(search.toLowerCase())
      );
    });
  }

  enrolledInTrack(track) {
    if (!this.state.assignments) {
      return false;
    }

    return _.findWhere(this.state.assignments, { trackID: track._id });
  }

  render() {
    return (
      <>
        <div
          className="bg-white border-bottom px-4 py-4 mb-4"
          style={{ marginTop: "5rem" }}
        >
          {/* Page content */}
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="12" sm="">
              <h2 className="m-0 text-dark">Course Library</h2>
            </Col>
            <Col xs="12" sm="auto" className="mt-2 mt-sm-0">
              <SelectPicker
                block={true}
                data={this.state.storeOptions}
                value={this.state.store}
                cleanable={false}
                onChange={(v) => {
                  this.setState({ store: v });

                  this.loadCourses(v);
                }}
                placeholder="Select A Store"
                label="Viewing Courses For"
                loading={this.state.loadingStores}
              />
            </Col>
            <Col xs="12" className="mt-2">
              <Row className="align-items-center">
                <Col xs="" className={`${this.state.search ? "pr-2" : ""}`}>
                  <Input
                    className="form-control"
                    bsSize="sm"
                    value={this.state.search}
                    placeholder="Search Courses"
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                    }}
                  />
                </Col>
                {this.state.search ? (
                  <Col xs="auto" className="pl-0">
                    <Button
                      color="dark"
                      size="sm"
                      className="btn-icon-only m-0"
                      style={{ height: 31 }}
                      outline
                      onClick={() => {
                        this.setState({ search: "" });
                      }}
                    >
                      <i className="mdi mdi-close"></i>
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </div>

        <Container fluid>
          {/* Table */}
          {!this.state.store && !this.state.loadingStores ? (
            <>
              <div className="rounded p-4 border">
                <h3 className="text-center m-0 text-dark">
                  Select a store to view the course library
                </h3>
              </div>
            </>
          ) : (
            <>
              {this.state.loading ? (
                <>
                  <Row className="align-items-top">
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  {this.state.tracks?.length ? (
                    <>
                      {this.filterTracks(this.state.search, this.state.tracks)
                        ?.length ? (
                        <>
                          {this.filterTracks(
                            this.state.search,
                            this.state.tracks
                          )?.map((track, i) => (
                            <div key={track?._id} className="mb-4">
                              <div
                                style={{
                                  position: "sticky",
                                  top: 80,
                                  zIndex: 1,
                                }}
                                className="px-1 py-3 border-bottom bg-white mb-3"
                              >
                                <Row className="align-items-center mb-1">
                                  <Col xs="">
                                    <p className="small text-dark m-0">
                                      {track?.required
                                        ? "Required"
                                        : "Optional"}
                                      &nbsp;Track:{" "}
                                      {
                                        this.filterCourses(
                                          this.state.search,
                                          track.courses
                                        )?.length
                                      }
                                      &nbsp;Courses
                                    </p>
                                    <h2 className="text-dark m-0">
                                      {track?.name}
                                    </h2>
                                  </Col>
                                  <Col xs="auto">
                                    <h2 className="m-0">
                                      <Badge
                                        className={`border-light border`}
                                        color={
                                          this.enrolledInTrack(track)
                                            ? "success"
                                            : "light"
                                        }
                                      >
                                        {this.enrolledInTrack(track)
                                          ? "Enrolled"
                                          : "Not Enrolled"}
                                      </Badge>
                                    </h2>
                                  </Col>
                                </Row>
                                <p
                                  className="m-0 small"
                                  style={{
                                    lineHeight: 1.3,
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                  }}
                                >
                                  {track?.description}
                                </p>
                              </div>
                              {track?.required == false &&
                              track?.type == "Ad-Hoc Track" &&
                              !this.enrolledInTrack(track) ? (
                                <>
                                  {this.prerequsitesMet(track) ? (
                                    <div className="my-3 rounded border border-lighter p-2">
                                      <Row className="align-items-center">
                                        <Col xs="">
                                          <h3 className="text-dark m-0">
                                            Enroll In Learning Track
                                          </h3>
                                          <p
                                            className="small text-dark m-0"
                                            style={{ lineHeight: 1.3 }}
                                          >
                                            Enroll in this track to gain access
                                            to the courses.
                                          </p>
                                        </Col>
                                        <Col xs="auto" className="pl-2">
                                          <Button
                                            size="sm"
                                            color="primary"
                                            onClick={() => {
                                              this.enrolledInTrack(track);
                                            }}
                                            disabled={
                                              this.state.enrolling == track?._id
                                            }
                                          >
                                            {this.state?.enrolling ==
                                            track?._id ? (
                                              <Spinner size="sm"></Spinner>
                                            ) : (
                                              "Enroll"
                                            )}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : (
                                    <div className="my-3 rounded border bg-ultralight border-lighter p-2">
                                      <Row className="align-items-center">
                                        <Col xs="">
                                          <h3 className="text-dark m-0">
                                            Prerequsites Not Met
                                          </h3>
                                          <p
                                            className="small text-dark m-0"
                                            style={{ lineHeight: 1.3 }}
                                          >
                                            Please complete the following
                                            training tracks prior to enrolling:{" "}
                                            {this.getPrerequisiteTracks(track)
                                              .map((track) => track.name)
                                              .join(", ")}
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </>
                              ) : null}

                              <Row className="align-items-top">
                                {this.filterCourses(
                                  this.state.search,
                                  track.courses
                                )?.map((course, j) => (
                                  <Col
                                    xs="12"
                                    sm="6"
                                    md="4"
                                    key={course?._id}
                                    className="mb-3"
                                  >
                                    <Card
                                      className={`${
                                        this.enrolledInTrack(track)
                                          ? "cursor-pointer shadow-sm-hover"
                                          : ""
                                      } border`}
                                      onClick={() => {
                                        if (!this.enrolledInTrack(track)) {
                                          return;
                                        }

                                        this.props.history.push(
                                          `/university/learning/courses/${course?._id}`
                                        );
                                      }}
                                    >
                                      <CardHeader className="p-0">
                                        {course?.cover?.url ? (
                                          <div
                                            style={{
                                              height: "20vw",
                                              minHeight: 180,
                                              maxHeight: 220,
                                              borderTopLeftRadius: 6,
                                              borderTopRightRadius: 6,
                                              background: `url(${course?.cover?.url}) no-repeat center center`,
                                              backgroundSize: "cover",
                                            }}
                                          ></div>
                                        ) : (
                                          <div
                                            className="d-flex align-items-center justify-content-center"
                                            style={{
                                              height: "20vw",
                                              minHeight: 180,
                                              maxHeight: 220,
                                              width: "100%",
                                            }}
                                          >
                                            <img
                                              src={require("../../assets/img/brand/pln_primary_no_dot.svg")}
                                              style={{
                                                width: "80%",
                                                maxWidth: 250,
                                              }}
                                              className=""
                                              alt="logo"
                                            ></img>
                                          </div>
                                        )}
                                      </CardHeader>
                                      <CardBody className="p-2">
                                        <h3
                                          className="text-dark mb-1"
                                          style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          {course?.name}
                                        </h3>
                                        <p
                                          className="small text-dark m-0"
                                          style={{
                                            lineHeight: 1.3,
                                            lineClamp: 3,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: "vertical",
                                            height: 50,
                                          }}
                                        >
                                          {course?.description}
                                        </p>
                                      </CardBody>
                                      <CardFooter className="p-2 border-top-0">
                                        {/**
                                 * <Row className="align-items-center">
                                  <Col xs="12">
                                    <Row className="align-items-center mb-1">
                                      <Col xs="" className="pr-0">
                                        <p className="small text-dark m-0">
                                          Topics Completed
                                        </p>
                                      </Col>
                                      <Col xs="auto" className="pl-0">
                                        <p className="small text-dark m-0">
                                          3/5
                                        </p>
                                      </Col>
                                    </Row>
                                    <div>
                                      <Progress
                                        value={50}
                                        color="success"
                                        size="sm"
                                      ></Progress>
                                    </div>
                                  </Col>
                                  <Col
                                    xs="12"
                                    md="6"
                                    className="mb-3 mb-md-0 pr-md-0"
                                  >
                                    <h4 className="text-dark m-0 text-center text-md-left">
                                      <i className="mdi mdi-circle-half-full text-success mr-2"></i>
                                      In Progress
                                    </h4>
                                  </Col>
                                  <Col xs="12" md="6">
                                    <Button
                                      color="primary"
                                      outline
                                      block
                                      size="sm"
                                      onClick={() => {
                                        this.props.history.push(
                                          `/global/learning-center/course/${course?._id}`
                                        );
                                      }}
                                    >
                                      View Course
                                    </Button>
                                  </Col>
                                </Row>
                                 */}
                                        <Button
                                          color={
                                            this.enrolledInTrack(track)
                                              ? "primary"
                                              : "medium"
                                          }
                                          outline
                                          block
                                          size="sm"
                                          disabled={
                                            !this.enrolledInTrack(track)
                                          }
                                        >
                                          View Course
                                        </Button>
                                      </CardFooter>
                                    </Card>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="rounded p-4 border">
                            <h3 className="text-center m-0 text-dark">
                              No courses matching your search "
                              {this.state.search}".
                            </h3>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="rounded p-4 border">
                      <h3 className="text-center m-0 text-dark">
                        No courses available at this time, check back later.
                      </h3>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </>
    );
  }
}

export default LearningCenterCourseLibrary;
