import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Input,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import ProductGroupRow from "components/ProductGroupRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import CreateProductGroupModal from "components/CreateProductGroupModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import DataAPI from "lib/DataAPI";
import cubejs from "@cubejs-client/core";
import swal from "sweetalert";
import semver from "semver";

class PlatformStorefrontVersionView extends React.Component {
  state = {
    groups: [],
    loading: true,
  };

  getVersion() {
    APIV2.getStorefrontAppVersion().then((res) => {
      this.setState({ version: res.data?.version });
    });
  }

  setVersion() {
    this.setState({ submitting: true });

    APIV2.setStorefrontAppVersion(this.state.version)
      .then((res) => {
        this.getVersion();

        swal({
          title: "Success",
          text: "Version updated",
          icon: "success",
        });
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    this.getVersion();
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader title="Storefront Version" showBackButton={false} />
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Current Version</h3>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        disabled={
                          !this.state.version ||
                          this.state.submitting ||
                          !semver.valid(this.state.version)
                        }
                        color="primary"
                        onClick={() => {
                          this.setVersion();
                        }}
                      >
                        {this.state.submitting ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-3">
                  <Input
                    value={this.state.version}
                    onChange={(e) => {
                      this.setState({ version: e.target.value });
                    }}
                    placeholder="1.2.3"
                    type="text"
                  ></Input>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <CreateProductGroupModal></CreateProductGroupModal>
      </>
    );
  }
}

export default PlatformStorefrontVersionView;
