import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React, { useEffect, useRef } from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Toggle,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";
import EditorV2 from "components/NovelEditor/ui/editor";
import FileUploadDrawer from "components/FileUploadDrawer";

function Clip({ url, styles }) {
  const videoRef = useRef();

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.load();
    }
  }, [url]);

  return (
    <video ref={videoRef} style={styles} controls>
      <source src={url} />
      Your browser does not support the video tag.
    </video>
  );
}

class LearningCourseDrawer extends React.Component {
  state = {
    name: "",
    description: "",

    overview: {},
    score: null,
    videoURL: "",
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  updateForm() {
    this.setState(
      {
        name: this.props.course?.name ? this.props.course?.name : "",
        description: this.props.course?.description
          ? this.props.course?.description
          : "",
        overview: this.props.course?.overview
          ? this.props.course?.overview
          : {},

        cover: this.props.course?.cover ? this.props.course?.cover : null,
        video: this.props.course?.video ? this.props.course?.video : null,
        certification: this.props.course?.certificationID
          ? this.props.course?.certificationID
          : null,
      },
      () => {
        if (this.props.course?.cover) {
          APIV2.getPrivateFileURL(this.props.course?.cover?.url).then(
            (data) => {
              this.setState({
                coverImageURL: data.data.url,
              });
            },
            (e) => {}
          );
        }

        if (this.props.course?.video) {
          APIV2.getPrivateFileURL(this.props.course?.video?.url).then(
            (data) => {
              this.setState({
                videoURL: data.data.url,
              });
            },
            (e) => {}
          );
        }

        setTimeout(() => {
          //console.log("HYDRATE", message);

          PubSub.publish("editor:contentUpdated", {
            event: "contentUpdated",
            forceHydrate: true,
            id: "learningCourseOverviewEditor",
            data: this.props.course?.overview
              ? this.props.course?.overview
              : {},
          });
        }, 0);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props?.course != prevProps?.course) {
      this.updateForm();
    }
  }

  componentDidMount() {
    if (this.props.course) {
      this.updateForm();
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  toggleModal() {
    this.setState({
      name: "",
      nextRoleID: "",
      overview: {},
      cover: null,
      video: null,
      coverImageURL: null,
      videoURL: null,
    });

    PubSub.publish("editor:contentUpdated", {
      event: "contentUpdated",
      forceHydrate: true,
      id: "learningCourseOverviewEditor",
      data: {},
    });

    this.props.onClose();
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createLearningCourse(
      this.state.name,
      this.state.description,
      this.state.overview,
      this.state.cover,
      this.state.video,
      this.state.certification,
      this.state.draft
    )
      .then(
        (data) => {
          const staff = data.data.course;

          PubSub.publish(Event.COURSE.CREATED, staff);

          this.setState({
            name: "",
            nextRoleID: "",
          });

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create course - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    this.setState({ submitting: true });

    APIV2.modifyLearningCourse(
      this.props?.course?._id,
      this.state.name,
      this.state.description,
      this.state.overview,
      this.state.cover,
      this.state.video,
      this.state.certification,
      this.state.draft
    )
      .then(
        (data) => {
          const staff = data.data.course;

          PubSub.publish(Event.COURSE.MODIFIED, staff);

          this.setState({
            name: "",
            nextRoleID: "",
          });

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify course - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.course ? "Modify" : "Create"}
              &nbsp;Course
            </h3>
            <Drawer.Actions>
              <Button
                onClick={() => {
                  if (this.props.course) {
                    this.modify();
                  } else {
                    this.create();
                  }
                }}
                size="sm"
                color="primary"
                disabled={!this.state.name || this.state.submitting}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : this.props.course ? (
                  "Save"
                ) : (
                  "Create"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <FormGroup>
              <h5>
                Name<span className="text-danger">*</span>
              </h5>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>Description</h5>
              <Input
                type="textarea"
                numRows={3}
                name="description"
                placeholder="Description"
                required
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Row className="mb-2 align-items-center">
                <div className="col">
                  <h5 className="m-0">Cover Image</h5>
                </div>
                <Col xs="auto">
                  {this.state.cover ? (
                    <Button
                      size="sm"
                      color="danger"
                      outline
                      onClick={() => {
                        this.setState({
                          cover: null,
                        });
                      }}
                      className="btn-icon-only"
                    >
                      <i className="mdi mdi-trash-can"></i>
                    </Button>
                  ) : null}
                  <Button
                    size="sm"
                    color="secondary"
                    outline
                    onClick={() => {
                      this.setState({
                        showUpload: true,
                        uploadMode: "cover",
                      });
                    }}
                  >
                    {this.state.cover ? "Change" : "Add"}
                  </Button>
                </Col>
              </Row>
              {this.state.cover ? (
                <div
                  style={{ aspectRatio: "16/9" }}
                  className="d-flex p-2 border rounded justify-content-center"
                >
                  <img
                    src={this.state.coverImageURL}
                    style={{
                      width: "100%",
                      maxWidth: "400px",

                      objectFit: "cover",
                    }}
                    alt="Cover Image"
                  ></img>
                </div>
              ) : (
                <>
                  <div
                    className="text-center p-3 border rounded text-medium d-flex align-items-center justify-content-center"
                    style={{ height: 200, width: "100%" }}
                  >
                    No image added
                  </div>
                </>
              )}
            </FormGroup>
            <FormGroup>
              <Row className="mb-2 align-items-center">
                <div className="col">
                  <h5 className="m-0">Introduction Video</h5>
                </div>
                <Col xs="auto">
                  {this.state.video ? (
                    <Button
                      size="sm"
                      color="danger"
                      outline
                      onClick={() => {
                        this.setState({
                          video: null,
                        });
                      }}
                      className="btn-icon-only"
                    >
                      <i className="mdi mdi-trash-can"></i>
                    </Button>
                  ) : null}
                  <Button
                    size="sm"
                    color="secondary"
                    outline
                    onClick={() => {
                      this.setState({
                        showUpload: true,
                        uploadMode: "video",
                      });
                    }}
                  >
                    {this.state.video ? "Change" : "Add"}
                  </Button>
                </Col>
              </Row>
              {this.state.video ? (
                <div
                  style={{ aspectRatio: "16/9" }}
                  className="d-flex p-2 border rounded justify-content-center"
                >
                  <Clip url={this.state.videoURL} styles={{ width: "100%" }} />
                </div>
              ) : (
                <>
                  <div
                    className="text-center p-3 border rounded text-medium d-flex align-items-center justify-content-center"
                    style={{ height: 200, width: "100%" }}
                  >
                    No video added
                  </div>
                </>
              )}
            </FormGroup>
            <FormGroup>
              <h5 className="mb-0">Course Overview</h5>
              <p className="small text-dark mb-2">
                Provide an overview of the course.
              </p>
              <div
                className="rounded p-3 border mb-3"
                style={{ minHeight: 300 }}
              >
                <EditorV2
                  editable={true}
                  allowForceHydration={
                    this.state.overview && this.state.templateLoad
                  }
                  id={"learningCourseOverviewEditor"}
                  padding="p-0"
                  autoSave={false}
                  debounceRate={750}
                  cdnMode="private"
                  value={this.state?.overview ? this.state?.overview : {}}
                  onChange={(value) => {
                    console.log("CHANGE", value);

                    if (value?.clear) {
                      this.setState({
                        templateLoad: false,
                      });

                      return;
                    }

                    //console.log("CHANGE", value);

                    this.setState({
                      overview: value,
                    });
                  }}
                  allowTaskLists={false}
                ></EditorV2>
              </div>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
        <FileUploadDrawer
          cdn="private"
          compressUploads={true}
          size="sm"
          allowedFileTypes={
            this.state.uploadMode == "cover"
              ? ["image/jpeg", "image/png"]
              : ["video/mp4", "video/mpeg"]
          }
          singleFile={true}
          onComplete={(files) => {
            console.log(files);

            if (this.state.uploadMode == "cover") {
              this.setState(
                {
                  cover: files[0],
                },
                () => {
                  APIV2.getPrivateFileURL(files[0].url).then(
                    (data) => {
                      this.setState({
                        coverImageURL: data.data.url,
                      });
                    },
                    (e) => {}
                  );
                }
              );
            } else if (this.state.uploadMode == "video") {
              this.setState(
                {
                  video: files[0],
                },
                () => {
                  APIV2.getPrivateFileURL(files[0].url).then(
                    (data) => {
                      console.log(data?.data?.url);

                      this.setState({
                        videoURL: data.data.url,
                      });
                    },
                    (e) => {}
                  );
                }
              );
            }

            this.setState({
              showUpload: false,
              uploadMode: null,
            });
          }}
          open={this.state.showUpload}
          onClose={() => {
            this.setState({
              showAttachment: false,
            });
          }}
        ></FileUploadDrawer>
      </>
    );
  }
}

export default withRouter(LearningCourseDrawer);
