import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminFooter from "components/Footers/AdminFooter.js";

import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import User from "lib/Model/User";
import PubSub from "lib/PubSub";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import AdminNavbarV2 from "components/Navbars/AdminNavbarV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import StoreList from "views/examples/StoreList";
import MealList from "views/examples/MealList";
import MenuList from "views/examples/MenuList";
import BillingAccountList from "views/examples/BillingAccountList";
import ProductGroupList from "views/examples/ProductGroupList";
import SignupLinkCreatorView from "views/examples/SignupLinkCreatorView";
import ScannerTestView from "views/examples/ScannerTestView";
import StoreView from "views/examples/StoreView";
import MealView from "views/examples/MealView";
import routesv2 from "routesv2";
import MealCreateView from "views/examples/MealCreateView";
import MealCategoryList from "views/examples/MealCategoryList";
import MealAllergenList from "views/examples/MealAllergenList";
import { Capacitor } from "@capacitor/core";
import CustomerListView from "views/examples/CustomerListView";
import GlobalfrontUserListView from "views/global/GlobalUserListView";
import GlobalUserListView from "views/global/GlobalUserListView";
import GlobalConversationTemplateListView from "../views/global/GobalConversationTemplateListView";
import GlobalNoteTemplateListView from "../views/global/GobalNoteTemplateListView";
import NSOTemplateView from "../views/global/NSOTemplateView";
import ReConTimelineView from "../views/global/ReConTimelineView";
import ClosingTransferTimelineView from "../views/global/ClosingTransferTimelineView";
import NSOPurchaseListView from "../views/global/NSOPurchaseListView";
import NSOOpeningListView from "../views/global/NSOOpeningListView";
import ApprovedRetailListView from "../views/global/ApprovedRetailListView";
import GlobalAppContentView from "../views/global/GlobalAppContentView";
import GlobalCampaignListView from "../views/global/GlobalCampaignListView";
import GlobalCohortsListView from "../views/global/GlobalCohortsListView";
import GlobalMarketingKitsView from "../views/global/GlobalMarketingKitsView";
import MarketingAutomationListView from "../views/global/MarketingAutomationListView";
import MarketingAutomationStepView from "../views/global/MarketingAutomationStepView";
import ContentCalendarView from "../views/marketing/ContentCalendarView";
import PlatformPaymentProcessingView from "../views/examples/PlatformPaymentProcessingView";
import PlatformStorefrontVersionView from "../views/examples/PlatformStorefrontVersionView";

class Admin extends React.Component {
  componentDidUpdate(e) {
    if (e.history?.location?.state?.noScroll) {
      return;
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  componentWillMount() {
    if (Capacitor.getPlatform() == "ios") {
      document.body.className += " ios-padding";
    }

    if (LocalStorage.get(Constant.CACHE.TOKEN)) {
      API.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2._wsConnect();
    }

    if (LocalStorage.get(Constant.CACHE.USER)) {
      window[Constant.CACHE.USER] = User._fromData(
        LocalStorage.get(Constant.CACHE.USER)
      );
      PubSub.publish(Event.USER_LOADED, window[Constant.CACHE.USER]);
    }

    APIV2.getAccount().then(
      (data) => {
        if (data && data.data && data.data.user) {
          data.data.user.id = data.data.user._id;

          let user = data.data.user;

          window[Constant.CACHE.USER] = user;
          LocalStorage.set(Constant.CACHE.USER, user);

          PubSub.publish(Event.USER_LOADED, user);

          if (!this.isGlobal()) {
            window.location.href = "/storefront";
          }
        }
      },
      (error) => {
        console.error(error);

        //window.location.href = "/storefront";
      }
    );
  }

  render() {
    if (!LocalStorage.get(Constant.CACHE.TOKEN)) {
      this.props.history.push("/auth");
    }

    if (LocalStorage.get(Constant.CACHE.USER)) {
      if (!this.isGlobal()) {
        window.location.href = "/storefront";
      }
    }

    return (
      <>
        <SidebarV2
          {...this.props}
          routes={routesv2.globalRoutes}
          basePath={"/global"}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbarV2
            {...this.props}
            searchMode="global"
            searchPlaceholder="Search All Members..."
          />
          <Switch>
            {/*<Route path="/global/stores" component={StoreList} exact />
            <Route
              path="/global/stores/details/:storeID/:activeTab"
              component={StoreView}
    />*/}
            <Route
              path="/global/templates"
              component={GlobalConversationTemplateListView}
              exact
            />
            <Route
              path="/global/templates/documents"
              component={GlobalNoteTemplateListView}
              exact
            />
            <Route
              path="/global/templates/nso"
              component={NSOTemplateView}
              exact
            />
            <Route
              path="/global/templates/recon"
              component={ReConTimelineView}
              exact
            />
            <Route
              path="/global/templates/transfer-closure"
              component={ClosingTransferTimelineView}
              exact
            />
            <Route
              path="/global/templates/nso/purchase-list"
              component={NSOPurchaseListView}
              exact
            />
            <Route
              path="/global/templates/nso/opening"
              component={NSOOpeningListView}
              exact
            />
            <Route
              path="/global/templates/nso/retail-list"
              component={ApprovedRetailListView}
              exact
            />
            <Route
              path="/global/product-categories"
              component={ProductGroupList}
              exact
            />
            <Route path="/global/app" component={GlobalAppContentView} exact />
            <Route
              path="/global/app/campaigns"
              component={GlobalCampaignListView}
              exact
            />
            <Route
              path="/global/app/cohorts"
              component={GlobalCohortsListView}
              exact
            />
            <Route
              path="/global/app/marketing-kits"
              component={GlobalMarketingKitsView}
              exact
            />
            <Route
              path="/global/app/social-calendar"
              component={ContentCalendarView}
              exact
            />
            <Route
              path="/global/app/automations"
              component={MarketingAutomationListView}
              exact
            />
            <Route
              path="/global/platform"
              component={PlatformPaymentProcessingView}
              exact
            />
            <Route
              path="/global/platform/version"
              component={PlatformStorefrontVersionView}
              exact
            />
            <Route
              path="/global/app/automations/:automationID"
              component={MarketingAutomationStepView}
              exact
            />
            <Route
              path="/global/url-creator"
              component={SignupLinkCreatorView}
              exact
            />
            <Route
              path="/global/scanner-test"
              component={ScannerTestView}
              exact
            />
            <Route path="/global/users" component={GlobalUserListView} exact />

            <Redirect from="*" to="/global/templates" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
        {/*<LooseSealScanner></LooseSealScanner>*/}
      </>
    );
  }
}

export default Admin;
