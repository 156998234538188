import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  BarList,
  Card,
  DonutChart,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import {
  Axis,
  Chart,
  Coordinate,
  Interaction,
  Interval,
  Legend,
  Tooltip,
} from "bizcharts";
import ChartMetricHeader from "./ChartMetricHeader";

class MemberLTVBreakdownPieChartCard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    tab: "overall",
  };

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      let metricType = "overall";

      if (this.props.metricType) {
        metricType = this.props.metricType;
      }

      // Load
      cubejsApi
        .load({
          measures: [
            "MetricsLTV.avgMetadataFulfilledorderltv",
            "MetricsLTV.avgMetadataRetailltv",
            "MetricsLTV.avgMetadataTotalcustomers",
          ],
          order: {
            "MetricsLTV.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "MetricsLTV.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          dimensions: ["Stores.name"],
          filters: [
            {
              member: "MetricsLTV.name",
              operator: "contains",
              values: [`ltv_store_${metricType}`],
            },
            {
              member: "MetricsLTV.metadataStoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "MetricsLTV.createdat",
              operator: "beforeDate",
              values: [this.props.dateRange[1].toISOString()],
            },
            {
              member: "MetricsLTV.createdat",
              operator: "beforeDate",
              values: [moment().toISOString()],
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let totalSubscription = 0;
          let totalRetail = 0;

          let out = [];

          let outOverall = [];

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];
            const storeName = item["Stores.name"]
              ?.replace("Project LeanNation", "PLN")
              ?.trim();

            totalSubscription +=
              item["MetricsLTV.avgMetadataFulfilledorderltv"];
            totalRetail += item["MetricsLTV.avgMetadataRetailltv"];

            const idx = _.findIndex(out, { storeName });

            if (idx >= 0) {
              out[idx]["Recurring Revenue"] =
                item["MetricsLTV.avgMetadataFulfilledorderltv"] > 0
                  ? item["MetricsLTV.avgMetadataFulfilledorderltv"]
                  : 0;
              out[idx]["Retail Revenue"] =
                item["MetricsLTV.avgMetadataRetailltv"] > 0
                  ? item["MetricsLTV.avgMetadataRetailltv"]
                  : 0;
              out[idx]["Total Members"] =
                item["MetricsLTV.avgMetadataTotalcustomers"] > 0
                  ? item["MetricsLTV.avgMetadataTotalcustomers"]
                  : 0;
            } else {
              out.push({
                storeName,
                "Recurring Revenue":
                  item["MetricsLTV.avgMetadataFulfilledorderltv"] > 0
                    ? item["MetricsLTV.avgMetadataFulfilledorderltv"]
                    : 0,
                "Retail Revenue":
                  item["MetricsLTV.avgMetadataRetailltv"] > 0
                    ? item["MetricsLTV.avgMetadataRetailltv"]
                    : 0,
                "Total Members":
                  item["MetricsLTV.avgMetadataTotalcustomers"] > 0
                    ? item["MetricsLTV.avgMetadataTotalcustomers"]
                    : 0,
              });
            }

            const idx2 = _.findIndex(outOverall, {
              metricName: "Recurring Revenue",
            });
            const idx3 = _.findIndex(outOverall, {
              metricName: "Retail Revenue",
            });

            if (idx2 >= 0) {
              outOverall[idx2].Revenue +=
                item["MetricsLTV.avgMetadataFulfilledorderltv"] > 0
                  ? item["MetricsLTV.avgMetadataFulfilledorderltv"]
                  : 0;

              outOverall[idx2].Members +=
                item["MetricsLTV.avgMetadataTotalcustomers"] > 0
                  ? item["MetricsLTV.avgMetadataTotalcustomers"]
                  : 0;
            } else {
              outOverall.push({
                Revenue:
                  item["MetricsLTV.avgMetadataFulfilledorderltv"] > 0
                    ? item["MetricsLTV.avgMetadataFulfilledorderltv"]
                    : 0,
                metricName: "Recurring Revenue",
                Members:
                  item["MetricsLTV.avgMetadataTotalcustomers"] > 0
                    ? item["MetricsLTV.avgMetadataTotalcustomers"]
                    : 0,
              });
            }

            if (idx3 >= 0) {
              outOverall[idx3].Revenue +=
                item["MetricsLTV.avgMetadataRetailltv"] > 0
                  ? item["MetricsLTV.avgMetadataRetailltv"]
                  : 0;

              outOverall[idx3].Members +=
                item["MetricsLTV.avgMetadataTotalcustomers"] > 0
                  ? item["MetricsLTV.avgMetadataTotalcustomers"]
                  : 0;
            } else {
              outOverall.push({
                Revenue:
                  item["MetricsLTV.avgMetadataRetailltv"] > 0
                    ? item["MetricsLTV.avgMetadataRetailltv"]
                    : 0,
                metricName: "Retail Revenue",
                Members:
                  item["MetricsLTV.avgMetadataTotalcustomers"] > 0
                    ? item["MetricsLTV.avgMetadataTotalcustomers"]
                    : 0,
              });
            }
          }

          for (let i = 0; i < outOverall.length; i++) {
            outOverall[i].Revenue =
              outOverall[i].Revenue / outOverall[i].Members;
          }

          out = out.map((item) => {
            const total =
              ((item["Recurring Revenue"] > 0 ? item["Recurring Revenue"] : 0) +
                (item["Retail Revenue"] > 0 ? item["Retail Revenue"] : 0)) /
              item["Total Members"];

            return {
              ...item,
              total,
              percent: total
                ? ((item["Recurring Revenue"] ? item["Recurring Revenue"] : 0) /
                    item["Total Members"] /
                    total) *
                  100
                : 0,
            };
          });

          outOverall = _.sortBy(outOverall, "metricName");

          out = _.sortBy(out, "total");

          out.reverse();

          return resolve({
            byStore: out,
            total: (
              (totalSubscription / (totalRetail + totalSubscription)) *
              100
            ).toFixed(1),
            overall: outOverall,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadDetailedReport(stores, dateRange) {
    this.setState({
      loading: true,
      dataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load total new members by store.",
        loading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });

      return;
    }

    let colors = StringUtils.randomChartColorSet(currentCount.byStore?.length);

    console.log(currentCount?.byStore);

    this.setState({
      currentCount: currentCount,
      loading: false,
      byStore: currentCount?.byStore,
      overall: currentCount?.overall,
      byStoreColors: colors,
      total: currentCount?.total,
    });

    if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.stores != prevProps?.stores ||
      this.props.dateRange != prevProps?.dateRange
    ) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload != prevProps.reload && this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.store && this.props.dateRange) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }

    if (this.props.reload) {
      this.loadDetailedReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0">
          <ChartMetricHeader
            title="Lifetime Value Breakdown"
            description="Percentage of lifetime value from recurring & retail revenue."
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading}
                  onClick={() => {
                    this.loadDetailedReport(
                      this.props.stores,
                      this.props.dateRange
                    );
                  }}
                >
                  {this.state.loading ? (
                    <Spinner size="sm"></Spinner>
                  ) : (
                    <i className="mdi mdi-refresh"></i>
                  )}
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`${StringUtils.numberFormat(
              this.state.currentCount?.total
            )}% Recurring Revenue`}
            comparisonMetric={null}
            dateRange={this.props.dateRange}
            deltaType={null}
            percentChange={null}
            showPercentChange={false}
          ></ChartMetricHeader>
          <div className="mt-3 border-bottom"></div>
          {this.state.loading ? (
            <div
              className="skeleton mt-3"
              style={{ height: "calc(327px + 1rem)", width: "100%" }}
            >
              &nbsp;
            </div>
          ) : (
            <>
              {this.state.dataAvailable ? (
                <>
                  <div className="mt-3 mx--4 px-4">
                    <Row className="align-items-center">
                      <Col xs="12" sm="12" md="6">
                        <div className="pt-4">
                          <DonutChart
                            data={this.state.overall}
                            category={"Revenue"}
                            dataKey="metricName"
                            colors={["orange", "emerald"]}
                            valueFormatter={(number) => {
                              return (
                                StringUtils.centsToCurrency(number) + " Revenue"
                              );
                            }}
                            height="h-80"
                          />
                        </div>
                      </Col>
                      <Col md="6" className="mt-3 mt-md-0">
                        <div
                          className="pt-4 mb--4 pb-4"
                          style={{
                            maxHeight: 368,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {this.state.byStore?.map((store, i) => (
                            <div key={i} className={`${i != 0 && "mt-3"}`}>
                              <p
                                className="m-0 text-dark font-weight-500"
                                style={{ fontSize: 16, lineHeight: 1.3 }}
                              >
                                {store?.storeName}
                              </p>
                              <Flex marginTop="mt-0">
                                <Text>
                                  {StringUtils.centsToCurrency(
                                    (store["Recurring Revenue"]
                                      ? store["Recurring Revenue"]
                                      : 0) / store["Total Members"]
                                  )}
                                  &nbsp;Recurring &bull;{" "}
                                  {store.percent.toFixed(1)}%
                                </Text>
                                <Text>
                                  {StringUtils.centsToCurrency(
                                    (store["Retail Revenue"]
                                      ? store["Retail Revenue"]
                                      : 0) / store["Total Members"]
                                  )}
                                  &nbsp;Retail
                                </Text>
                              </Flex>
                              <ProgressBar
                                marginTop="mt-1"
                                percentageValue={store.percent}
                                color={"orange"}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "calc(327px + 1rem)", width: "100%" }}
                >
                  <p className="m-0">
                    No data available.
                    {this.state.error ? ` ${this.state.error}` : null}
                  </p>
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default MemberLTVBreakdownPieChartCard;
