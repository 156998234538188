import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Collapse,
  FormGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import _, { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { Drawer, Dropdown, Popover, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import LibraryModuleEditDrawer from "components/Drawers/LibraryModuleEditDrawer";
import LibraryAllModulesDrawer from "components/Drawers/LibraryAllModulesDrawer";
import { convertTypeAcquisitionFromJson } from "typescript";
import LibrarySectionEditDrawer from "components/Drawers/LibrarySectionEditDrawer";
import LibraryTopicEditDrawer from "components/Drawers/LibraryTopicEditDrawer";
import { Link } from "react-router-dom";

import { Editor } from "react-draft-wysiwyg";
import EditorV2 from "components/NovelEditor/ui/editor";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import ReactPlayer from "react-player";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Capacitor } from "@capacitor/core";
import StringUtils from "lib/StringUtils";
import FileUploadDrawer from "components/FileUploadDrawer";
import LinkUtils from "lib/LinkUtils";

class LibraryTopic extends React.Component {
  state = {
    loadingModule: true,
    contentState: EditorState.createEmpty(),
    loadingTopic: true,
    openSection: false,
    openTopic: false,
    module: null,
    topic: null,
    content: "",
    topicContent: {},
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  onContentChange(editorState) {
    this.setState({
      contentState: editorState,
    });
  }

  async uploadAttachments(files) {
    let attachments = this.state?.attachments?.length
      ? this.state.attachments
      : [];

    attachments = attachments.concat(files);

    this.setState({
      attachments,
    });

    return attachments;
  }

  async openAttachment(attachment) {
    LinkUtils.openPrivateURL(attachment?.url);

    return;
  }

  removeAttachment(attachment) {
    swal({
      title: "Remove Attachment",
      text: `Are you sure you want to remove the "${attachment?.fileName}" attachment?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      let attachments = this.state?.attachments?.length
        ? this.state?.attachments
        : [];

      attachments = _.filter(attachments, (at) => {
        return (
          at?.fileName != attachment?.fileName && at?.url != attachment?.url
        );
      });

      this.setState({
        attachments,
      });
    });
  }

  uploadCallback(file) {
    return new Promise((resolve, reject) => {
      if (file) {
        const data = new FormData();

        data.append("file", file);

        APIV2.uploadCDNImage(data).then(
          (data) => {
            let url = data.data.data.url;

            //console.log(url);

            resolve({ data: { link: url } });
          },
          (e) => {
            reject(e);
          }
        );
      }
    });
  }

  getPreviousTopic() {
    if (!this.state.module || !this.state.topic) {
      return null;
    }

    let module = this.state.module;
    let allTopics = [];

    for (let i = 0; i < module?.sections?.length; i++) {
      const section = module.sections[i];

      for (let j = 0; j < section?.topics?.length; j++) {
        allTopics.push(section.topics[j].topic);
      }
    }

    const idx = _.findIndex(allTopics, { _id: this.state.topic?._id });

    if (idx <= 0 || allTopics?.length == 1) {
      return null;
    }

    return allTopics[idx - 1];
  }

  getNextTopic() {
    if (!this.state.module || !this.state.topic) {
      return null;
    }

    let module = this.state.module;
    let allTopics = [];

    for (let i = 0; i < module?.sections?.length; i++) {
      const section = module.sections[i];

      for (let j = 0; j < section?.topics?.length; j++) {
        allTopics.push(section.topics[j].topic);
      }
    }

    const idx = _.findIndex(allTopics, { _id: this.state.topic?._id });

    //console.log(idx);

    if (idx < 0 || allTopics?.length == 1 || idx == allTopics?.length - 1) {
      return null;
    }

    return allTopics[idx + 1];
  }

  /**
   * Fetches all categories.
   */
  getModule(moduleID) {
    this.setState({
      loadingModule: true,
    });

    APIV2.getLibraryModuleByID(moduleID)
      .then(
        (data) => {
          let mod = data.data.module;

          for (let i = 0; i < mod?.sections?.length; i++) {
            mod.sections[i].open = true;
          }

          this.setState({
            module: mod,
          });
        },
        () => {
          window.alert("Failed to get module. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingModule: false,
        });
      });
  }

  getTopic() {
    this.setState({
      loadingTopic: true,
      templateLoad: true,
    });

    APIV2.getLibraryTopicByID(this.props.match?.params?.topicID)
      .then(
        async (data) => {
          let mod = data.data.topic;

          if (mod?.internal && !this.isGlobal() && !this.isProduction()) {
            this.props.history.push("/university/library");
          }

          if (mod?.audioRecording?.url) {
            APIV2.getPrivateFileURL(mod?.audioRecording?.url).then((data) => {
              let url = data?.data?.url;

              this.setState({
                audioRecordingURL: url,
              });
            });
          }

          if (mod?.content && mod?.legacy) {
            const contentBlock = htmlToDraft(mod?.content);
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
              );
              const contentState2 = EditorState.createWithContent(contentState);

              this.setState({ contentState: contentState2 });
            }
          } else {
            let content = mod?.content ? mod?.content : {};

            console.log(content);

            //console.log(content?.json);

            if (content?.json?.content?.length) {
              for (let i = 0; i < content?.json?.content?.length; i++) {
                let block = content?.json?.content[i];

                //console.log(block);

                if (block?.type == "image") {
                  let url = block?.attrs?.src;

                  let withoutParams = StringUtils.removeURLParameters(url);

                  withoutParams = withoutParams?.split(
                    `s3.us-east-1.amazonaws.com/files.projectleannation.com/`
                  )[1];

                  if (url) {
                    let presignedURL = await APIV2.getPrivateFileURL(
                      withoutParams
                    );

                    if (presignedURL?.data?.url) {
                      block.attrs.src = presignedURL?.data?.url;

                      //console.log(presignedURL?.data?.url);

                      if (content?.html?.length) {
                        //console.log(content?.html);

                        let knownPartOfSrc = withoutParams; // Part of the src you know
                        let newSrc = presignedURL?.data?.url; // New src to replace with

                        // Regex explanation:
                        // <img\s+ : Matches the start of the img tag followed by one or more whitespace characters
                        // src=" : Matches the src attribute opening quote
                        // [^"]* : Matches any character except the closing quote, as many times as possible (greedy)
                        // example.com/images : The known part of the src attribute you're targeting
                        // [^"]* : Again, matches any character except the closing quote, as many times as possible
                        // " : Matches the closing quote of the src attribute
                        let regex = new RegExp(
                          `(src="[^"]*${knownPartOfSrc}[^"]*")`,
                          "g"
                        );

                        // Replace the src attribute in the matched img tag
                        content.html = content.html.replace(
                          regex,
                          `src="${newSrc}"`
                        );
                      }
                    }
                  }

                  content.json.content[i] = block;
                }
              }

              mod.content = content;
            }
          }

          console.log(mod);

          this.setState({
            topic: mod,
            topicContent: mod?.content ? mod?.content : {},
            templateLoad: false,
          });
        },
        () => {
          window.alert("Failed to get topic. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingTopic: false,
        });
      });
  }

  onModifyTopic(topic) {
    this.setState({ topic, openTopic: false });
  }

  saveContent() {
    this.setState({
      submitting: true,
    });

    let content = "";

    //console.log(this.state.topic, this.state.topicContent);

    if (this.state.topic?.legacy) {
      content = draftToHtml(
        convertToRaw(this.state.contentState.getCurrentContent())
      );
    } else {
      content = this.state.topicContent
        ? this.state.topicContent
        : this.state.topic?.content;
    }

    //console.log(content);

    let url = this.state.url ? this.state.url : null;
    let embed = this.state.embed ? this.state.embed : null;

    if (!url && embed) {
      url = embed;
    }

    let attachments = this.state.attachments?.length
      ? this.state.attachments
      : [];

    let questions = this.state.questions?.length ? this.state.questions : [];

    // Filter questions for the correct format of the following:
    /*{
      question: "",
      answers: [
        {
          option: "",
          correct: true,
        },
        {
          option: "",
          correct: false,
        },
        {
          option: "",
          correct: false,
        },
        {
          option: "",
          correct: false,
        },
      ],
    }*/

    questions = _.filter(questions, (q) => {
      return (
        q?.question &&
        typeof q?.question == "string" &&
        q?.answers?.length == 4 &&
        q?.answers[0]?.option &&
        q?.answers[1]?.option &&
        q?.answers[2]?.option &&
        q?.answers[3]?.option &&
        _.findWhere(q?.answers, { correct: true })
      );
    });

    //console.log(url);

    APIV2.modifyLibraryTopicContent(
      this.state.topic?._id,
      content,
      url,
      attachments,
      questions
    )
      .then(
        (data) => {
          this.setState({
            topic: data.data.topic,
            editMode: false,
            topicContent: data.data.topic?.content,
            attachments: data?.data?.topic?.attachments,
            questions: data?.data?.topic?.questions,
          });
        },
        (e) => {
          alert("Unable to save topic. Please try again.");
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  componentDidMount() {
    this.getTopic();
    this.loadTemplates();

    const urlParams = new URLSearchParams(window.location.search);
    const moduleID = urlParams.get("moduleID");
    const sectionID = urlParams.get("sectionID");

    if (moduleID) {
      this.getModule(moduleID);

      this.setState({ sectionID, moduleID });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.topicID != prevProps?.match?.params?.topicID
    ) {
      this.getTopic();

      this.setState({
        editMode: false,
        contentState: EditorState.createEmpty(),
      });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const moduleID = urlParams.get("moduleID");
    const sectionID = urlParams.get("sectionID");

    if (moduleID != this?.state?.moduleID) {
      this.getModule(moduleID);

      this.setState({ moduleID });
    }

    if (sectionID != this.state?.sectionID) {
      this.setState({ sectionID });
    }
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isProduction() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.productionPermissions?.length ? true : false;
  }

  loadTemplates() {
    this.setState({
      loadingTemplates: true,
    });

    let filters = {
      $or: [
        {
          type: "Resource Center",
        },
      ],
    };

    APIV2.getGlobalNoteTemplates(1, 500, filters, { name: -1 })
      .then(
        (data) => {
          if (data && data.data) {
            let templates = [];

            if (data.data.templates.length) {
              templates = data.data.templates;
            }

            this.setState({
              templates,
            });
          }
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          loadingTemplates: false,
        });
      });
  }

  generateQuestions(number = 1) {
    this.setState({
      submittingQuestion: true,
    });

    let systemPrompt = `You are an expert in learning management content creation and testing for employee training. Using the content below, create ${number} multiple choice questions that truthfully and accurately test for understanding of the information in the content. Each question should include a question sentence with proper grammar and four possible responses. One response should be the truth and three should be wrong answers, but should not be immediately obvious. The objective is to test the reader's understanding, so they should have to read the content to be able to answer each question accurately. Make the questions relevant to the content and not cursory in nature, ensuring that the test is worth the participant's time. Do not test for topic overviews or tables of contents, but instead the content they speak about in the topic. Don't provide repeat questions. The questions should be returned in the following JSON format: {"questions": [{"question":"","answers":[{"option":"","correct":true},{"option":"","correct":false}...]}]}`;

    let content = "";

    if (this.state.topic?.legacy) {
      content = draftToHtml(
        convertToRaw(this.state.contentState.getCurrentContent())
      );
    } else {
      content = this.state.topicContent
        ? this.state.topicContent.html
        : this.state.topic?.content?.html;
    }

    let model = "gpt-4o";

    let prompt = `Content (In HTML Format):${content}
\n\n${
      this.state.questions
        ? `Existing Questions:${JSON.stringify(this.state.questions)}\n\n`
        : ""
    } Output:\n`;

    APIV2.llmPrompt(model, systemPrompt?.toString(), prompt, 1024, {
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      response_format: {
        type: "json_object",
      },
    })
      .then(
        (data) => {
          if (data?.data?.content?.questions?.length) {
            let existing = this.state.questions?.length
              ? this.state.questions
              : [];

            let newQuestions = data?.data?.content?.questions;

            console.log(newQuestions);

            newQuestions = _.filter(newQuestions, (q) => {
              return (
                q?.question &&
                typeof q?.question == "string" &&
                q?.answers?.length == 4 &&
                q?.answers[0]?.option &&
                q?.answers[1]?.option &&
                q?.answers[2]?.option &&
                q?.answers[3]?.option
              );
            });

            newQuestions = newQuestions.map((q) => {
              q.aiGenerated = true;
              q._id = StringUtils.uuid();

              for (let i = 0; i < q.answers.length; i++) {
                q.answers[i]._id = StringUtils.uuid();
              }

              return q;
            });

            let questions = existing.concat(newQuestions);

            this.setState({
              questions,
            });
          }
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          submittingQuestion: false,
        });
      });
  }

  generateAnswers(question, number = 4) {
    this.setState({
      submittingAnswer: question?._id,
    });

    let systemPrompt = `You are an expert in learning management content creation and testing for employee training. Using the content below, create ${number} answers to the following multiple choice question that truthfully and accurately test for understanding of the information in the content. Each answer should include proper grammar and should not repeat amongst eachother. One answer should be the truth and the others should be wrong answers, but should not be immediately obvious. The objective is to test the reader's understanding, so they should have to read the content to be able to answer each question accurately. Make the answers relevant to the question and content and not cursory in nature, ensuring that the test is worth the participant's time.  Don't provide repeat answers. The answers should be returned in the following JSON format: {"answers":[{"option":"","correct":true},{"option":"","correct":false}...]}`;

    let content = "";

    if (this.state.topic?.legacy) {
      content = draftToHtml(
        convertToRaw(this.state.contentState.getCurrentContent())
      );
    } else {
      content = this.state.topicContent
        ? this.state.topicContent?.html
        : this.state.topic?.content?.html;
    }

    let model = "gpt-4o-mini";

    let prompt = `Topic Content (In HTML Format):${content}
\n\nQuestion:${question?.question}
\n\n${
      this.state.questions
        ? `All Existing Questions:${JSON.stringify(this.state.questions)}\n\n`
        : ""
    } Output:\n`;

    APIV2.llmPrompt(model, systemPrompt?.toString(), prompt, 1024, {
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      response_format: {
        type: "json_object",
      },
    })
      .then(
        (data) => {
          if (data?.data?.content?.answers?.length) {
            let idx = _.findIndex(this.state.questions, { _id: question._id });

            let newAnswers = data?.data?.content?.answers;

            console.log(newAnswers);

            for (let i = 0; i < newAnswers.length; i++) {
              newAnswers[i]._id = StringUtils.uuid();
            }

            let questions = this.state.questions;

            questions[idx].answers = newAnswers;

            this.setState({
              questions,
            });
          }
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          submittingAnswer: false,
        });
      });
  }

  generateAudio() {
    this.setState({
      submittingAudio: true,
    });

    let content = "";

    if (this.state.topic?.legacy) {
      content = draftToHtml(
        convertToRaw(this.state.contentState.getCurrentContent())
      );
    } else {
      content = this.state.topicContent
        ? this.state.topicContent?.html
        : this.state.topic?.content?.html;
    }

    APIV2.generateLibraryTopicAudio(this.state?.topic?._id, content)
      .then(
        (data) => {
          console.log(data?.data);

          if (data?.data?.url) {
            let topic = this.state.topic;

            topic.audioRecording = data?.data;

            APIV2.getPrivateFileURL(data?.data?.url).then((data) => {
              let url = data?.data?.url;

              this.setState({
                audioRecordingURL: url,
              });
            });

            this.setState({
              topic,
            });
          }
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          submittingAudio: false,
        });
      });
  }

  deleteAudio() {
    this.setState({
      deletingAudio: true,
    });

    APIV2.deleteLibraryTopicAudio(this.state?.topic?._id)
      .then(
        (data) => {
          let topic = this.state.topic;

          topic.audioRecording = null;

          this.setState({
            topic,
            audioRecordingURL: null,
          });
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          deletingAudio: false,
        });
      });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <div
          className="bg-white border-bottom px-4 py-4 py-md-6"
          style={{ marginTop: "5rem" }}
        >
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="">
              {this.state.loadingTopic ? (
                <>
                  <div
                    style={{
                      height: 20,
                      width: "100%",
                      maxWidth: 300,
                      marginBottom: "1.5rem",
                      borderRadius: 6,
                    }}
                    className="skeleton"
                  ></div>
                  <div
                    style={{
                      height: 35,
                      width: "100%",
                      maxWidth: 500,
                      borderRadius: 6,
                      marginBottom: "1rem",
                    }}
                    className="skeleton"
                  ></div>
                  <div
                    style={{
                      height: 20,
                      width: "100%",
                      marginBottom: "0.25rem",
                      borderRadius: 6,
                    }}
                    className="skeleton"
                  ></div>
                </>
              ) : (
                <>
                  <p className="text-muted mb-2">
                    <div className="d-inline-block pr-2">
                      <Link to="/university/library">Library</Link>
                    </div>
                    {this.state.module && (
                      <>
                        <span>-</span>
                        <div className="d-inline-block pl-2 pr-2">
                          <Link
                            to={`/university/library/modules/${this.state.module?._id}`}
                          >
                            {this.state.module?.name}
                          </Link>
                        </div>
                      </>
                    )}
                    <span>-</span>

                    <div className="d-inline-block pl-2">
                      {this.state.topic?.name}
                    </div>
                  </p>
                  {this.state.topic?.internal ? (
                    <h2 className="mb-0">
                      <Badge color="light" className="border">
                        INTERNAL
                      </Badge>
                    </h2>
                  ) : null}
                  <h1 className="m-0 display-3 text-dark">
                    {this.state.topic?.name}
                  </h1>
                  {this.state.topic?.description && (
                    <p className="m-0 text-dark font-weight-500">
                      {this.state.topic.description}
                    </p>
                  )}
                </>
              )}
            </Col>
            {this.isGlobal() && this.state.editMode && (
              <Col xs="auto">
                <Button
                  color="secondary"
                  outline
                  className="btn-icon-only"
                  onClick={() => {
                    this.setState({ openTopic: true });
                  }}
                >
                  <i className="mdi mdi-pencil"></i>
                </Button>
              </Col>
            )}
          </Row>
        </div>
        <div className="bg-white pt-4 pb-6 border-bottom">
          <Container fluid>
            {/* Table */}
            {this.isGlobal() && (
              <>
                <div className="text-right mb-4">
                  {!this.state.editMode ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            editMode: !this.state.editMode,
                            expanded: this.state.topic?.videoURL,
                            url:
                              this.state.topic?.videoURL &&
                              !this.state.topic?.videoURL?.includes("<iframe")
                                ? this.state.topic.videoURL
                                : null,
                            embed:
                              this.state.topic?.videoURL &&
                              this.state.topic?.videoURL?.includes("<iframe")
                                ? this.state.topic.videoURL
                                : null,
                            topicContent: this.state.topic?.content
                              ? this.state.topic?.content
                              : {
                                  json: {},
                                  html: "",
                                  text: "",
                                },
                            attachments: this.state.topic?.attachments?.length
                              ? this.state.topic?.attachments
                              : [],
                            questions: this.state.topic?.questions?.length
                              ? this.state.topic?.questions
                              : [],
                          });

                          //console.log("EDIT", this.state.topic?.content);

                          setTimeout(() => {
                            PubSub.publish("editor:contentUpdated", {
                              event: "contentUpdated",
                              forceHydrate: true,
                              id: "topicTemplateEditor",
                              data: this.state.topic?.content
                                ? this.state.topic?.content
                                : {
                                    json: {},
                                    html: "",
                                    text: "",
                                  },
                            });
                          }, 0);
                        }}
                        color="dark"
                        outline
                        className=""
                      >
                        {this.state.editMode ? "Done Editing" : "Edit"}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            editMode: !this.state.editMode,
                            topicContent: this.state.topic?.content
                              ? this.state.topic?.content
                              : {
                                  json: {},
                                  html: "",
                                  text: "",
                                },
                          });

                          localStorage.setItem(
                            "note-content-topicTemplateEditor",
                            "{}"
                          );
                        }}
                        color="dark"
                        outline
                        className=""
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.saveContent();
                        }}
                        color="primary"
                        disabled={this.state.submitting}
                        className=""
                      >
                        {this.state.submitting ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}
            {this.isGlobal() && this.state.editMode ? (
              <>
                <Card
                  className={`mb-4 border ${
                    this.state.expanded ? "shadow" : ""
                  }`}
                >
                  <CardHeader
                    className={`cursor-pointer `}
                    onClick={() => {
                      this.setState({ expanded: !this.state.expanded });
                    }}
                  >
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="m-0 text-dark">Add Video / Media</h3>
                      </Col>
                      <Col xs="auto">
                        <h3 className="text-dark m-0">
                          <i
                            style={{ fontSize: 22 }}
                            className={`mdi mdi-${
                              this.state.expanded
                                ? "chevron-down"
                                : "chevron-right"
                            }`}
                          ></i>
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.expanded}>
                    <CardBody>
                      <FormGroup>
                        <h4 className="text-dark mb-0">Youtube Video URL</h4>
                        <p className="small mt-0 mb-2">
                          Paste a youtube video URL here to embed it at the
                          beginning of the topic.
                        </p>
                        <Input
                          type="text"
                          placeholder="https://youtube.com"
                          className="form-control form-control-sm"
                          name="url"
                          value={this.state.url}
                          onChange={(e) => {
                            const { name, value } = e.target;

                            this.setState({
                              [name]: value,
                            });
                          }}
                        ></Input>
                      </FormGroup>
                      <hr></hr>
                      <FormGroup>
                        <h4 className="text-dark mb-0">Embedded Video Code</h4>
                        <p className="small mt-0 mb-2">
                          Paste embedded code from any tool such as Loom, etc
                          here to publish at the top of the page.
                        </p>
                        <Input
                          type="textarea"
                          placeholder="<iframe>..."
                          className="form-control form-control-sm"
                          name="embed"
                          value={this.state.embed}
                          onChange={(e) => {
                            const { name, value } = e.target;

                            this.setState({
                              [name]: value,
                            });
                          }}
                        ></Input>
                      </FormGroup>
                      <hr></hr>
                      <FormGroup>
                        <Row>
                          <Col xs="">
                            <h4 className="text-dark mb-0">Audio Narration</h4>
                            <p className="small m-0">
                              Create an AI-generated audio narration for this
                              topic.
                            </p>
                          </Col>
                          <Col xs="auto">
                            {this.state.topic?.audioRecording?.url ? (
                              <Button
                                className="btn-icon-only"
                                size="sm"
                                outline
                                color="danger"
                                disabled={
                                  this.state.deletingAudio ||
                                  this.state.submittingAudio
                                }
                                onClick={() => {
                                  swal({
                                    title: "Remove Audio",
                                    text: `Are you sure you want to remove the audio recording for this topic?`,
                                    icon: "warning",
                                    buttons: ["Nevermind", "Remove"],
                                    dangerMode: true,
                                  }).then((conf) => {
                                    if (!conf) {
                                      return;
                                    }

                                    this.deleteAudio();
                                  });
                                }}
                              >
                                {this.state.deletingAudio ? (
                                  <Spinner size="sm"></Spinner>
                                ) : (
                                  <i className="mdi mdi-trash-can"></i>
                                )}
                              </Button>
                            ) : null}
                            <Button
                              onClick={() => {
                                this.generateAudio();
                              }}
                              size="sm"
                              color="primary"
                              disabled={this.state.submittingAudio}
                            >
                              {this.state.submittingAudio ? (
                                <Spinner size="sm"></Spinner>
                              ) : this.state?.topic?.audioRecording ? (
                                "Regenerate"
                              ) : (
                                "Generate"
                              )}
                            </Button>
                          </Col>
                        </Row>
                        {this.state.topic?.audioRecording?.url ? (
                          <div className="mt-3">
                            <audio
                              controls
                              src={
                                this.state.audioRecordingURL
                                  ? this.state.audioRecordingURL
                                  : null
                              }
                              style={{ width: "100%" }}
                            ></audio>
                          </div>
                        ) : null}
                      </FormGroup>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className={`mb-4 border`}>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="m-0 text-dark">Add Attachments</h3>
                      </Col>
                      <Col xs="auto">
                        <Button
                          onClick={() => {
                            this.setState({
                              openAttachments: true,
                            });
                          }}
                          size="sm"
                          outline
                          color="primary"
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  {this.state.attachments?.length ? (
                    <CardBody>
                      {this.state.attachments?.map((at, i) => (
                        <div className={`p-2 border rounded mb-2`} key={i}>
                          <Row className="align-items-center">
                            <Col xs="12" sm="">
                              <p className="small m-0 text-dark text-truncate">
                                {at?.fileName}
                              </p>
                            </Col>
                            <Col
                              xs="12"
                              sm="auto"
                              className="mt-3 mt-sm-0 text-right"
                            >
                              <Button
                                className="btn-icon-only"
                                size="sm"
                                outline
                                color="secondary"
                                onClick={() => {
                                  this.openAttachment(at);
                                }}
                              >
                                <i className="mdi mdi-eye"></i>
                              </Button>
                              <Button
                                className="btn-icon-only"
                                size="sm"
                                outline
                                color="danger"
                                onClick={() => {
                                  this.removeAttachment(at);
                                }}
                              >
                                <i className="mdi mdi-close"></i>
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </CardBody>
                  ) : null}
                </Card>

                {this.state.topic?.legacy ? (
                  <>
                    <h3 className="text-dark mb-2">Topic Editor</h3>
                    <Editor
                      placeholder="Add content here..."
                      toolbarClassName="pln-richtext-toolbar"
                      editorClassName="pln-richtext-editor"
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "link",
                          "embedded",
                          "emoji",
                          "image",
                          "remove",
                          "history",
                        ],
                        image: {
                          uploadEnabled: true,
                          uploadCallback: this.uploadCallback.bind(this),
                          alignmentEnabled: false,
                          previewImage: true,
                          inputAccept:
                            "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                          alt: { present: false, mandatory: false },
                          defaultSize: {
                            height: "auto",
                            width: "60%",
                          },
                        },
                        embedded: {
                          className: undefined,
                          component: undefined,
                          popupClassName: undefined,
                          embedCallback: undefined,
                          defaultSize: {
                            height: "auto",
                            width: "60%",
                          },
                        },
                      }}
                      editorState={this.state.contentState}
                      onEditorStateChange={this.onContentChange.bind(this)}
                    ></Editor>
                  </>
                ) : (
                  <>
                    <Row className="mb-3 align-items-center">
                      <Col xs="">
                        <h2 className="text-dark m-0">Topic Editor</h2>
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="secondary"
                          outline
                          onClick={() => {
                            this.setState({
                              openTemplates: true,
                            });
                          }}
                        >
                          Use Template
                        </Button>
                      </Col>
                    </Row>
                    <div
                      className="rounded p-3 border"
                      style={{ minHeight: 300 }}
                    >
                      <EditorV2
                        editable={true}
                        allowForceHydration={
                          this.state.topic && this.state.templateLoad
                        }
                        id={"topicTemplateEditor"}
                        padding="p-0"
                        autoSave={false}
                        debounceRate={750}
                        value={this.state?.topicContent}
                        onChange={(value) => {
                          if (value?.clear) {
                            this.setState({
                              templateLoad: false,
                            });

                            return;
                          }

                          //console.log("CHANGE", value);

                          this.setState({
                            topicContent: value,
                          });
                        }}
                        allowTaskLists={false}
                      ></EditorV2>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {this.state.loadingTopic ? (
                  <>
                    <div style={{ minHeight: 400 }}>
                      <Card className="border">
                        <CardBody>
                          <Row className="align-items-center">
                            <Col xs="auto">
                              <Spinner color="dark"></Spinner>
                            </Col>
                            <Col xs="">
                              <h2 className="m-0">Hang Tight...</h2>
                              <p className="m-0">Fetching the topic now.</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.topic?.videoURL ? (
                      <>
                        {this.state.topic?.videoURL?.includes("<iframe") ? (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.topic?.videoURL,
                              }}
                              className="mb-4 border border-midlighter"
                              style={{ width: "100%" }}
                            ></div>
                          </>
                        ) : (
                          <>
                            <ReactPlayer
                              className="mb-4 border border-midlighter"
                              width={"100%"}
                              url={this.state.topic?.videoURL}
                            />
                          </>
                        )}
                      </>
                    ) : null}
                    {this.state.topic?.audioRecording?.url ? (
                      <>
                        <Card className="mb-4 shadow-sm">
                          <CardHeader className="p-3">
                            <h3 className="m-0 text-dark font-weight-bold">
                              Listen To The Topic
                            </h3>
                          </CardHeader>
                          <CardBody className="p-3">
                            <audio
                              controls
                              src={
                                this.state.audioRecordingURL
                                  ? this.state.audioRecordingURL
                                  : null
                              }
                              style={{ width: "100%" }}
                            ></audio>
                          </CardBody>
                        </Card>
                      </>
                    ) : null}
                    {this.state.topic?.legacy ? (
                      <div
                        style={{ minHeight: 400 }}
                        className="plnu-topic"
                        dangerouslySetInnerHTML={{
                          __html: this.state.topic?.content
                            ? this.state.topic?.content
                            : "(empty)",
                        }}
                      ></div>
                    ) : (
                      <div
                        style={{ minHeight: 400 }}
                        className="plnu-topic"
                        dangerouslySetInnerHTML={{
                          __html: this.state.topic?.content?.html
                            ? this.state.topic?.content?.html
                            : "(empty)",
                        }}
                      ></div>
                    )}
                  </>
                )}
              </>
            )}
            {this.state.topic?.attachments?.length && !this.state.editMode ? (
              <Card className="border">
                <CardHeader className="p-3">
                  <h3 className="m-0 text-dark" style={{ lineHeight: 1 }}>
                    Attachments
                  </h3>
                </CardHeader>
                <CardBody className="p-3">
                  {this.state.topic?.attachments?.map((at, i) => (
                    <div
                      className={`p-2 border rounded ${
                        i != this.state.topic?.attachments?.length - 1 && "mb-2"
                      }`}
                      key={i}
                    >
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <p className="small m-0 text-dark text-truncate">
                            {at?.fileName}
                          </p>
                        </Col>
                        <Col
                          xs="12"
                          sm="auto"
                          className="mt-3 mt-sm-0 text-right"
                        >
                          <Button
                            className="btn-icon-only"
                            size="sm"
                            outline
                            color="secondary"
                            onClick={() => {
                              this.openAttachment(at);
                            }}
                          >
                            <i className="mdi mdi-eye"></i>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </CardBody>
              </Card>
            ) : null}
            {this.isGlobal() && this.state.editMode ? (
              <>
                <Card
                  className={`mt-4 border ${
                    this.state.questionsExpanded ? "shadow" : ""
                  }`}
                >
                  <CardHeader
                    className={`cursor-pointer `}
                    onClick={() => {
                      this.setState({
                        questionsExpanded: !this.state.questionsExpanded,
                      });
                    }}
                  >
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="m-0 text-dark">Add Testing Questions</h3>
                      </Col>
                      <Col xs="auto">
                        <h3 className="text-dark m-0">
                          <i
                            style={{ fontSize: 22 }}
                            className={`mdi mdi-${
                              this.state.questionsExpanded
                                ? "chevron-down"
                                : "chevron-right"
                            }`}
                          ></i>
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={this.state.questionsExpanded}>
                    <CardBody>
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <Row className="align-items-center">
                            <Col xs="auto" className="pr-0">
                              <i
                                style={{ fontSize: 22, lineHeight: 1 }}
                                className="mdi mdi-creation text-dark"
                              ></i>
                            </Col>
                            <Col xs="">
                              <h4 className="text-dark mb-0">
                                Generate Questions With AI
                              </h4>
                              <p className="small m-0">
                                Use AI to generate 4 questions with answers
                                automatically based on the content.
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="12" sm="auto" className="mt-3 mt-sm-0">
                          <Button
                            color="primary"
                            onClick={() => {
                              this.generateQuestions(1);
                            }}
                            size="sm"
                            disabled={this.state.submittingQuestion}
                          >
                            {this.state.submittingQuestion ? (
                              <Spinner size="sm"></Spinner>
                            ) : (
                              "Generate"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                    {this.state?.questions?.length ? (
                      <>
                        {this.state.questions?.map((q, i) => (
                          <CardBody key={q?._id} className="border-top">
                            <Row className="align-items-center">
                              <Col xs="12" className="mb-3">
                                <Row className="align-items-center">
                                  <Col className="pr-0" xs="">
                                    <div
                                      style={{ fontSize: 20 }}
                                      className="font-weight-bold text-dark"
                                    >
                                      {q?.aiGenerated ? (
                                        <i
                                          style={{ lineHeight: 1 }}
                                          className="mdi mdi-creation text-dark pr-2"
                                        ></i>
                                      ) : null}
                                      Question #{i + 1}
                                    </div>
                                  </Col>
                                  <Col className="pl-0" xs="auto">
                                    {this.state.questions?.length > 1 ? (
                                      <>
                                        <Button
                                          className="btn-icon-only"
                                          outline
                                          disabled={i == 0}
                                          color="dark"
                                          size="sm"
                                          onClick={() => {
                                            let questions =
                                              this.state.questions;

                                            // Move up
                                            if (i > 0) {
                                              let temp = questions[i - 1];
                                              questions[i - 1] = questions[i];
                                              questions[i] = temp;
                                            }

                                            this.setState({
                                              questions,
                                            });
                                          }}
                                        >
                                          <i className="mdi mdi-chevron-up"></i>
                                        </Button>
                                        <Button
                                          className="btn-icon-only"
                                          disabled={
                                            i == this.state.questions.length - 1
                                          }
                                          outline
                                          color="dark"
                                          size="sm"
                                          onClick={() => {
                                            let questions =
                                              this.state.questions;

                                            // Move down
                                            if (i < questions.length - 1) {
                                              let temp = questions[i + 1];
                                              questions[i + 1] = questions[i];
                                              questions[i] = temp;
                                            }

                                            this.setState({
                                              questions,
                                            });
                                          }}
                                        >
                                          <i className="mdi mdi-chevron-down"></i>
                                        </Button>
                                      </>
                                    ) : null}

                                    <Button
                                      className="btn-icon-only"
                                      outline
                                      color="danger"
                                      size="sm"
                                      onClick={() => {
                                        let questions = this.state.questions;

                                        questions = _.filter(
                                          questions,
                                          (q2) => {
                                            return q2._id != q._id;
                                          }
                                        );

                                        this.setState({
                                          questions,
                                        });
                                      }}
                                    >
                                      <i className="mdi mdi-trash-can"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="12" className="mb-3">
                                <Row className="align-items-center">
                                  <Col xs="">
                                    <Input
                                      type="textarea"
                                      numRows={2}
                                      placeholder="Question"
                                      className="form-control form-control-sm"
                                      name="question"
                                      value={q.question}
                                      onChange={(e) => {
                                        const { name, value } = e.target;

                                        let questions = this.state.questions;

                                        questions[i].question = value;

                                        this.setState({
                                          questions,
                                        });
                                      }}
                                    ></Input>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="12" className="mb-3">
                                <Row className="align-items-center">
                                  <Col xs="">
                                    <h3 className="text-dark m-0">Options:</h3>
                                  </Col>
                                  <Col xs="auto">
                                    <Button
                                      size="sm"
                                      disabled={this.state.submittingAnswer}
                                      outline
                                      color="dark"
                                      onClick={() => {
                                        this.generateAnswers(q);
                                      }}
                                    >
                                      {this.state.submittingAnswer == q?._id ? (
                                        <Spinner size="sm"></Spinner>
                                      ) : (
                                        "Generate"
                                      )}
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="12">
                                <Row className="align-items-top">
                                  {q.answers?.map((a, j) => (
                                    <>
                                      <Col
                                        xs="12"
                                        sm="12"
                                        md="6"
                                        className="mb-2"
                                        key={a?._id}
                                      >
                                        <div
                                          className={`p-2 rounded border ${
                                            a?.correct
                                              ? "bg-primary-ultralight border-primary-superlight"
                                              : "border-lighter"
                                          }`}
                                          style={{}}
                                        >
                                          <Row
                                            onClick={() => {
                                              let questions =
                                                this.state.questions;

                                              let answers =
                                                questions[i].answers;

                                              answers = answers.map((a2) => {
                                                if (a2._id == a._id) {
                                                  a2.correct = !a.correct;
                                                } else {
                                                  a2.correct = false;
                                                }

                                                return a2;
                                              });

                                              questions[i].answers = answers;

                                              this.setState({
                                                questions,
                                              });
                                            }}
                                            className="align-items-center cursor-pointer mb-1"
                                          >
                                            <Col xs="">
                                              <h4 className="text-dark m-0">
                                                Option {j + 1}:
                                              </h4>
                                            </Col>
                                            <Col xs="auto">
                                              <div className="text-dark">
                                                <input
                                                  className="mr-2"
                                                  style={{
                                                    position: "relative",
                                                    top: 1,
                                                    accentColor:
                                                      "var(--primary)",
                                                  }}
                                                  type="checkbox"
                                                  checked={
                                                    q?.answers[j].correct
                                                  }
                                                  onChange={(e) => {
                                                    const { name, value } =
                                                      e.target;

                                                    let questions =
                                                      this.state.questions;

                                                    let answers = questions[
                                                      i
                                                    ].answers?.map((a) => {
                                                      a.correct = false;

                                                      if (
                                                        a._id ==
                                                        q.answers[j]._id
                                                      ) {
                                                        a.correct =
                                                          e.target.checked;
                                                      }

                                                      return a;
                                                    });

                                                    questions[i].answers =
                                                      answers;

                                                    this.setState({
                                                      questions,
                                                    });
                                                  }}
                                                ></input>
                                                Correct
                                              </div>
                                            </Col>
                                          </Row>

                                          <Input
                                            type="textarea"
                                            numRows={2}
                                            placeholder="Option"
                                            className="form-control form-control-sm"
                                            name="option"
                                            value={q.answers[j].option}
                                            onChange={(e) => {
                                              const { name, value } = e.target;

                                              let questions =
                                                this.state.questions;

                                              questions[i].answers[j].option =
                                                value;

                                              this.setState({
                                                questions,
                                              });
                                            }}
                                          ></Input>
                                        </div>
                                      </Col>
                                    </>
                                  ))}
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        ))}
                      </>
                    ) : null}
                    <CardBody className="border-top">
                      <Row className="align-items-center">
                        <Col xs="12" sm="">
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="text-dark mb-0">
                                Manually Add Question
                              </h4>
                              <p className="small m-0">
                                Manually add a new question to the topic.
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="12" sm="auto" className="mt-3 mt-sm-0">
                          <Button
                            color="primary"
                            onClick={() => {
                              let questions = this.state.questions?.length
                                ? this.state.questions
                                : [];

                              questions.push({
                                aiGenerated: false,
                                _id: StringUtils.uuid(),
                                question: "",
                                answers: [
                                  {
                                    option: "",
                                    correct: false,
                                    _id: StringUtils.uuid(),
                                  },
                                  {
                                    option: "",
                                    correct: false,
                                    _id: StringUtils.uuid(),
                                  },
                                  {
                                    option: "",
                                    correct: false,
                                    _id: StringUtils.uuid(),
                                  },
                                  {
                                    option: "",
                                    correct: false,
                                    _id: StringUtils.uuid(),
                                  },
                                ],
                              });

                              this.setState({
                                questions,
                              });
                            }}
                            size="sm"
                            disabled={this.state.submittingQuestion}
                          >
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </>
            ) : null}
          </Container>
        </div>
        {this.getNextTopic() || this.getPreviousTopic() ? (
          <>
            <div className="bg-white pt-4 pb-4 border-bottom">
              <Container fluid>
                <Row>
                  <Col xs="">
                    {this.getPreviousTopic() && (
                      <>
                        <h3 className="m-0 font-weight-normal">
                          <Link
                            to={`/university/library/topics/${
                              this.getPreviousTopic()?._id
                            }?moduleID=${this.state.module?._id}`}
                          >
                            <span
                              className="mr-1"
                              style={{ position: "relative", top: 1 }}
                            >
                              <i className="mdi mdi-chevron-left"></i>
                            </span>
                            {this.getPreviousTopic()?.name}
                          </Link>
                        </h3>
                      </>
                    )}
                  </Col>
                  <Col xs="" className="text-right">
                    {this.getNextTopic() && (
                      <>
                        <h3 className="m-0 font-weight-normal">
                          <Link
                            to={`/university/library/topics/${
                              this.getNextTopic()?._id
                            }?moduleID=${this.state.module?._id}`}
                          >
                            {this.getNextTopic()?.name}
                            <span
                              className="ml-1"
                              style={{ position: "relative", top: 1 }}
                            >
                              <i className="mdi mdi-chevron-right"></i>
                            </span>
                          </Link>
                        </h3>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        ) : null}

        <LibraryTopicEditDrawer
          open={this.state.openTopic}
          topic={this.state.topic}
          onModify={(topic) => {
            this.onModifyTopic(topic);
          }}
          onClose={() => {
            this.setState({ openTopic: false });
          }}
        ></LibraryTopicEditDrawer>
        <Drawer
          style={{ maxWidth: "100%" }}
          open={this.state.openTemplates}
          onClose={() => {
            this.setState({
              openTemplates: false,
            });
          }}
          size="sm"
        >
          <Drawer.Header className="pr-4 border-bottom-0">
            <h3 className="mb-2" style={{ position: "relative", top: "7px" }}>
              Topic Templates
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-0" style={{ height: "calc(100% - 70px)" }}>
            {this.state.loadingTemplates ? (
              <div className="py-3 text-center">
                <Spinner size="sm"></Spinner>
              </div>
            ) : (
              <div>
                {this.state.templates?.map((template, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      this.setState({
                        openTemplates: false,
                        topicContent: template?.template,
                      });

                      //console.log("HERE");

                      PubSub.publish("editor:contentUpdated", {
                        event: "contentUpdated",
                        forceHydrate: true,
                        id: "topicTemplateEditor",
                        data: template?.template ? template?.template : {},
                      });
                    }}
                    className={`p-3 cursor-pointer bg-superlight--hover border-bottom ${
                      i == 0 ? "border-top" : ""
                    }`}
                  >
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="m-0">{template?.name}</h3>
                        {template?.description ? (
                          <p className="m-0">{template?.description}</p>
                        ) : null}
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="dark"
                          outline
                          className="btn-icon-only"
                          size="sm"
                        >
                          <i className="mdi mdi-chevron-right"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            )}
          </Drawer.Body>
        </Drawer>
        <FileUploadDrawer
          cdn="private"
          compressUploads={false}
          size="sm"
          onComplete={(files) => {
            console.log(files);

            this.setState({
              openAttachments: false,
            });

            this.uploadAttachments(files);
          }}
          open={this.state.openAttachments}
          onClose={() => {
            this.setState({
              openAttachments: false,
            });
          }}
        ></FileUploadDrawer>
      </>
    );
  }
}

export default LibraryTopic;
