import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeaderV2 from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import TaskModifyDrawer from "components/Drawers/TaskModifyDrawer";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";

class FranDevTaskListView extends React.Component {
  state = {
    active: {
      id: "Open Tasks Assigned To Me",
      name: "Open Tasks Assigned To Me",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 15,
    filters: null,
    orderBy: {
      dueDate: 1,
    },
    tabs: [
      {
        name: "Open Tasks Assigned To Me",
        id: "Open Tasks Assigned To Me",
      },
      {
        name: "Open Tasks Created By Me",
        id: "Open Tasks Created By Me",
      },
      {
        name: "All Upcoming & Overdue Tasks",
        id: "All Upcoming & Overdue Tasks",
      },
      {
        name: "Fran-Dev",
        id: "Fran-Dev",
      },
      /* {
        name: "Fran-Sales (First Calls)",
        id: "Fran-Sales-First",
      },
      {
        name: "Fran-Sales (All Tasks)",
        id: "Fran-Sales-All",
      },*/

      {
        name: "Fran-Support",
        id: "Fran-Support",
      },
      {
        name: "Fran-Finance",
        id: "Fran-Finance",
      },
      {
        name: "Unit Operations",
        id: "Unit Operations",
      },
      {
        name: "Technology",
        id: "Technology",
      },
      {
        name: "Marketing",
        id: "Marketing",
      },
      {
        name: "Production",
        id: "Production",
      },
      {
        name: "Real Estate & Construction",
        id: "Real Estate & Construction",
      },
      {
        name: "Franchise Team NSO Tasks",
        id: "Franchise Team NSO Tasks",
      },
      {
        name: "Franchise Partner",
        id: "Franchise Partner",
      },
      {
        name: "All Tasks Created By Me",
        id: "All Tasks Created By Me",
      },
      {
        name: "All Incomplete Tasks",
        id: "All Incomplete Tasks",
      },
      {
        name: "All Completed Tasks",
        id: "All Completed Tasks",
      },
    ],
    sortByOption: "dueDate",
    sortByDirection: "ascending",
    viewMode: "table",
  };

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    this.loadStores();

    PubSub.subscribe(Event.TASK.CREATED, (m) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.TASK.MODIFIED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.detailTask?._id == a?._id) {
        this.setState({
          detailTask: a,
        });
      }
    });

    PubSub.subscribe(Event.TASK.DELETED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadTasks(page = 1, count = 15, filters = null, orderBy) {
    this.setState({
      loadingTasks: true,
    });

    //console.log("TEST", page, count, filters, orderBy);

    //console.log(orderBy);

    APIV2.getTasks(page, count, filters, orderBy)
      .then(
        (data) => {
          if (data && data.data) {
            let tasks = [];

            if (data.data.tasks.length) {
              tasks = data.data.tasks;
            }

            //console.log(data.data.pagination);

            this.setState({
              tasks,
              pagination: data.data.pagination,
              result: data.data.result,
              page: data.data.pagination?.current,
              count: data.data.pagination?.count,
              filters: filters,
              orderBy: orderBy,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the tasks. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTasks: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchTasksForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultTasks: data.data.tasks,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultTasks: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    let now = moment();
    let due = moment(dateString);

    if (now.toDate() > due.toDate() && status != "Completed") {
      return (
        <Badge
          style={{ fontSize: "0.75rem" }}
          color=""
          className="bg-yellow text-dark"
        >
          {due.format("MMM DD, YYYY")}
        </Badge>
      );
    }

    return due.format("MMM DD, YYYY");
  }

  async loadTabCounts(force = false) {
    if (this.state.tabsLoading) {
      return;
    }

    this.setState(
      {
        tabsLoading: true,
      },
      () => {
        let out = this.state.tabs?.map((tab) => {
          if (force || tab?.countLoading === undefined) {
            tab.count = 0;
            tab.countLoading = true;
          }

          return _.clone(tab);
        });

        this.setState({ tabs: out }, async () => {
          let tabs = this.state.tabs?.map((t) => {
            const { filters } = this.getFilters(t);

            return {
              id: t.id,
              collection: "Tasks",
              query: filters,
            };
          });

          try {
            let data = await APIV2.getTabCounts(tabs);

            let out = this.state.tabs?.map((tab) => {
              const res = _.findWhere(data.data?.tabs, { id: tab.id });

              tab.count = res?.count ? res.count : 0;
              tab.countLoading = false;

              return tab;
            });

            this.setState({
              tabs: out,
            });
          } catch (e) {
            console.error(e);
          }

          this.setState({
            tabsLoading: false,
          });
        });
      }
    );
  }

  getFilters(option) {
    let filters = null;
    let orderBy = {
      dueDate: 1,
    };

    let user = LocalStorage.get(Constant.CACHE.USER);

    if (option.id == "Open Tasks Assigned To Me") {
      filters = {
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        assignedTo: user?._id,
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Open Tasks Created By Me") {
      filters = {
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        "createdBy._id": user?._id,
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Tasks Created By Me") {
      filters = {
        storeTask: true,
        supportTicket: { $ne: true },
        "createdBy._id": user?._id,
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Upcoming & Overdue Tasks") {
      let mom = moment();

      mom.add(7, "days");

      filters = {
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        dueDate: {
          $lte: { $date: mom.toDate().toISOString() },
        },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Unit Operations") {
      filters = {
        type: "Unit Operations",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Fran-Dev") {
      filters = {
        type: "Fran-Dev",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        frandevLeadID: { $in: [null, ""] },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Fran-Sales-First") {
      filters = {
        type: "Fran-Dev",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        frandevLeadID: { $nin: [null, ""] },
        subject: { $regex: "(Call 1)", $options: "i" },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Fran-Sales-All") {
      filters = {
        type: "Fran-Dev",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        frandevLeadID: { $nin: [null, ""] },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Fran-Support") {
      filters = {
        type: "Fran-Support",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Fran-Finance") {
      filters = {
        type: "Fran-Finance",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Franchise Partner") {
      filters = {
        type: "Franchise Partner",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Franchise Team NSO Tasks") {
      filters = {
        subject: { $regex: "NSO:" },
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Technology") {
      filters = {
        type: "Technology",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Marketing") {
      filters = {
        type: "Marketing",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Production") {
      filters = {
        type: "Production",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Real Estate & Construction") {
      filters = {
        type: "Real Estate & Construction",
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Incomplete Tasks") {
      filters = {
        status: { $in: ["Not Started", "In Progress", "On Hold"] },
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Completed Tasks") {
      filters = {
        status: "Completed",
        storeTask: true,
        supportTicket: { $ne: true },
        deleted: { $ne: true },
      };
      orderBy = { completedAt: -1 };
    }

    return {
      filters,
      orderBy,
    };
  }

  handleTabChange(option, force = false) {
    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    console.log("TAB CHANGE");

    let user = LocalStorage.get(Constant.CACHE.USER);

    const { filters, orderBy } = this.getFilters(option);

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));

    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadTasks(1, this.state.count, filters, orderBy);
    this.loadTabCounts();
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores()
      .then(
        (data) => {
          if (data && data.data) {
            let store = _.findWhere(data.data.stores, {
              _id: this.props?.match?.params?.storeID,
            });

            this.setState({ stores: data.data.stores, store });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the stores. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadTasks(page, this.state.count, this.state.filters);
  }

  row(c) {
    return (
      <Row className="align-items-center">
        <Col xs="6" sm="6" md="4">
          {c?.store?.name ? (
            <>
              <p className="m-0 small">
                {c?.store?.name?.replace("Project LeanNation", "PLN")}
              </p>
            </>
          ) : (
            <>
              <p className="m-0 small">FranDev Team</p>
            </>
          )}
          <h4 className="mb-0">{c.subject}</h4>
          <p className="mb-0" style={{ fontSize: "13px" }}>
            {c.type}
            {c?.activity ? ` (${c?.activity})` : ``}
            {c?.customer ? ` for ${c?.customer?.name}` : ""}
            {c?.lead ? ` for ${c?.lead?.name}` : ""}
            {c?.frandevLead ? ` - ${c?.frandevLead?.name}` : ""}
          </p>
        </Col>

        <Col xs="6" sm="6" md="4" className="text-truncate">
          <Whisper
            speaker={
              <Tooltip>
                <p className="m-0">
                  {c?.assignedTo ? c?.assignedTo?.name : "(unassigned)"}
                </p>
              </Tooltip>
            }
            placement="auto"
            trigger={`${c?.assignedTo ? "hover" : "none"}`}
            delayOpen={400}
          >
            <span>{c?.assignedTo ? c?.assignedTo?.name : "--"}</span>
          </Whisper>
        </Col>
        <Col xs="6" sm="6" md="2">
          {this.formatDueDate(c.dueDate, c.status)}
        </Col>

        <Col xs="6" sm="6" md="2">
          {this.formatStatus(c.status)}
        </Col>
      </Row>
    );
  }

  switchViewMode(viewMode) {
    this.setState({
      viewMode,
    });
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "Not Started") {
      return (
        <Badge
          color=""
          style={{ fontSize: "0.75rem" }}
          className="bg-yellow text-dark"
        >
          {status}
        </Badge>
      );
    }

    if (status == "In Progress") {
      return (
        <Badge style={{ fontSize: "0.75rem" }} color="info">
          {status}
        </Badge>
      );
    }

    if (status == "Completed") {
      return (
        <Badge style={{ fontSize: "0.75rem" }} color="light">
          {status}
        </Badge>
      );
    }

    return (
      <Badge style={{ fontSize: "0.75rem" }} color="light">
        {status}
      </Badge>
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeaderV2
            sticky={true}
            title={`Franchise Tasks`}
            showBackButton={false}
            forceSecondaryDropdown={true}
            secondaryActions={
              [
                /*{
                name: <>View As Table</>,
                onClick: () => {
                  this.setState({
                    viewMode: "table",
                  });
                },
                disabled: this.state.viewMode == "table",
              },
              {
                name: <>View As Board</>,
                onClick: () => {
                  this.setState({
                    viewMode: "board",
                  });
                },
                disabled: this.state.viewMode == "board",
              },*/
              ]
            }
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  openCreate: true,
                });
              },
            }}
            tabs={
              <CardSelectorHeader
                compact={true}
                showCount={true}
                value={this.state.active}
                options={this.state.tabs}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
            }
          />
          {this.state.viewMode == "table" ? (
            <>
              <div
                className="pb-3 bg-white border-bottom"
                style={{
                  marginLeft: -15,
                  marginRight: -15,
                  marginTop: "-1.5rem",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: "1rem",
                }}
              >
                <Row className="align-items-center">
                  <Col xs="" sm="" className="">
                    <Input
                      type="text"
                      placeholder="Search tasks..."
                      size="sm"
                      name="search"
                      value={this.state.search}
                      onChange={this.handleSearchChange.bind(this)}
                    ></Input>
                  </Col>
                </Row>
                <Row className="align-items-center mt-3">
                  {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                  <Col xs="" sm="" className="">
                    {this.state.searchResults ? (
                      <>
                        Found{" "}
                        {this.state.searchResultTasks?.length
                          ? StringUtils.numberFormat(
                              this.state.searchResultTasks?.length
                            )
                          : "0"}{" "}
                        task
                        {this.state.searchResultTasks?.length == 1
                          ? ""
                          : "s"}{" "}
                        matching your search.
                      </>
                    ) : (
                      <div>
                        {this.state.result?.start && this.state.result?.end ? (
                          <>
                            Showing{" "}
                            {StringUtils.numberFormat(this.state.result?.start)}
                            -{StringUtils.numberFormat(this.state.result?.end)}
                            {this.state.result?.total ? (
                              <>
                                &nbsp;of{" "}
                                {StringUtils.numberFormat(
                                  this.state.result?.total
                                )}{" "}
                                tasks
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>---</>
                        )}
                      </div>
                    )}
                  </Col>
                  <Col xs="auto" sm="auto" className="pl-2 pl-sm-3 text-right">
                    <Button
                      style={{ height: "28px" }}
                      size="sm"
                      color="secondary"
                      className="btn-icon-only"
                      outline
                      disabled={
                        this.state.page == 1 ||
                        !this.state.pagination?.hasPrevious ||
                        this.state.searchResults
                      }
                      onClick={() => {
                        this.incrementPage(-1);
                      }}
                    >
                      <i
                        className="mdi mdi-chevron-left"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </Button>
                    <Button
                      style={{ height: "28px" }}
                      size="sm"
                      color="secondary"
                      disabled={
                        !this.state.pagination?.hasNext ||
                        this.state.searchResults
                      }
                      className="btn-icon-only"
                      outline
                      onClick={() => {
                        this.incrementPage(1);
                      }}
                    >
                      <i
                        className="mdi mdi-chevron-right"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </Button>
                  </Col>
                </Row>
              </div>
              <div
                className="bg-white"
                style={{
                  marginLeft: -15,
                  marginRight: -15,
                }}
              >
                <div className="py-2 px-3 bg-superlight border-bottom text-dark">
                  <Row className="align-items-center">
                    <Col xs="6" sm="6" md="4">
                      <h4 className="mb-0">Task</h4>
                    </Col>

                    <Col xs="6" sm="6" md="2">
                      <h4 className="mb-0">Priority</h4>
                    </Col>
                    <Col xs="6" sm="6" md="2">
                      <h4 className="mb-0">Assigned To</h4>
                    </Col>
                    <Col xs="6" sm="6" md="2">
                      <h4
                        className={`mb-0 cursor-pointer ${
                          this.state.sortByOption == "dueDate"
                            ? "font-weight-bold"
                            : ""
                        }`}
                        onClick={() => {
                          let dir = "ascending";

                          if (this.state.sortByOption == "dueDate") {
                            if (this.state.sortByDirection == "descending") {
                              return this.setState(
                                {
                                  sortByOption: "",
                                  sortByDirection: "",
                                  orderBy: null,
                                },
                                () => {
                                  this.loadTasks(
                                    1,
                                    this.state.count,
                                    this.state.filters,
                                    this.state.orderBy
                                  );
                                }
                              );
                            } else if (
                              this.state.sortByDirection == "ascending"
                            ) {
                              dir = "descending";
                            }
                          }

                          this.setState(
                            {
                              sortByOption: "dueDate",
                              sortByDirection: dir,
                              orderBy: {
                                dueDate: dir == "descending" ? -1 : 1,
                              },
                            },
                            () => {
                              this.loadTasks(
                                1,
                                this.state.count,
                                this.state.filters,
                                this.state.orderBy
                              );
                            }
                          );
                        }}
                      >
                        Due Date
                        {this.state.sortByOption == "dueDate" ? (
                          <>
                            <i
                              className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                            ></i>
                          </>
                        ) : (
                          <>
                            <i className="mdi mdi-sort pl-1"></i>
                          </>
                        )}
                      </h4>
                    </Col>
                    <Col xs="6" sm="6" md="2">
                      <h4 className="mb-0">Status</h4>
                    </Col>
                  </Row>
                </div>

                <div>
                  {this.state.loadingTasks || this.state.searchLoading ? (
                    <>
                      <div
                        className="text-center p-3"
                        style={{
                          zIndex: 1,
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          background: "rgba(255, 255, 255, 0.6)",
                        }}
                      >
                        <Spinner
                          style={{ position: "relative", top: "15vh" }}
                          color="medium"
                          size=""
                        ></Spinner>
                      </div>
                    </>
                  ) : null}
                  {this.state.searchResults ? (
                    <div>
                      {!this.state.searchResultTasks?.length &&
                      !this.state.searchLoading ? (
                        <div className="px-3 py-6 border-bottom">
                          <Row>
                            <Col>
                              No tasks found matching your search "
                              {this.state.search}"
                            </Col>
                          </Row>
                        </div>
                      ) : null}

                      {this.state.searchResultTasks?.map((c, i) => (
                        <div
                          className={`px-3 py-2 bg-superlight--hover cursor-pointer border-bottom`}
                          key={i}
                          onClick={() => {
                            this.setState({
                              detailTask: c,
                              detailOpen: true,
                            });
                          }}
                        >
                          {this.row(c)}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {!this.state.tasks?.length && !this.state.loadingTasks ? (
                        <div className="px-3 py-6 border-bottom">
                          <Row>
                            <Col>Get started by adding your first task.</Col>
                          </Row>
                        </div>
                      ) : null}

                      {this.state.tasks?.map((c, i) => (
                        <div
                          className={`px-3 py-2 bg-superlight--hover cursor-pointer border-bottom`}
                          key={i}
                          onClick={() => {
                            this.setState({
                              detailTask: c,
                              detailOpen: true,
                            });
                          }}
                        >
                          {this.row(c)}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : null}
          {this.state.viewMode == "board" ? <div></div> : null}
        </Container>
        <TaskDetailDrawer
          task={this.state.detailTask}
          open={this.state.detailOpen}
          onClose={() => {
            this.setState({
              detailTask: null,
              detailOpen: false,
            });
          }}
        ></TaskDetailDrawer>
        <TaskCreateDrawer
          allowAssignStore={true}
          storeTask={true}
          open={this.state.openCreate}
          onClose={() => {
            this.setState({ openCreate: false });
          }}
        ></TaskCreateDrawer>
      </>
    );
  }
  f;
}

export default FranDevTaskListView;
