import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import TaskModifyDrawer from "components/Drawers/TaskModifyDrawer";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";

const nsoTabs = [
  {
    name: "All Upcoming Tasks",
    id: "All Upcoming Tasks",
  },
  {
    name: "1st Lead Call",
    id: "1st Lead Call",
  },
  {
    name: "2nd Lead Call",
    id: "2nd Lead Call",
  },
  {
    name: "Final Lead Call",
    id: "Final Lead Call",
  },
  {
    name: "FranDev: Upcoming & Overdue",
    id: "FranDev: Upcoming & Overdue",
  },
  {
    name: "FranDev: All Incomplete",
    id: "FranDev: All Incomplete",
  },
  {
    name: "FranDev: All Completed",
    id: "FranDev: All Completed",
  },
  {
    name: "Member Engagement",
    id: "Member Engagement",
  },
  {
    name: "New Online Signups",
    id: "New Online Signups",
  },
  {
    name: "Billing",
    id: "Billing",
  },
  {
    name: "Fulfillment",
    id: "Fulfillment",
  },
  {
    name: "Admin",
    id: "Admin",
  },
  {
    name: "Partner Engagement",
    id: "Partner Engagement",
  },

  {
    name: "All Incomplete Tasks",
    id: "All Incomplete Tasks",
  },
];

const tabs = [
  {
    name: "All Upcoming Tasks",
    id: "All Upcoming Tasks",
  },
  {
    name: "1st Lead Call",
    id: "1st Lead Call",
  },
  {
    name: "2nd Lead Call",
    id: "2nd Lead Call",
  },
  {
    name: "Final Lead Call",
    id: "Final Lead Call",
  },

  {
    name: "Member Engagement",
    id: "Member Engagement",
  },
  {
    name: "New Online Signups",
    id: "New Online Signups",
  },
  {
    name: "Billing",
    id: "Billing",
  },
  {
    name: "Fulfillment",
    id: "Fulfillment",
  },
  {
    name: "Admin",
    id: "Admin",
  },
  {
    name: "Partner Engagement",
    id: "Partner Engagement",
  },

  {
    name: "FranSupport: All Incomplete",
    id: "FranSupport: All Incomplete",
  },
  {
    name: "All Incomplete Tasks",
    id: "All Incomplete Tasks",
  },
];

class StorefrontTaskListView extends React.Component {
  state = {
    active: {
      id: "All Upcoming Tasks",
      name: "All Upcoming Tasks",
    },
    tabs: tabs,
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 15,
    filters: null,
    orderBy: {
      dueDate: 1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
  };

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }

    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID &&
      this.props.match?.params?.storeID &&
      this.props.match?.params?.storeID != "select"
    ) {
      this.handleTabChange(this.state.active, true);
      this.loadTabCounts(true);
      this.loadStores();
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    this.loadStores();
    this.loadTabCounts();

    PubSub.subscribe(Event.TASK.CREATED, (m) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.TASK.MODIFIED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.detailTask?._id == a?._id) {
        this.setState({
          detailTask: a,
        });
      }
    });

    PubSub.subscribe(Event.TASK.DELETED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    API.setMealImage(1, formData).then(
      (data) => {
        // console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadTasks(page = 1, count = 15, filters = null, orderBy) {
    this.setState({
      loadingTasks: true,
    });

    APIV2.getTasksForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      orderBy
    )
      .then(
        (data) => {
          if (data && data.data) {
            let tasks = [];

            if (data.data.tasks.length) {
              tasks = data.data.tasks;
            }

            this.setState({
              tasks,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the tasks. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTasks: false,
        });

        this.loadTabCounts();
      });
  }

  performSearch() {
    APIV2.simpleSearchTasksForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultTasks: data.data.tasks,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultTasks: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  getFilters(option) {
    let filters = null;
    let orderBy = {
      dueDate: 1,
    };

    if (option.id == "All Upcoming Tasks") {
      let mom = moment();

      mom.add(7, "days");

      filters = {
        storeID: this.props?.match?.params?.storeID,
        status: { $in: ["Not Started", "In Progress"] },
        dueDate: {
          $lte: { $date: mom.toDate().toISOString() },
        },
        deleted: { $ne: true },
        type: { $ne: "Fran-Support" },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "1st Lead Call") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: "Lead Engagement",
        subject: { $regex: "Lead Call: 1st Attempt", $options: "i" },
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "2nd Lead Call") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: "Lead Engagement",
        subject: { $regex: "Lead Call: 2nd Attempt", $options: "i" },
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Final Lead Call") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: "Lead Engagement",
        subject: { $regex: "Lead Call: Final Attempt", $options: "i" },
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Lead Engagement") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: "Lead Engagement",
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Lead Engagement") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: "All Lead Engagement",
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Lead Engagement") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: "All Lead Engagement",
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Partner Engagement") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: { $in: ["Partner Engagement", "Affiliate Engagement"] },
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Member Engagement") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: "Member Engagement",
        subject: {
          $nin: [
            "New Online Sign-Up: Schedule Consultation",
            "New Online Sign-Up: Call",
          ],
        },
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "New Online Signups") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        subject: { $regex: "New Online", $options: "i" },
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Billing") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: "Billing",
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Fulfillment") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: "Fulfillment",
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "Admin") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        type: "Admin",
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Incomplete Tasks") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: { $ne: true },
      };
      orderBy = { dueDate: 1 };
    } else if (option.id == "All Completed Tasks") {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        status: "Completed",
        storeTask: { $ne: true },
      };
      orderBy = { completedAt: -1 };
    } else if (
      option.id == "FranDev: Upcoming & Overdue" ||
      option.id == "FranSupport: Upcoming & Overdue"
    ) {
      let mom = moment();

      mom.add(14, "days");

      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        status: { $in: ["Not Started", "In Progress"] },
        dueDate: {
          $lte: { $date: mom.toDate().toISOString() },
        },
        storeTask: true,
        type: "Franchise Partner",
      };

      orderBy = { dueDate: 1 };
    } else if (
      option.id == "FranDev: All Incomplete" ||
      option.id == "FranSupport: All Incomplete"
    ) {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        status: { $in: ["Not Started", "In Progress"] },
        storeTask: true,
        type: "Franchise Partner",
      };

      orderBy = { dueDate: 1 };
    } else if (
      option.id == "FranDev: All Completed" ||
      option.id == "FranSupport: All Completed"
    ) {
      filters = {
        storeID: this.props?.match?.params?.storeID,
        deleted: { $ne: true },
        status: { $in: ["Completed"] },
        storeTask: true,
        type: "Franchise Partner",
      };

      orderBy = { completedAt: -1 };
    }

    return {
      filters,
      orderBy,
    };
  }

  handleTabChange(option, force = false) {
    let filters = null;
    let orderBy = {
      dueDate: 1,
    };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    const res = this.getFilters(option);

    filters = res.filters;
    orderBy = res.orderBy;

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadTasks(1, this.state.count, filters, orderBy);
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  async loadTabCounts(force = false) {
    if (this.state.tabsLoading) {
      return;
    }

    this.setState(
      {
        tabsLoading: true,
      },
      () => {
        let out = this.state.tabs?.map((tab) => {
          if (force || tab?.countLoading === undefined) {
            tab.count = 0;
            tab.countLoading = true;
          }

          return _.clone(tab);
        });

        this.setState({ tabs: out }, async () => {
          let tabs = this.state.tabs?.map((t) => {
            const { filters } = this.getFilters(t);

            return {
              id: t.id,
              collection: "Tasks",
              query: filters,
            };
          });

          try {
            let data = await APIV2.getTabCounts(tabs);

            let out = this.state.tabs?.map((tab) => {
              const res = _.findWhere(data.data?.tabs, { id: tab.id });

              tab.count = res?.count ? res.count : 0;
              tab.countLoading = false;

              return tab;
            });

            this.setState({
              tabs: out,
            });
          } catch (e) {
            console.error(e);
          }

          this.setState({
            tabsLoading: false,
          });
        });
      }
    );
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoreByID(this.props?.match?.params?.storeID)
      .then(
        (data) => {
          if (data && data.data) {
            let store = data.data?.store;

            //console.log(store?.nsoStage);

            this.setState({ store });

            if (store?.nsoStage != "Live") {
              this.setState(
                {
                  tabs: JSON.parse(JSON.stringify(nsoTabs)),
                },
                () => {
                  this.loadTabCounts(true);
                }
              );
            } else {
              this.setState(
                {
                  tabs: JSON.parse(JSON.stringify(tabs)),
                },
                () => {
                  this.loadTabCounts(true);
                }
              );
            }
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the store. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadTasks(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    let now = moment();
    let due = moment(dateString);

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    if (status == "Not Started") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {status}
        </Badge>
      );
    }

    if (status == "In Progress") {
      return <Badge color="info">{status}</Badge>;
    }

    if (status == "Completed") {
      return <Badge color="light">{status}</Badge>;
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Tasks"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
            tabs={
              <CardSelectorHeader
                showCount={true}
                compact={true}
                value={this.state.active}
                options={this.state.tabs}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
            }
          />

          <Row>
            <div className="col">
              <Card
                className=" border-0 mt--3"
                style={{
                  minHeight: "500px",
                  marginLeft: -15,
                  marginRight: -15,
                }}
              >
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <Input
                        type="text"
                        placeholder="Search tasks..."
                        size="sm"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                      ></Input>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-3">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.searchResults ? (
                        <div className="text-dark">
                          Found{" "}
                          {this.state.searchResultTasks?.length
                            ? StringUtils.numberFormat(
                                this.state.searchResultTasks?.length
                              )
                            : "0"}{" "}
                          task
                          {this.state.searchResultTasks?.length == 1
                            ? ""
                            : "s"}{" "}
                          matching your search.
                        </div>
                      ) : (
                        <div className="text-dark">
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  tasks
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults ||
                          this.state.loadingTasks
                            ? "light"
                            : "secondary"
                        }
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults ||
                          this.state.loadingTasks
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults ||
                          this.state.loadingTasks
                            ? "light"
                            : "secondary"
                        }
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults ||
                          this.state.loadingTasks
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color={
                          this.state.loadingTasks || this.state.searchLoading
                            ? "light"
                            : "dark"
                        }
                        disabled={
                          this.state.loadingTasks || this.state.searchLoading
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.loadTasks(
                            this.state.page,
                            this.state.count,
                            this.state.filters,
                            this.state.orderBy
                          );
                        }}
                      >
                        {this.state.loadingTasks || this.state.searchLoading ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          <i className="mdi mdi-refresh"></i>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Subject</h4>
                      </Col>

                      <Col xs="6" sm="6" md="2">
                        <h4
                          className={`mb-0 cursor-pointer ${
                            this.state.sortByOption == "dueDate"
                              ? "font-weight-bold"
                              : ""
                          }`}
                          onClick={() => {
                            let dir = "ascending";

                            if (this.state.sortByOption == "dueDate") {
                              if (this.state.sortByDirection == "descending") {
                                return this.setState(
                                  {
                                    sortByOption: "",
                                    sortByDirection: "",
                                    orderBy: null,
                                  },
                                  () => {
                                    this.loadTasks(
                                      1,
                                      this.state.count,
                                      this.state.filters,
                                      this.state.orderBy
                                    );
                                  }
                                );
                              } else if (
                                this.state.sortByDirection == "ascending"
                              ) {
                                dir = "descending";
                              }
                            }

                            this.setState(
                              {
                                sortByOption: "dueDate",
                                sortByDirection: dir,
                                orderBy: {
                                  dueDate: dir == "descending" ? -1 : 1,
                                },
                              },
                              () => {
                                this.loadTasks(
                                  1,
                                  this.state.count,
                                  this.state.filters,
                                  this.state.orderBy
                                );
                              }
                            );
                          }}
                        >
                          Due Date
                          {this.state.sortByOption == "dueDate" ? (
                            <>
                              <i
                                className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i className="mdi mdi-sort pl-1"></i>
                            </>
                          )}
                        </h4>
                      </Col>

                      <Col xs="6" sm="6" md="2">
                        <h4
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            let dir = "ascending";

                            if (this.state.sortByOption == "status") {
                              if (this.state.sortByDirection == "descending") {
                                return this.setState(
                                  {
                                    sortByOption: "",
                                    sortByDirection: "",
                                    orderBy: null,
                                  },
                                  () => {
                                    this.loadTasks(
                                      1,
                                      this.state.count,
                                      this.state.filters,
                                      this.state.orderBy
                                    );
                                  }
                                );
                              } else if (
                                this.state.sortByDirection == "ascending"
                              ) {
                                dir = "descending";
                              }
                            }

                            this.setState(
                              {
                                sortByOption: "status",
                                sortByDirection: dir,
                                orderBy: {
                                  status: dir == "descending" ? -1 : 1,
                                },
                              },
                              () => {
                                this.loadTasks(
                                  1,
                                  this.state.count,
                                  this.state.filters,
                                  this.state.orderBy
                                );
                              }
                            );
                          }}
                        >
                          Status
                          {this.state.sortByOption == "status" ? (
                            <>
                              <i
                                className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i className="mdi mdi-sort pl-1"></i>
                            </>
                          )}
                        </h4>
                      </Col>
                      <Col xs="12" sm="12" md="4" className="d-none d-md-block">
                        <h4
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            let dir = "ascending";

                            if (this.state.sortByOption == "createdAt") {
                              if (this.state.sortByDirection == "descending") {
                                return this.setState(
                                  {
                                    sortByOption: "",
                                    sortByDirection: "",
                                    orderBy: null,
                                  },
                                  () => {
                                    this.loadTasks(
                                      1,
                                      this.state.count,
                                      this.state.filters,
                                      this.state.orderBy
                                    );
                                  }
                                );
                              } else if (
                                this.state.sortByDirection == "ascending"
                              ) {
                                dir = "descending";
                              }
                            }

                            this.setState(
                              {
                                sortByOption: "createdAt",
                                sortByDirection: dir,
                                orderBy: {
                                  createdAt: dir == "descending" ? -1 : 1,
                                },
                              },
                              () => {
                                this.loadTasks(
                                  1,
                                  this.state.count,
                                  this.state.filters,
                                  this.state.orderBy
                                );
                              }
                            );
                          }}
                        >
                          Created
                          {this.state.sortByOption == "createdAt" ? (
                            <>
                              <i
                                className={`mdi mdi-sort-${this.state.sortByDirection} pl-1`}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i className="mdi mdi-sort pl-1"></i>
                            </>
                          )}
                        </h4>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    {this.state.loadingTasks || this.state.searchLoading ? (
                      <>
                        <div className="p-3 border-bottom">
                          <div
                            className="skeleton rounded"
                            style={{
                              width: "100%",
                              height: `calc(25px + 13px)`,
                            }}
                          ></div>
                        </div>
                        <div className="p-3 border-bottom">
                          <div
                            className="skeleton rounded"
                            style={{
                              width: "100%",
                              height: `calc(25px + 13px)`,
                            }}
                          ></div>
                        </div>
                        <div className="p-3 border-bottom">
                          <div
                            className="skeleton rounded"
                            style={{
                              width: "100%",
                              height: `calc(25px + 13px)`,
                            }}
                          ></div>
                        </div>
                        <div className="p-3 border-bottom">
                          <div
                            className="skeleton rounded"
                            style={{
                              width: "100%",
                              height: `calc(25px + 13px)`,
                            }}
                          ></div>
                        </div>
                        <div className="p-3 border-bottom">
                          <div
                            className="skeleton rounded"
                            style={{
                              width: "100%",
                              height: `calc(25px + 13px)`,
                            }}
                          ></div>
                        </div>
                        <div className="p-3 border-bottom">
                          <div
                            className="skeleton rounded"
                            style={{
                              width: "100%",
                              height: `calc(25px + 13px)`,
                            }}
                          ></div>
                        </div>
                        <div className="p-3 border-bottom">
                          <div
                            className="skeleton rounded"
                            style={{
                              width: "100%",
                              height: `calc(25px + 13px)`,
                            }}
                          ></div>
                        </div>
                        <div className="p-3 border-bottom">
                          <div
                            className="skeleton rounded"
                            style={{
                              width: "100%",
                              height: `calc(25px + 13px)`,
                            }}
                          ></div>
                        </div>
                      </>
                    ) : (
                      <>
                        {this.state.searchResults ? (
                          <div>
                            {!this.state.searchResultTasks?.length &&
                            !this.state.searchLoading ? (
                              <div className="px-3 py-2">
                                <Row>
                                  <Col>
                                    No tasks found matching your search "
                                    {this.state.search}"
                                  </Col>
                                </Row>
                              </div>
                            ) : null}

                            {this.state.searchResultTasks?.map((c, i) => (
                              <div
                                className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                                  i != this.state.searchResultTasks.length - 1
                                    ? "border-bottom"
                                    : ""
                                }`}
                                key={i}
                                onClick={() => {
                                  this.setState({
                                    detailTask: c,
                                    detailOpen: true,
                                  });
                                }}
                              >
                                <Row className="align-items-center">
                                  <Col xs="6" sm="6" md="4">
                                    <h4 className="mb-0">{c.subject}</h4>
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {c.type}
                                      {c?.customer
                                        ? ` For ${c?.customer?.name}`
                                        : ""}
                                      {c?.lead
                                        ? ` for ${
                                            c?.lead?.name
                                              ? c?.lead?.name
                                              : c?.lead?.email
                                              ? c?.lead?.email
                                              : c?.lead?.phone
                                              ? StringUtils.formatPhoneNumber(
                                                  c?.lead?.phone
                                                )
                                              : "new lead"
                                          }`
                                        : ""}
                                    </p>
                                  </Col>
                                  <Col xs="6" sm="6" md="2">
                                    {this.formatDueDate(c.dueDate, c.status)}
                                  </Col>

                                  <Col xs="6" sm="6" md="2">
                                    {this.formatStatus(c.status)}
                                  </Col>
                                  <Col xs="12" sm="12" md="4">
                                    {moment(c?.createdAt).format(
                                      "MMM DD, YYYY"
                                    )}{" "}
                                    <span className="font-weight-bold">
                                      ({moment(c?.createdAt).fromNow()})
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>
                            {!this.state.tasks?.length &&
                            !this.state.loadingTasks ? (
                              <div className="px-3 py-2">
                                <Row>
                                  <Col>
                                    Get started by adding your first task.
                                  </Col>
                                </Row>
                              </div>
                            ) : null}

                            {this.state.tasks?.map((c, i) => (
                              <div
                                className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                                  i != this.state.tasks.length - 1
                                    ? "border-bottom"
                                    : ""
                                }`}
                                key={i}
                                onClick={() => {
                                  this.setState({
                                    detailTask: c,
                                    detailOpen: true,
                                  });
                                }}
                              >
                                <Row className="align-items-center">
                                  <Col xs="6" sm="6" md="3">
                                    <h4 className="mb-0">{c.subject}</h4>
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {c.type}
                                      {c?.customer
                                        ? ` for ${c?.customer?.name}`
                                        : ""}
                                      {c?.lead
                                        ? ` for ${
                                            c?.lead?.name?.trim()
                                              ? c?.lead?.name
                                              : c?.lead?.email
                                              ? c?.lead?.email
                                              : c?.lead?.phone
                                              ? StringUtils.formatPhoneNumber(
                                                  c?.lead?.phone
                                                )
                                              : "new lead"
                                          }`
                                        : ""}
                                    </p>
                                  </Col>
                                  <Col
                                    xs="6"
                                    sm="6"
                                    md="2"
                                    className="text-dark"
                                  >
                                    {this.formatDueDate(c.dueDate, c.status)}
                                  </Col>

                                  <Col
                                    xs="6"
                                    sm="6"
                                    md="2"
                                    className="text-dark"
                                  >
                                    {this.formatStatus(c.status)}
                                  </Col>
                                  <Col
                                    xs="12"
                                    sm="12"
                                    md="4"
                                    className="mt-3 mt-md-0 text-dark"
                                  >
                                    <span className="d-inline d-md-none">
                                      Created:{" "}
                                    </span>
                                    {moment(c?.createdAt).format(
                                      "MMM DD, YYYY"
                                    )}
                                    &nbsp;&nbsp;
                                    <span className="font-weight-bold">
                                      ({moment(c?.createdAt).fromNow()})
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <TaskCreateDrawer
          store={this.state.store}
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          onCreate={(c) => {
            PubSub.publish(Event.TASK.CREATED, c);
          }}
        ></TaskCreateDrawer>
        <TaskDetailDrawer
          store={this.state.store}
          open={this.state.detailOpen}
          task={this.state.detailTask}
          onClose={() => {
            this.setState({
              detailOpen: false,
              detailTask: null,
            });
          }}
        ></TaskDetailDrawer>
      </>
    );
  }
  f;
}

export default StorefrontTaskListView;
