import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import ProductGroupRow from "components/ProductGroupRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import CreateBillingAccountModal from "components/CreateBillingAccountModal";
import CreateProductGroupModal from "components/CreateProductGroupModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import DataAPI from "lib/DataAPI";
import cubejs from "@cubejs-client/core";
import swal from "sweetalert";

class PlatformPaymentProcessingView extends React.Component {
  state = {
    groups: [],
    loading: true,
  };

  loadStatues(invoiceDate) {
    if (!invoiceDate) {
      return;
    }

    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["CustomerInvoices.count"],

          timeDimensions: [
            {
              dimension: "CustomerInvoices.dueon",
              dateRange: [
                moment(invoiceDate).subtract(1, "day").format("YYYY-MM-DD"),
                moment(invoiceDate).format("YYYY-MM-DD"),
              ],
            },
          ],
          filters: [
            {
              member: "CustomerInvoices.status",
              operator: "notEquals",
              values: ["CANCELLED"],
            },
          ],
          dimensions: ["CustomerInvoices.status"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let out = data?.map((d) => {
            return {
              status: d["CustomerInvoices.status"],
              count: d["CustomerInvoices.count"],
            };
          });

          this.setState({
            statuses: out,
            loading: false,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  rerunProcessing(invoiceDate) {
    if (!invoiceDate) {
      return;
    }

    swal({
      title: "Rerun Payment Processing?",
      text: `This will requeue the payment processing for pending invoices on ${moment(
        invoiceDate
      ).format("MM/DD/YYYY")}.`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (!willDelete) {
        return;
      }

      this.setState({
        processing: true,
      });

      APIV2.rerunPaymentProcessing(invoiceDate.format("YYYY-MM-DD"))
        .then(
          () => {
            swal({
              title: "Payment Processing Restarted",
              text: `Payment processing has been restarted for pending invoices on ${moment(
                invoiceDate
              ).format(
                "MM/DD/YYYY"
              )}. Processing will take 20-30 minutes. Please use the refresh button to view the live invoice statuses.`,
              icon: "success",
            });
          },
          () => {
            swal({
              title: "Payment Processing Failed",
              text: `Payment processing failed to restart for pending invoices on ${moment(
                invoiceDate
              ).format(
                "MM/DD/YYYY"
              )}. Please try again or contact support if the issue persists.`,
              icon: "error",
            });
          }
        )
        .finally(() => {
          this.setState({
            processing: false,
          });
        });
    });
  }

  componentDidMount() {
    let now = moment();

    let invoiceDate = moment().day(6).startOf("day");

    if (now.day() < 4) {
      invoiceDate = moment().day(6).subtract(1, "weeks");
    }

    this.setState(
      {
        invoiceDate,
      },
      () => {
        this.loadStatues(invoiceDate.toDate());
      }
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader title="Payment Processing" showBackButton={false} />
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">
                        Invoice Report For{" "}
                        {this.state.invoiceDate
                          ? this.state.invoiceDate?.format("MM/DD/YYYY")
                          : "--"}
                      </h3>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        color="dark"
                        outline
                        className="btn-icon-only"
                        onClick={() => {
                          this.loadStatues(this.state.invoiceDate);
                        }}
                      >
                        <i className="mdi mdi-refresh"></i>
                      </Button>
                      <Button
                        size="sm"
                        disabled={
                          !this.state.invoiceDate ||
                          this.state.loading ||
                          this.state.processing
                        }
                        color="primary"
                        onClick={() => {
                          this.rerunProcessing(this.state.invoiceDate);
                        }}
                      >
                        {this.state.processing ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          "Rerun Processing"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  {this.state.loading ? (
                    <div className="skeleton m-3" style={{ height: 200 }}></div>
                  ) : (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" className="text-dark">
                            Status
                          </th>
                          <th scope="col">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.statuses?.map((s) => {
                          return (
                            <tr>
                              <th
                                className="text-dark text-capitalize"
                                scope="row"
                              >
                                {s.status}
                              </th>
                              <td className="text-dark">{s.count}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <CreateProductGroupModal></CreateProductGroupModal>
      </>
    );
  }
}

export default PlatformPaymentProcessingView;
