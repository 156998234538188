import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import { DatePicker, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import { Table, Cell, Column, HeaderCell } from "rsuite-table";
import _ from "underscore";
import MoneyCell from "components/Table/MoneyCell";
import NumericCell from "components/Table/NumericCell";
import StoreCell from "components/Table/StoreCell";
import BootstrapTable from "react-bootstrap-table-next";
import MealsOrderedWidget from "components/Widgets/Fulfillment/MealsOrderedWidget";
import PlansFulfilledWidget from "components/Widgets/Fulfillment/PlansFulfilledWidget";
import AverageBoxSizeWidget from "components/Widgets/Fulfillment/AverageBoxSizeWidget";
import PlanFrequencyChartWidget from "components/Widgets/Fulfillment/PlanFrequencyChartWidget";
import PlanProcurementChartWidget from "components/Widgets/Fulfillment/PlanProcurementChartWidget";
import PlanTypeChartWidget from "components/Widgets/Fulfillment/PlanTypeChartWidget";
import { Link } from "react-router-dom";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";

class FulfillmentSummary extends React.Component {
  state = {
    stores: [],
    loading: true,
    fulfillmentDate: new Date(),
    availableDates: [],
    storeOpen: true,
    mealOpen: true,
    planOpen: true,
    storePerformance: [],
    dates: [],
    compareToOptions: [],
    storePerformanceColumns: [
      {
        dataField: "storeID",
        text: "Store ID",
        hidden: true,
      },
      {
        dataField: "storeName",
        text: "Store",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          return (
            <Link to={"/frandev/stores/details/" + row.storeID + "/about"}>
              {row.storeName}
            </Link>
          );
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a.localeCompare(b);
          else return b.localeCompare(a);
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "orders",
        text: "Orders",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          if (row.ordersPercentChange) {
            return (
              <div style={{ position: "relative" }}>
                <span className="pr-5">
                  {StringUtils.numberFormat(row.orders)}
                </span>
                <div
                  style={{ position: "absolute", right: "-16px", top: 0 }}
                  className={`font-weight-bold ${
                    row.ordersPercentChange > 0 ? "text-success" : "text-danger"
                  }`}
                >
                  {Math.round(Math.abs(row.ordersPercentChange)).toFixed(0)}%
                </div>
              </div>
            );
          } else {
            return StringUtils.numberFormat(row.orders);
          }
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a - b;
          else return b - a;
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "meals",
        text: "Meals",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          if (row.quantityPercentChange) {
            return (
              <div style={{ position: "relative" }}>
                <span className="pr-5">
                  {StringUtils.numberFormat(row.meals)}
                </span>
                <div
                  style={{ position: "absolute", right: "-16px", top: 0 }}
                  className={`font-weight-bold ${
                    row.quantityPercentChange > 0
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  {Math.round(Math.abs(row.quantityPercentChange)).toFixed(0)}%
                </div>
              </div>
            );
          } else {
            return StringUtils.numberFormat(row.meals);
          }
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a - b;
          else return b - a;
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "grossRevenue",
        text: "Gross Revenue",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          if (row.grossRevenuePercentChange) {
            return (
              <div style={{ position: "relative" }}>
                <span className="pr-5">
                  {StringUtils.centsToCurrency(row.grossRevenue)}
                </span>
                <div
                  style={{ position: "absolute", right: "-16px", top: 0 }}
                  className={`font-weight-bold ${
                    row.grossRevenuePercentChange > 0
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  {Math.round(Math.abs(row.grossRevenuePercentChange)).toFixed(
                    0
                  )}
                  %
                </div>
              </div>
            );
          } else {
            return StringUtils.centsToCurrency(row.grossRevenue);
          }
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a - b;
          else return b - a;
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "totalActives",
        text: "Active Customers",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          if (row.totalActivesPercentChange) {
            return (
              <div style={{ position: "relative" }}>
                <span className="pr-5">
                  {StringUtils.numberFormat(row.totalActives)}
                </span>
                <div
                  style={{ position: "absolute", right: "-16px", top: 0 }}
                  className={`font-weight-bold ${
                    row.totalActivesPercentChange > 0
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  {Math.round(Math.abs(row.totalActivesPercentChange)).toFixed(
                    0
                  )}
                  %
                </div>
              </div>
            );
          } else {
            return StringUtils.numberFormat(row.totalActives);
          }
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a - b;
          else return b - a;
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
      {
        dataField: "percentFulfilled",
        text: "% Fulfilled",
        sort: true,
        headerClasses: "bg-superlight",
        headerStyle: { position: "relative", paddingLeft: "2.3rem" },
        formatter: (cell, row, rowIndex) => {
          if (row.percentFulfilledPercentChange) {
            return (
              <div style={{ position: "relative" }}>
                <span className="pr-5">
                  {row.totalActives
                    ? StringUtils.numberFormat(
                        (row.percentFulfilled * 100).toFixed(1)
                      ) + "%"
                    : "--"}
                </span>
                <div
                  style={{ position: "absolute", right: "-16px", top: 0 }}
                  className={`font-weight-bold ${
                    row.percentFulfilledPercentChange > 0
                      ? "text-success"
                      : "text-danger"
                  }`}
                >
                  {Math.round(
                    Math.abs(row.percentFulfilledPercentChange)
                  ).toFixed(0)}
                  %
                </div>
              </div>
            );
          } else {
            return row.totalActives
              ? StringUtils.numberFormat(
                  (row.percentFulfilled * 100).toFixed(1)
                ) + "%"
              : "--";
          }
        },
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") return a - b;
          else return b - a;
        },
        sortCaret: (order, column) => {
          if (order) {
            if (order == "asc") {
              return (
                <i
                  className="mdi mdi-sort-ascending text-primary mr--1"
                  style={{ position: "absolute", left: "1rem" }}
                ></i>
              );
            }

            return (
              <i
                className="mdi mdi-sort-descending text-primary"
                style={{ position: "absolute", left: "1rem" }}
              ></i>
            );
          }

          return (
            <i
              className="mdi mdi-sort"
              style={{ position: "absolute", left: "1rem" }}
            ></i>
          );
        },
      },
    ],
  };

  loadCompareToOptions(first) {
    let current = moment(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

    let weekPrior = moment(current.format());
    let monthPrior = moment(current.format());
    let quarterPrior = moment(current.format());
    let yearPrior = moment(current.format());

    weekPrior = weekPrior.subtract(1, "week");
    monthPrior = monthPrior.subtract(4, "weeks");
    quarterPrior = quarterPrior.subtract(12, "weeks");
    yearPrior = yearPrior.subtract(1, "year").day(6);

    //let monthPrior = current.subtract(4 * 7, "days");
    //let quarterPrior = current.subtract(12 * 7, "days");
    //let yearPrior = current.subtract(1, "year");

    let compareTo = [];

    if (
      this.state.availableDates.indexOf(weekPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastweek",
        label: "vs. Previous Week",
      });
    }

    if (
      this.state.availableDates.indexOf(monthPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastmonth",
        label: "vs. Previous Month",
      });
    }

    if (
      this.state.availableDates.indexOf(quarterPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastquarter",
        label: "vs. Previous Quarter",
      });
    }

    if (
      this.state.availableDates.indexOf(yearPrior.format("YYYY-MM-DD")) >= 0
    ) {
      compareTo.push({
        value: "lastyear",
        label: "vs. Previous Year",
      });
    }

    this.setState({ compareToOptions: compareTo });
  }

  getAvailableDates() {
    APIV2.getSubscriptionFulfillmentDates().then(
      (data) => {
        let dates = data.data.dates;

        dates.push(this.state.fulfillmentMoment.format("YYYY-MM-DD"));

        this.setState({
          availableDates: dates,
        });

        this.loadCompareToOptions(true);
      },
      (e) => {
        this.setState({
          availableDates: [],
        });

        console.error(e);
      }
    );
  }

  getAllStores() {
    APIV2.getStores().then((data) => {
      this.setState({ stores: data.data.stores });

      if (this.state.reports?.length) {
        this.renderSummary(this.state.reports);
      }
    });
  }

  getAllMeals() {}

  renderSummary(reports, comparison = []) {
    if (!reports || !reports.length) {
      this.setState({ emptyReporting: true });
    } else {
      this.setState({ emptyReporting: false });
    }

    let totalOrders = 0;
    let totalComparisonOrders = 0;
    let totalQuantity = 0;
    let totalComparisonQuantity = 0;
    let totalGrossRevenue = 0;
    let totalComparisonGrossRevenue = 0;
    let totalActiveSubscriptions = 0;
    let totalCompareActiveSubscriptions = 0;
    let comparePercentFulfilled = 0;
    let percentFulfilled = 0;

    let storeTotals = [];
    let storeTotalsComparison = [];

    for (let i = 0; i < reports.length; i++) {
      if (!reports[i].statistics) {
        continue;
      }

      totalOrders += reports[i].statistics?.totalFulfilledCount
        ? reports[i].statistics?.totalFulfilledCount
        : reports[i].totalOrderCount;
      totalQuantity += reports[i].statistics?.totalBOMQuantity;
      totalGrossRevenue += reports[i].statistics?.grossRevenue;
      totalActiveSubscriptions += reports[i].totalActiveSubscriptions
        ? reports[i].totalActiveSubscriptions
        : 0;

      let storePerformance = {
        storeID: "",
        storeName: "",
        orders: reports[i].statistics?.totalFulfilledCount
          ? reports[i].statistics?.totalFulfilledCount
          : reports[i].totalOrderCount,
        meals: reports[i].statistics?.totalBOMQuantity,
        grossRevenue: reports[i].statistics?.grossRevenue,
        totalActives: reports[i].totalActiveSubscriptions
          ? reports[i].totalActiveSubscriptions
          : 0,
        percentFulfilled:
          (reports[i].statistics?.totalFulfilledCount
            ? reports[i].statistics?.totalFulfilledCount
            : reports[i].totalOrderCount) && reports[i].totalActiveSubscriptions
            ? (reports[i].statistics?.totalFulfilledCount
                ? reports[i].statistics?.totalFulfilledCount
                : reports[i].totalOrderCount) /
              reports[i].totalActiveSubscriptions
            : 0,
      };

      let st = _.findWhere(this.state.stores, {
        _id: reports[i].storeID,
      });

      if (st) {
        storePerformance.storeID = st._id;
        storePerformance.storeName = st.name;
      }

      storeTotals.push(storePerformance);
    }

    percentFulfilled = totalOrders / totalActiveSubscriptions;

    this.setState({
      reports,
      totalOrders,
      totalQuantity,
      totalGrossRevenue,
      storePerformance: storeTotals,
      totalActiveSubscriptions,
      percentFulfilled,
    });

    this.setState({
      totalOrdersPercentChange: null,
      totalQuantityPercentChange: null,
      totalGrossRevenuePercentChange: null,
      totalPercentFulfilledPercentChange: null,
      totalActiveSubscriptionsPercentChange: null,
    });

    if (comparison?.length) {
      for (let i = 0; i < comparison.length; i++) {
        if (!comparison[i].statistics) {
          continue;
        }

        totalComparisonOrders += comparison[i].statistics?.totalFulfilledCount
          ? comparison[i].statistics?.totalFulfilledCount
          : comparison[i].totalOrderCount;
        totalCompareActiveSubscriptions +=
          comparison[i].totalActiveSubscriptions;
        totalComparisonQuantity += comparison[i].statistics?.totalBOMQuantity;
        totalComparisonGrossRevenue += comparison[i].statistics?.grossRevenue;

        let storePerformance = {
          storeID: "",
          storeName: "",
          orders: comparison[i].statistics?.totalFulfilledCount
            ? comparison[i].statistics?.totalFulfilledCount
            : comparison[i].totalOrderCount,
          meals: comparison[i].statistics?.totalBOMQuantity,
          grossRevenue: comparison[i].statistics?.grossRevenue,
          totalActives: comparison[i].totalActiveSubscriptions
            ? comparison[i].totalActiveSubscriptions
            : 0,
          percentFulfilled:
            (comparison[i].statistics?.totalFulfilledCount
              ? comparison[i].statistics?.totalFulfilledCount
              : comparison[i].totalOrderCount) &&
            comparison[i].totalActiveSubscriptions
              ? (comparison[i].statistics?.totalFulfilledCount
                  ? comparison[i].statistics?.totalFulfilledCount
                  : comparison[i].totalOrderCount) /
                comparison[i].totalActiveSubscriptions
              : 0,
        };

        let st = _.findWhere(this.state.stores, {
          _id: comparison[i].storeID,
        });

        if (st) {
          storePerformance.storeID = st._id;
          storePerformance.storeName = st.name;
        }

        storeTotalsComparison.push(storePerformance);
      }

      let totalOrdersPercentChange = totalOrders - totalComparisonOrders;

      totalOrdersPercentChange = (
        (totalOrdersPercentChange / totalOrders) *
        100
      ).toFixed(1);

      let totalQuantityPercentChange = totalQuantity - totalComparisonQuantity;

      totalQuantityPercentChange = (
        (totalQuantityPercentChange / totalQuantity) *
        100
      ).toFixed(1);

      let totalGrossRevenuePercentChange =
        totalGrossRevenue - totalComparisonGrossRevenue;

      totalGrossRevenuePercentChange = (
        (totalGrossRevenuePercentChange / totalGrossRevenue) *
        100
      ).toFixed(1);

      let totalActiveSubscriptionsPercentChange = null;
      let totalPercentFulfilledPercentChange = null;

      if (totalCompareActiveSubscriptions) {
        totalActiveSubscriptionsPercentChange =
          totalActiveSubscriptions - totalCompareActiveSubscriptions;

        totalActiveSubscriptionsPercentChange = (
          (totalActiveSubscriptionsPercentChange / totalActiveSubscriptions) *
          100
        ).toFixed(1);
      }

      if (comparePercentFulfilled) {
        totalPercentFulfilledPercentChange =
          percentFulfilled - comparePercentFulfilled;

        totalPercentFulfilledPercentChange = (
          (totalPercentFulfilledPercentChange / totalActiveSubscriptions) *
          100
        ).toFixed(1);
      }

      this.setState({
        comparisonReports: comparison,
        totalOrdersPercentChange,
        totalQuantityPercentChange,
        totalGrossRevenuePercentChange,
        totalActiveSubscriptionsPercentChange,
        totalPercentFulfilledPercentChange,
      });

      let bestPerformer = null;
      let bestPerformerRev = null;
      let weakestPerformer = null;
      let weakestPerformerRev = null;

      // TODO: add plus minus for stores here.
      for (let j = 0; j < storeTotalsComparison.length; j++) {
        let comp = storeTotalsComparison[j];

        let idx = _.findIndex(storeTotals, { storeID: comp.storeID });

        if (idx >= 0) {
          let report = storeTotals[idx];

          let ordersPercentChange = report.orders - comp.orders;

          ordersPercentChange = (
            (ordersPercentChange / report.orders) *
            100
          ).toFixed(1);

          let quantityPercentChange = report.meals - comp.meals;

          quantityPercentChange = (
            (quantityPercentChange / report.meals) *
            100
          ).toFixed(1);

          let grossRevenuePercentChange =
            report.grossRevenue - comp.grossRevenue;

          grossRevenuePercentChange = (
            (grossRevenuePercentChange / report.grossRevenue) *
            100
          ).toFixed(1);

          let activeSubscriptionsPercentChange = null;
          let percentFulfilledPercentChange = null;

          if (comp.totalActives) {
            activeSubscriptionsPercentChange =
              report.totalActives - comp.totalActives;

            activeSubscriptionsPercentChange = (
              (activeSubscriptionsPercentChange / report.totalActives) *
              100
            ).toFixed(1);
          }

          if (comp.percentFulfilled > 0 && comp.percentFulfilled < 2) {
            percentFulfilledPercentChange =
              report.percentFulfilled - comp.percentFulfilled;

            percentFulfilledPercentChange = (
              (percentFulfilledPercentChange / report.percentFulfilled) *
              100
            ).toFixed(1);
          }

          storeTotals[idx].ordersPercentChange = ordersPercentChange;
          storeTotals[idx].quantityPercentChange = quantityPercentChange;
          storeTotals[idx].grossRevenuePercentChange =
            grossRevenuePercentChange;
          storeTotals[idx].totalActivesPercentChange =
            activeSubscriptionsPercentChange;
          storeTotals[idx].percentFulfilledPercentChange =
            percentFulfilledPercentChange;

          if (
            (!isNaN(Number(storeTotals[idx].grossRevenuePercentChange)) &&
              bestPerformerRev === null) ||
            Number(storeTotals[idx].grossRevenuePercentChange) >
              bestPerformerRev
          ) {
            bestPerformer = storeTotals[idx];
            bestPerformerRev = Number(
              storeTotals[idx].grossRevenuePercentChange
            );
          }

          if (
            !isNaN(Number(storeTotals[idx].grossRevenuePercentChange)) &&
            (weakestPerformerRev === null ||
              Number(storeTotals[idx].grossRevenuePercentChange) <
                weakestPerformerRev)
          ) {
            weakestPerformer = storeTotals[idx];
            weakestPerformerRev = Number(
              storeTotals[idx].grossRevenuePercentChange
            );
          }

          this.setState({
            storePerformance: storeTotals,
          });
        }
      }

      console.log(bestPerformer, bestPerformerRev);

      this.setState({
        bestGrowth: bestPerformer,
        worstGrowth: weakestPerformer,
      });
    }

    if (!this.state.comparisonValue) {
      this.setState({
        comparisonReports: [],
        totalOrdersPercentChange: null,
        totalQuantityPercentChange: null,
        totalGrossRevenuePercentChange: null,
        totalPercentFulfilledPercentChange: null,
        totalActiveSubscriptionsPercentChange: null,
      });
    }
  }

  loadSummary(dateString, compareTo = false) {
    if (!dateString) {
      return;
    }

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("date", dateString);
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.setState({
      loading: true,
    });

    let comp = "";

    if (compareTo) {
      let current = moment(this.state.fulfillmentMoment.format("YYYY-MM-DD"));
      let weekPrior = moment(current.format());
      let monthPrior = moment(current.format());
      let quarterPrior = moment(current.format());
      let yearPrior = moment(current.format());

      weekPrior = weekPrior.subtract(1, "week");
      monthPrior = monthPrior.subtract(4, "weeks");
      quarterPrior = quarterPrior.subtract(12, "weeks");
      yearPrior = yearPrior.subtract(1, "year").day(6);

      if (compareTo == "lastweek") {
        comp = weekPrior.format("YYYY-MM-DD");
      } else if (compareTo == "lastmonth") {
        comp = monthPrior.format("YYYY-MM-DD");
      } else if (compareTo == "lastquarter") {
        comp = quarterPrior.format("YYYY-MM-DD");
      } else if (compareTo == "lastyear") {
        comp = yearPrior.format("YYYY-MM-DD");
      }
    }

    this.setState({ comparisonDateString: comp });

    if (comp) {
      APIV2.getSubscriptionFulfillmentSummaryForDate(dateString).then(
        (data) => {
          let payload = {};

          payload.reports = data.data.reports;

          this.setState(payload, () => {
            APIV2.getSubscriptionFulfillmentSummaryForDate(comp)
              .then(
                (data) => {
                  this.setState(
                    {
                      comparisonReports: data.data.reports,
                    },
                    () => {
                      this.renderSummary(
                        this.state.reports,
                        this.state.comparisonReports
                      );

                      this.loadCompareToOptions();
                    }
                  );
                },
                (e) => {}
              )
              .finally(() => {
                this.setState({ loading: false });
              });
          });
        },
        (e) => {
          this.setState({ loading: false });
        }
      );
    } else {
      APIV2.getSubscriptionFulfillmentSummaryForDate(dateString)
        .then(
          (data) => {
            let payload = {};

            payload.reports = data.data.reports;

            this.setState(payload, () => {
              this.renderSummary(this.state.reports);

              this.loadCompareToOptions();
            });
          },
          (e) => {}
        )
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  isGloba() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  isProduction() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.productionPermissions?.length ? true : false;
  }

  componentDidMount() {
    if (!this.isGloba() && !this.isProduction()) {
      this.props.history.push("/reports/fulfillment");

      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get("date");

    let fulfillmentDate = moment();

    if (date) {
      fulfillmentDate = moment(date);
    }

    fulfillmentDate
      .set("day", 6)
      .set("hours", 0)
      .set("minutes", 0)
      .set("seconds", 0);

    this.setState(
      {
        fulfillmentDate: fulfillmentDate.toDate(),
        fulfillmentMoment: fulfillmentDate,
        fulfillmentDateString: fulfillmentDate.format("YYYY-MM-DD"),
        comparisonValue: "lastweek",
      },
      () => {
        this.loadSummary(this.state.fulfillmentDateString, "lastweek");
        this.getAvailableDates();
      }
    );

    this.getAllStores();
    this.getAllStores();
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            title="Fulfillment Summary"
            showBackButton={false}
            sticky={true}
          />
          {/* Table */}
          <div className="border-bottom pb-3 mt--2 mb-4">
            <Row className="align-items-center">
              <Col xs="12" sm="12" md="">
                <h3 className="m-0">
                  {this.state.fulfillmentMoment?.format("ddd, MMM Do YYYY")}
                </h3>
                {this.state.comparisonDateString ? (
                  <small>
                    compared to{" "}
                    {moment(this.state.comparisonDateString).format(
                      "ddd, MMM Do YYYY"
                    )}
                  </small>
                ) : null}
              </Col>
              <Col xs="12" sm="6" md="auto">
                <DatePicker
                  oneTap
                  value={this.state.fulfillmentDate}
                  onChange={(v) => {
                    let mom = moment(v.toISOString());

                    mom.set("hours", 0).set("minutes", 0).set("seconds", 0);

                    this.setState(
                      {
                        fulfillmentDate: mom.toDate(),
                        fulfillmentMoment: mom,
                        fulfillmentDateString: mom.format("YYYY-MM-DD"),
                      },
                      () => {
                        this.loadSummary(
                          this.state.fulfillmentDateString,
                          this.state.comparisonValue
                        );
                      }
                    );
                  }}
                  className="mt-3 mt-md-0"
                  placeholder="Select Date"
                  style={{
                    minWidth: "200px",
                    width: "100%",
                    maxWidth: "800px",
                  }}
                  placement="autoVerticalEnd"
                  block
                  cleanable={false}
                  disabledDate={(date) => {
                    let mom = moment(date.toISOString());

                    if (!this.state.availableDates) {
                      return false;
                    }

                    return this.state.availableDates.indexOf(
                      mom.format("YYYY-MM-DD")
                    ) >= 0
                      ? false
                      : true;
                  }}
                />
              </Col>
              <Col xs="12" sm="6" md="auto" className="pl-md-0">
                <SelectPicker
                  className="mt-3 mt-md-0"
                  searchable={false}
                  oneTap
                  value={this.state.comparisonValue}
                  onChange={(v) => {
                    this.setState({
                      comparisonValue: v,
                    });

                    if (v) {
                      this.loadSummary(this.state.fulfillmentDateString, v);
                    } else {
                      this.loadSummary(this.state.fulfillmentDateString, false);
                    }
                  }}
                  cleanable={true}
                  block
                  placement="autoVerticalEnd"
                  placeholder="Compare To"
                  data={this.state.compareToOptions}
                ></SelectPicker>
              </Col>
            </Row>
          </div>

          {this.state.loading ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    <Spinner color="dark" size="sm"></Spinner>
                    <span className="pl-3">Loading Summary</span>
                  </h3>
                </CardHeader>
                <CardBody>
                  Hang tight while we fetch your fulfillment summary for{" "}
                  {this.state.fulfillmentMoment?.format("dddd, MMM Do YYYY")}.
                </CardBody>
              </Card>
            </>
          ) : null}
          {!this.state.loading && this.state.emptyReporting ? (
            <>
              <Card className="border">
                <CardHeader>
                  <h3 className="m-0">
                    {moment().isBefore(this.state.fulfillmentMoment)
                      ? "Summary Not Ready"
                      : "Summary Not Available"}
                  </h3>
                </CardHeader>
                <CardBody>
                  {moment().isBefore(this.state.fulfillmentMoment) ? (
                    <>
                      <p className="mb-0">
                        Check back on Thursday to see a summary of this week's
                        store fulfillment.
                      </p>
                    </>
                  ) : (
                    <>
                      We couldn't find any Store Fulfillment reports for{" "}
                      {this.state.fulfillmentMoment?.format(
                        "dddd, MMM Do YYYY"
                      )}
                      . Try choosing another date.
                    </>
                  )}
                </CardBody>
              </Card>
            </>
          ) : null}
          {!this.state.loading && !this.state.emptyReporting ? (
            <>
              <div className="mb-3 mt--3">
                {this.state.reports?.length ? (
                  <p className="mb-0" style={{ lineHeight: 1.2 }}>
                    <small>
                      Reporting from {this.state.reports.length} store
                      {this.state.reports.length > 1 ? "s" : ""}.
                    </small>
                  </p>
                ) : null}
              </div>
              <Row>
                <Col xs="12" sm="6" lg="4" className="mb-3">
                  <Card className="border shadow">
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="">
                          <h4 className="m-0">Total Orders</h4>
                        </Col>
                        <Col xs="auto">
                          {this.state.totalOrdersPercentChange !== null &&
                          this.state.totalOrdersPercentChange !== 0 ? (
                            <small
                              className={`pl-2 font-weight-bold ${
                                this.state.totalOrdersPercentChange > 0
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                              style={{ fontSize: "14px" }}
                            >
                              {this.state.totalOrdersPercentChange > 0 ? (
                                <i className="text-success mdi mdi-chevron-up"></i>
                              ) : (
                                <i className="text-danger mdi mdi-chevron-down"></i>
                              )}
                              {Math.abs(this.state.totalOrdersPercentChange)}%
                            </small>
                          ) : null}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-3">
                      <h1 className="m-0">
                        {StringUtils.numberFormat(this.state.totalOrders)}
                      </h1>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" sm="6" lg="4" className="mb-3">
                  <Card className="border shadow">
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="">
                          <h4 className="m-0">Total Meals</h4>
                        </Col>
                        <Col xs="auto">
                          {this.state.totalQuantityPercentChange !== null &&
                          this.state.totalQuantityPercentChange !== 0 ? (
                            <small
                              className={`pl-2 font-weight-bold ${
                                this.state.totalQuantityPercentChange > 0
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                              style={{ fontSize: "14px" }}
                            >
                              {this.state.totalQuantityPercentChange > 0 ? (
                                <i className="text-success mdi mdi-chevron-up"></i>
                              ) : (
                                <i className="text-danger mdi mdi-chevron-down"></i>
                              )}
                              {Math.abs(this.state.totalQuantityPercentChange)}%
                            </small>
                          ) : null}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-3">
                      <h1 className="m-0">
                        {StringUtils.numberFormat(this.state.totalQuantity)}
                      </h1>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" sm="12" lg="4" className="mb-3">
                  <Card className="border shadow">
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="">
                          <h4 className="m-0">Gross Revenue</h4>
                        </Col>
                        <Col xs="auto">
                          {this.state.totalGrossRevenuePercentChange !== null &&
                          this.state.totalGrossRevenuePercentChange !== 0 ? (
                            <small
                              className={`pl-2 font-weight-bold ${
                                this.state.totalGrossRevenuePercentChange > 0
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                              style={{ fontSize: "14px" }}
                            >
                              {this.state.totalGrossRevenuePercentChange > 0 ? (
                                <i className="text-success mdi mdi-chevron-up"></i>
                              ) : (
                                <i className="text-danger mdi mdi-chevron-down"></i>
                              )}
                              {Math.abs(
                                this.state.totalGrossRevenuePercentChange
                              )}
                              %
                            </small>
                          ) : null}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-3">
                      <h1 className="m-0">
                        {StringUtils.centsToCurrency(
                          this.state.totalGrossRevenue
                        )}
                      </h1>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" sm="6" lg="6" className="mb-3">
                  <Card className="border shadow">
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="">
                          <h4 className="m-0">Total Actives</h4>
                        </Col>
                        <Col xs="auto">
                          {this.state.totalActiveSubscriptionsPercentChange !==
                            null &&
                          this.state.totalActiveSubscriptionsPercentChange !==
                            0 ? (
                            <small
                              className={`pl-2 font-weight-bold ${
                                this.state
                                  .totalActiveSubscriptionsPercentChange > 0
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                              style={{ fontSize: "14px" }}
                            >
                              {this.state
                                .totalActiveSubscriptionsPercentChange > 0 ? (
                                <i className="text-success mdi mdi-chevron-up"></i>
                              ) : (
                                <i className="text-danger mdi mdi-chevron-down"></i>
                              )}
                              {Math.abs(
                                this.state.totalActiveSubscriptionsPercentChange
                              )}
                              %
                            </small>
                          ) : null}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-3">
                      <h1 className="m-0">
                        {this.state.totalActiveSubscriptions > 0
                          ? StringUtils.numberFormat(
                              this.state.totalActiveSubscriptions
                            )
                          : "--"}
                      </h1>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" sm="6" lg="6" className="mb-3">
                  <Card className="border shadow">
                    <CardHeader className="p-2">
                      <Row className="align-items-center">
                        <Col xs="">
                          <h4 className="m-0">Percent Fulfilled</h4>
                        </Col>
                        <Col xs="auto">
                          {this.state.totalPercentFulfilledPercentChange !==
                            null &&
                          this.state.totalPercentFulfilledPercentChange !==
                            0 ? (
                            <small
                              className={`pl-2 font-weight-bold ${
                                this.state.totalPercentFulfilledPercentChange >
                                0
                                  ? "text-success"
                                  : "text-danger"
                              }`}
                              style={{ fontSize: "14px" }}
                            >
                              {this.state.totalPercentFulfilledPercentChange >
                              0 ? (
                                <i className="text-success mdi mdi-chevron-up"></i>
                              ) : (
                                <i className="text-danger mdi mdi-chevron-down"></i>
                              )}
                              {Math.abs(
                                this.state.totalPercentFulfilledPercentChange
                              )}
                              %
                            </small>
                          ) : null}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-3">
                      <h1 className="m-0">
                        {this.state.percentFulfilled > 0 &&
                        this.state.percentFulfilled < 2 ? (
                          <>
                            {StringUtils.numberFormat(
                              this.state.percentFulfilled * 100
                            )}
                            %
                          </>
                        ) : (
                          "--"
                        )}
                      </h1>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div
                className="mt-3 pb-2 mb-3 border-bottom cursor-pointer"
                onClick={() => {
                  this.setState({ storeOpen: !this.state.storeOpen });
                }}
              >
                <h3 className="mb-0">
                  <i
                    className={`pr-3 mdi mdi-chevron-${
                      this.state.storeOpen ? "down" : "right"
                    }`}
                  />
                  Fulfillment By Store
                </h3>
              </div>
              <Collapse isOpen={this.state.storeOpen}>
                <div>
                  <Row>
                    <Col xs="12" sm="6" lg="6" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <h4 className="m-0">Best Revenue Growth </h4>
                        </CardHeader>
                        <CardBody className="p-3">
                          {this.state.bestGrowth ? (
                            <>
                              <Row className="align-items-center">
                                <Col xs="8">
                                  <h3 className="m-0">
                                    {this.state.bestGrowth.storeName.replace(
                                      "Project LeanNation",
                                      "PLN"
                                    )}
                                  </h3>
                                </Col>
                                <Col xs="4" className="text-right">
                                  <h3
                                    className={`m-0 font-weight-bold ${
                                      this.state.bestGrowth
                                        .grossRevenuePercentChange > 0
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                  >
                                    {this.state.bestGrowth
                                      .grossRevenuePercentChange > 0 ? (
                                      <>
                                        <i className="mdi mdi-menu-up" />
                                      </>
                                    ) : (
                                      <>
                                        <i className="mdi mdi-menu-down" />
                                      </>
                                    )}
                                    {Math.abs(
                                      this.state.bestGrowth
                                        .grossRevenuePercentChange
                                    ).toFixed(1)}
                                    %
                                  </h3>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <h2>--</h2>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="6" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <h4 className="m-0">Biggest Revenue Decline</h4>
                        </CardHeader>
                        <CardBody className="p-3">
                          {this.state.worstGrowth ? (
                            <>
                              <Row className="align-items-center">
                                <Col xs="8">
                                  <h3 className="m-0">
                                    {this.state.worstGrowth.storeName.replace(
                                      "Project LeanNation",
                                      "PLN"
                                    )}
                                  </h3>
                                </Col>
                                <Col xs="4" className="text-right">
                                  <h3
                                    className={`m-0 font-weight-bold ${
                                      this.state.worstGrowth
                                        .grossRevenuePercentChange > 0
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                  >
                                    {this.state.worstGrowth
                                      .grossRevenuePercentChange > 0 ? (
                                      <>
                                        <i className="mdi mdi-menu-up" />
                                      </>
                                    ) : (
                                      <>
                                        <i className="mdi mdi-menu-down" />
                                      </>
                                    )}
                                    {Math.abs(
                                      this.state.worstGrowth
                                        .grossRevenuePercentChange
                                    ).toFixed(1)}
                                    %
                                  </h3>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <h2>--</h2>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" lg="12" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <h4 className="m-0">Store Performance</h4>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="table-responsive">
                            <BootstrapTable
                              condensed
                              columns={this.state.storePerformanceColumns}
                              data={this.state.storePerformance}
                              bootstrap4
                              keyField="storeID"
                              defaultSorted={[
                                {
                                  dataField: "orders", // if dataField is not match to any column you defined, it will be ignored.
                                  order: "desc", // desc or asc
                                },
                              ]}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Collapse>
              <div
                className="mt-3 pb-2 mb-3 border-bottom cursor-pointer"
                onClick={() => {
                  this.setState({ mealOpen: !this.state.mealOpen });
                }}
              >
                <h3 className="mb-0">
                  <i
                    className={`pr-3 mdi mdi-chevron-${
                      this.state.mealOpen ? "down" : "right"
                    }`}
                  />
                  Fulfillment By Meal
                </h3>
              </div>
              <Collapse isOpen={this.state.mealOpen}>
                <div>
                  <Row>
                    <Col xs="12" sm="6" lg="6" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <h4 className="m-0">
                            Most Popular{" "}
                            <Badge color="light" className="pl-2">
                              Coming Soon
                            </Badge>
                          </h4>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="p-3">
                            <h5>Lifestyle</h5>
                            <h2 className="m-0">--</h2>
                          </div>
                          <hr className="m-0"></hr>
                          <div className="p-3">
                            <h5>Athlete</h5>
                            <h2 className="m-0">--</h2>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" lg="6" className="mb-3">
                      <Card className="border shadow">
                        <CardHeader className="p-2">
                          <h4 className="m-0">
                            Least Popular{" "}
                            <Badge color="light" className="pl-2">
                              Coming Soon
                            </Badge>
                          </h4>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="p-3">
                            <h5>Lifestyle</h5>
                            <h2 className="m-0">--</h2>
                          </div>
                          <hr className="m-0"></hr>
                          <div className="p-3">
                            <h5>Athlete</h5>
                            <h2 className="m-0">--</h2>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" lg="12" className="mb-3">
                      <MealsOrderedWidget
                        reports={this.state.reports}
                      ></MealsOrderedWidget>
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div
                className="mt-3 pb-2 mb-3 border-bottom cursor-pointer"
                onClick={() => {
                  this.setState({ planOpen: !this.state.planOpen });
                }}
              >
                <h3 className="mb-0">
                  <i
                    className={`pr-3 mdi mdi-chevron-${
                      this.state.planOpen ? "down" : "right"
                    }`}
                  />
                  Fulfillment By Plan
                </h3>
              </div>
              <Collapse isOpen={this.state.planOpen}>
                <div>
                  <Row>
                    <Col xs="12" md="4" className="mb-3">
                      <PlanFrequencyChartWidget
                        reports={this.state.reports}
                      ></PlanFrequencyChartWidget>
                    </Col>
                    <Col xs="12" md="4" className="mb-3">
                      <PlanProcurementChartWidget
                        reports={this.state.reports}
                      ></PlanProcurementChartWidget>
                    </Col>
                    <Col xs="12" md="4" className="mb-3">
                      <PlanTypeChartWidget
                        reports={this.state.reports}
                      ></PlanTypeChartWidget>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="mb-3">
                      <AverageBoxSizeWidget
                        reports={this.state.reports}
                      ></AverageBoxSizeWidget>
                    </Col>
                  </Row>

                  <PlansFulfilledWidget
                    reports={this.state.reports}
                  ></PlansFulfilledWidget>
                </div>
              </Collapse>
            </>
          ) : null}
        </Container>
      </>
    );
  }
}

export default FulfillmentSummary;
