import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import _, { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { Dropdown, Popover, SelectPicker, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import LibraryCategoryEditDrawer from "components/Drawers/LibraryCategoryEditDrawer";
import LibraryModuleEditDrawer from "components/Drawers/LibraryModuleEditDrawer";
import LibraryAllModulesDrawer from "components/Drawers/LibraryAllModulesDrawer";
import { convertTypeAcquisitionFromJson } from "typescript";
import LibraryAllTopicsDrawer from "components/Drawers/LibraryAllTopicsDrawer";
import { Capacitor } from "@capacitor/core";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

class LearningCenterCourseSummary extends React.Component {
  state = {
    active: 0,
    loadingCategories: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    openCategory: false,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  loadCourse() {
    this.setState({ loading: true });

    APIV2.getLearningCourseByID(this.props.match?.params?.courseID)
      .then(
        async (data) => {
          let course = data?.data?.course;

          if (course?.video?.url) {
            try {
              let res = await APIV2.getPrivateFileURL(course.video.url);

              course.video.url = res.data.url;
            } catch (e) {
              course.video.url = "";
            }
          }

          if (course?.cover?.url) {
            try {
              let res = await APIV2.getPrivateFileURL(course.cover.url);

              course.cover.url = res.data.url;
            } catch (e) {
              course.cover.url = "";
            }
          }

          this.setState({ course });

          if (data?.data?.course?.draft && !this.isGlobal()) {
            this.props.history.push("/university/learning/courses");
          }
        },
        (e) => {
          this.props.history.push("/university/learning/courses");
        }
      )
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.loadCourse();
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isProduction() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.productionPermissions?.length ? true : false;
  }

  getQuestionCount(topics) {
    let count = 0;

    for (let i = 0; i < topics?.length; i++) {
      count += topics[i]?.topic?.questions?.length
        ? topics[i]?.topic?.questions?.length
        : 0;
    }

    return count;
  }

  render() {
    return (
      <>
        <Container className="px-0" fluid>
          <div>
            <div
              className="bg-white px-4 py-4 mb-4 learning-course-summary-description-container"
              style={{ marginTop: "5rem" }}
            >
              {/* Page content */}
              {Capacitor.isNativePlatform() && (
                <div className="pt-5 d-md-none"></div>
              )}
              {this.state.loading ? (
                <>
                  <div
                    style={{
                      height: 20,
                      width: "100%",
                      maxWidth: 300,
                      marginBottom: "1.5rem",
                      borderRadius: 6,
                    }}
                    className="skeleton"
                  ></div>
                  <div className="mt-4">
                    <div
                      style={{ width: "100%", aspectRatio: "16/9" }}
                      className="rounded skeleton"
                    ></div>
                  </div>
                  <div
                    className="mt-4 rounded skeleton"
                    style={{ height: 35, width: "60%" }}
                  ></div>

                  <div
                    className="mt-4 rounded skeleton"
                    style={{ height: 90, width: "100%" }}
                  ></div>
                </>
              ) : (
                <>
                  <p className="text-dark m-0">
                    <div className="d-inline-block pr-2">
                      <Link to="/university/learning/courses">Courses</Link>
                    </div>

                    <span>-</span>

                    <div className="d-inline-block pl-2">
                      {this.state.course?.name}
                    </div>
                  </p>
                </>
              )}
              <div>
                {this.state.course?.video ? (
                  <div className="mt-4">
                    <ReactPlayer
                      className="rounded border-lighter border shadow bg-dark overflow-hidden"
                      width={"100%"}
                      style={{ contain: "cover", aspectRatio: "16/9" }}
                      url={this.state?.course?.video?.url}
                      controls={true}
                      height={"auto"}
                    />
                  </div>
                ) : null}
                {this.state.course?.cover && !this.state.course?.video ? (
                  <div className="mt-4">
                    <img
                      src={this.state.course?.cover?.url}
                      style={{
                        width: "100%",
                        aspectRatio: "16/9",
                        contain: "cover",
                      }}
                      alt="Cover"
                      className="rounded border border-midlighter"
                    />
                  </div>
                ) : null}
                <div className="mt-4">
                  <h1>{this.state.course?.name}</h1>
                  <p style={{ lineHeight: 1.5 }}>
                    {this.state.course?.description}
                  </p>
                </div>
                <hr className="my-4"></hr>
                <div
                  className="plnu-topic"
                  dangerouslySetInnerHTML={{
                    __html: this.state.course?.overview?.html,
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="border-left p-4 bg-white learning-course-summary-outline-container">
            {this.state.loading ? (
              <div className="rounded skeleton" style={{ height: 400 }}></div>
            ) : (
              <>
                <h2 className="text-dark mb-3">Course Outline:</h2>
                {this.state.course?.sections?.map((section, i) => (
                  <div key={i} className="mb-3 d-flex">
                    <div className="pr-2" style={{ width: 32 }}>
                      <h2>{i + 1}.</h2>
                    </div>
                    <div style={{ width: "calc(100% - 32px)" }}>
                      <h3 className="font-weight-500 mb-1">
                        {section?.name?.trim()}
                      </h3>

                      <p
                        className="mb-1 small text-dark"
                        style={{
                          lineHeight: 1.3,
                          lineClamp: 3,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                          wordBreak: "break-word",
                          hyphens: "auto",
                        }}
                      >
                        {section?.description}
                      </p>
                      <p
                        className="m-0 small text-dark"
                        style={{ lineHeight: 1.3 }}
                      >
                        {this.getQuestionCount(section?.topics)}&nbsp;Questions
                      </p>
                    </div>
                  </div>
                ))}
                <div className="mb-3 d-flex">
                  <div className="pr-2" style={{ width: 32 }}>
                    <h2 className="m-0">
                      {this.state.course?.sections?.length + 1}.
                    </h2>
                  </div>
                  <div style={{ width: "calc(100% - 32px)" }}>
                    <h3 className="font-weight-500 m-0">
                      {this.state.course?.name?.trim()} Quiz
                    </h3>
                    <p
                      className="mb-1 small text-dark"
                      style={{
                        lineHeight: 1.3,
                        lineClamp: 3,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        wordBreak: "break-word",
                        hyphens: "auto",
                      }}
                    >
                      Take a short quiz to test your knowledge.
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </>
    );
  }
}

export default LearningCenterCourseSummary;
